import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { WhiteBox } from '../white-box/white-box';

export function OkeySvg() {
  return (
    <svg
      width="97"
      height="96"
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1113_50621)">
        <circle cx="48.5" cy="41" r="36" fill="#51B943" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.4602 28.7289C66.7144 29.8115 66.8536 31.7059 65.7711 32.9601L47.6461 53.9601C47.1072 54.5845 46.3351 54.9594 45.5113 54.9969C44.6874 55.0344 43.8845 54.731 43.2912 54.1582L32.4162 43.6582C31.2243 42.5074 31.191 40.6081 32.3418 39.4162C33.4926 38.2243 35.3918 38.1909 36.5838 39.3418L45.1766 47.6383L61.2289 29.0398C62.3115 27.7856 64.2059 27.6463 65.4602 28.7289Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1113_50621"
          x="0.5"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1113_50621"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1113_50621"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export const IconError = () => {
  return (
    <svg
      width="103"
      height="102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#fff" d="M.5 0h102v102H.5z" />
      <path
        d="M44.232 16.402 8.235 76.497a8.5 8.5 0 0 0 7.267 12.75h71.995a8.5 8.5 0 0 0 7.268-12.75L58.767 16.402a8.5 8.5 0 0 0-14.535 0z"
        fill="#CE4525"
        stroke="#CE4525"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5 38.25v17m0 12.75v2.125"
        stroke="#fff"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export interface PopupMessageProps {
  message?: string;
  type: 'success' | 'error';
  close: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function PopupMessage(props: PopupMessageProps) {
  return (
    <Modal open={true}>
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <WhiteBox
          sx={{
            p: 'auto',
            width: 250,
            height: 300,
            m: 'auto',
            marginTop: 'calc(50vh - 150px)',
          }}
        >
          <Stack sx={{ m: 'auto' }}>
            <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
              {props.type === 'success' ? <OkeySvg /> : <IconError />}
            </Box>
            <Typography
              sx={{ width: '80%', ml: 'auto', mr: 'auto' }}
              variant="body1"
              fontWeight="bold"
              textAlign="center"
              data-testid="popup-message"
            >
              {props.message}
            </Typography>
            <Button
              onClick={props.close}
              sx={{
                marginTop: 3,
                color: 'white.main',
                width: '70%',
                ml: 'auto',
                mr: 'auto',
              }}
              color={props.type === 'success' ? 'success' : 'gray'}
              variant="contained"
              data-testid="close-popup-button"
            >
              Chiudi
            </Button>
          </Stack>
        </WhiteBox>
      </Box>
    </Modal>
  );
}
