import styled from 'styled-components';
import {
  Navigate,
  Outlet,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { useState } from 'react';
import { DrawerMenu } from './drawer-menu';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import PrivacyAndTermsModal from './privacy-and-terms-modal';
import Link from '@mui/material/Link';
import { LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RequiredAuth } from '@docpack/frontend/client-authorization';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetUserInfo } from '@docpack/frontend/core-components';
import Typography from '@mui/material/Typography';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import { usePaymentInfoHook } from '@docpack/frontend/payments-feature';
import moment from 'moment';
import StripeModal from './stripe-modal';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const sizeMenu = 377;
const sizeFooter = 40;

const Logo = styled.img`
  max-height: 50px;
  margin-bottom: 0;
  object-fit: contain;
  max-width: 60px;
  margin-left: 0;
  margin-right: auto;

  @media (min-width: 600px) {
    max-width: 200px;
  }
`;

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${() => sizeFooter}px;
  display: flex;
  align-items: center;
  z-index: 9;
  padding: 0 16px;
  flex-basis: 325px;

  @media (min-width: 600px) {
    padding: 0 24px;
  }
`;

export function LoggedInShell() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { data, mutate } = useGetUserInfo();
  const theme = useTheme();
  const location = useLocation();

  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const { data: user } = useGetCurrentUserInfo();
  const { planData } = usePaymentInfoHook();
  console.log(planData);
  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const daysLeft = moment(planData?.data?.current_period_end).diff(
    new Date(),
    'days'
  );
  if (!user) return null;
  if (user?.workspace?.firstlogin)
    return <Navigate to="/setup/welcome" replace />;

  return (
    <RequiredAuth redirectUri="/login">
      <AppBar color="secondary" elevation={0} position="sticky">
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'nowrap' }}
            sx={{ width: '100%' }}
          >
            <Link
              underline="always"
              style={{ marginRight: 'auto' }}
              component={RouterLink}
              to="/"
            >
              <Logo
                src={data?.workspace?.workspace_logo}
                alt="workspace logo"
              />
            </Link>
            <Stack
              direction="row"
              mr={{ xs: 2, md: 4 }}
              spacing={2}
              data-portal="search-bar"
            ></Stack>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                size="large"
                aria-label="menu"
                onClick={handleDrawerToggle}
                sx={{ p: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <DrawerMenu
              PaperProps={{ style: { width: sizeMenu } }}
              variant="temporary"
              open={menuOpen}
              onClose={handleDrawerToggle}
            />
          </Stack>
        </Toolbar>
      </AppBar>
      {planData.isValidating && <LinearProgress color="primary" />}
      {planData.data &&
        !planData.isValidating &&
        planData.data.status != 'trialing' &&
        planData.data.status != 'active' &&
        location.pathname !== '/app/payments' &&
        location.pathname !== '/app/plan-selection' && (
          <StripeModal></StripeModal>
        )}
      {data && !data.privacy_policy && (
        <PrivacyAndTermsModal
          type="privacy_policy"
          title="Informativa sulla privacy"
          onAcceptTerms={mutate}
        >
          <Typography variant="body1">
            Scopri come utilizziamo e proteggiamo i tuoi dati nella nostra{' '}
            <Link
              color="#000"
              target="_blank"
              rel="noreferrer"
              underline="always"
              href="https://docpack.it/privacy-policy/"
            >
              Informativa Privacy
            </Link>
          </Typography>
        </PrivacyAndTermsModal>
      )}
      {data && data.privacy_policy && !data?.terms_conditions && (
        <PrivacyAndTermsModal
          type="terms_conditions"
          title="Termini e condizioni d'uso"
          onAcceptTerms={mutate}
        >
          <Typography variant="body1">
            Approfondisci i nostri{' '}
            <Link
              color="#000"
              target="_blank"
              rel="noreferrer"
              underline="always"
              href="https://docpack.it/condizioni-duso/"
            >
              Termini e Condizioni
            </Link>
          </Typography>
        </PrivacyAndTermsModal>
      )}
      {/* Outlet route */}
      {!isXs && <Offset />}
      <Stack sx={{ mb: sizeFooter + 10, px: { xs: 2, md: 3 } }}>
        <Outlet />
      </Stack>
      <FooterContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Stack
          flexBasis={'400px'}
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={2}
          style={{
            fontSize: theme.typography.overline.fontSize,
            lineHeight: theme.typography.overline.lineHeight,
            fontWeight: theme.typography.overline.fontSize,
            color: theme.palette.white.main,
          }}
        >
          <Box>&copy; DOCpack {new Date().getFullYear()}</Box>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Link
              color={theme.palette.white.main}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              href="https://docpack.it/privacy-policy/"
            >
              Informativa Privacy
            </Link>
            <Box>|</Box>
            <Link
              color={theme.palette.white.main}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              href="https://docpack.it/condizioni-duso/"
            >
              Termini e Condizioni
            </Link>
          </Stack>
        </Stack>
        {planData?.data?.status == 'trialing' ? (
          <Box
            sx={{
              backgroundColor: 'red',
              padding: '4px 6px',
              fontWeight: 500,
              borderRadius: '10px',
              color: '#fff',
              maxHeight: '30px',
              flex: 'none',
              //transform: 'translateX(50%)',
            }}
          >
            Prova Gratuita. Giorni Rimanenti: {daysLeft + 1}
          </Box>
        ) : (
          <></>
        )}
        <Box
          flexBasis={'400px'}
          textAlign="right"
          data-portal="projects-count"
        ></Box>
      </FooterContainer>
    </RequiredAuth>
  );
}

export default LoggedInShell;
