import { Route, Routes } from 'react-router-dom';
import {
  EditProjectPage,
  MoveProjectPage,
  NewProjectPage,
  ProjectAssetsDownloadPage,
  ProjectsListPage,
  SendEmailProjectPage,
  DetailProjectPage,
  ReportProjectsPage,
} from './pages';

export function ProjectsFeatureRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProjectsListPage />}>
        <Route path="new" element={<NewProjectPage />} />
        <Route path="edit/:project_uuid" element={<EditProjectPage />} />
        <Route path="download" element={<ProjectAssetsDownloadPage />} />
        <Route path="send-email" element={<SendEmailProjectPage />} />
        <Route path="move" element={<MoveProjectPage />} />
        <Route path="detail/:project_uuid" element={<DetailProjectPage />} />
        <Route path="report" element={<ReportProjectsPage />} />
      </Route>
    </Routes>
  );
}
