import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Duck from '../../../../registration-feature/src/lib/components/not-logged-in-shell/duck.svg';
import { WhiteBox } from '@docpack/frontend/registration-feature';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import { Navigate, Link as RouterLink } from 'react-router-dom';
/* eslint-disable-next-line */
export interface FrontendWizardFeatureProps {}

export function FrontendWizardFeature(props: FrontendWizardFeatureProps) {
  const { data: user } = useGetCurrentUserInfo();
  console.log(user);
  if (!user?.workspace?.firstlogin)
    return <Navigate to="/app/projects" replace />;
  
  let username = user.details?.first_name
    ? user.details?.first_name
    : user.username;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        backgroundColor: 'secondary.main',
        backgroundImage: `url(${Duck})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 130%',
        alignContent: 'center',
      }}
    >
      <WhiteBox>
        <Stack>
          <Typography variant="h3" align="center" paddingTop={'28px'}>
            Ciao {username},<br /> benvenuto in DOCPACK!
          </Typography>

          <Typography
            align="center"
            fontSize={'20px'}
            paddingTop={'48px'}
            paddingBottom={'48px'}
          >
            Ora puoi procedere a configurare e personalizzare il tuo spazio
          </Typography>

          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: 'white !important',
              //maxWidth: '50%',
              alignSelf: 'center',
              ':hover': {
                color: '#20484f !important',
              },
            }}
            component={RouterLink}
            to="/setup/fase-due"
            endIcon={<ArrowForwardIcon />}
          >
            Procedi
          </Button>
        </Stack>
      </WhiteBox>
    </Box>
  );
}

export default FrontendWizardFeature;
