import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAcceptTerms } from './hook/accept-terms.hook';
import { AlertController } from '@docpack/frontend/core-components';
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '@docpack/frontend/client-authorization';

export const StripeModal = () => {
  const navigate = useNavigate();
  const { logout } = useLogin();

  return (
    <>
      <Modal open={true} aria-labelledby="parent-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white.main',
            maxWidth: 'calc(100% - 80px)',
            width: 500,
            p: '26px 54px 54px 54px',
            borderRadius: '24px',
            maxHeight: 'calc(100vh - 60px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h5"
            id="parent-modal-title"
            textAlign="center"
            sx={{
              marginRight: 'auto',
              marginLeft: 'auto',
              maxWidth: '220px',
              color: 'red',
            }}
          >
            Peccato!
          </Typography>
          <Typography
            id="parent-modal-text"
            textAlign="center"
            mb={2}
            sx={{
              marginRight: 'auto',
              marginLeft: 'auto',
              maxWidth: '320px',
              color: 'red',
            }}
          >
            Purtroppo il periodo di prova è scaduto.
          </Typography>
          <Typography
            id="parent-modal-text"
            textAlign="center"
            mb={2}
            sx={{ marginRight: 'auto', marginLeft: 'auto', maxWidth: '320px' }}
          >
            Grazie per aver utilizzato DOCpack.
            <br />
            Se non intendi confermare l’abbonamento non fare nulla, entro 10
            giorni il tuo profilo e tutti i tuoi dati saranno cancellati
            definitivamente.
            <br />
            <br />
            Se invece vuoi continuare ad utilizzare
            <br /> DOCpack, benvenuto! <br />
            Scegli il piano ed inserisci un metodo di pagamento.
          </Typography>
          <Box sx={{ display: 'inline-flex' }}>
            <Button
              variant="outlined"
              sx={{ display: 'flex', margin: 'auto auto 0 auto' }}
              onClick={() => {
                console.log('Cancellazione piano effettuata.');
                logout();
              }}
            >
              Arrivederci.
            </Button>
            <Button
              variant="contained"
              sx={{ display: 'flex', margin: 'auto auto 0 auto' }}
              onClick={() => {
                navigate('/app/payments');
              }}
            >
              Conferma Piano
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default StripeModal;
