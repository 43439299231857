import { forwardRef, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';

export interface WhiteBoxContainerInterface {
  whiteBoxWith?: number;
}

export const WhiteBoxModalContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<WhiteBoxContainerInterface>
>((props, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white.main',
          width: props.whiteBoxWith ? props.whiteBoxWith : 1000,
          maxHeight: 'calc(100vh - 32px)',
          maxWidth: 'calc(100vw - 32px)',
          overflow: 'auto',
          padding: { xs: '40px' },
          borderRadius: '24px',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
});
