import { Route, Routes } from 'react-router-dom';
import { ContactsPage, EditContactPage, NewContactPage } from './pages';

export function ContactsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ContactsPage />}>
        <Route path="new" element={<NewContactPage />} />
        <Route path="edit/:contact_uuid" element={<EditContactPage />} />
      </Route>
    </Routes>
  );
}
