import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { BrandDto, UpdateBrandDto } from '@docpack/shared/dtos';

export function useUpdateBrand() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(true);

  async function updateBrand(data: UpdateBrandDto) {
    const result = await fetcher(`/api/brand/update`, {
      method: 'PUT',
      body: data,
    });

    return handle<BrandDto>(result);
  }

  async function deleteBrand(brand_uuid: string) {
    const result = await fetcher(`/api/brand/${brand_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<BrandDto>(result);
  }

  return {
    error,
    updateBrand,
    deleteBrand,
  };
}
