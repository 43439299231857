import { Route, Routes } from 'react-router-dom';
import AcitivityPage from './pages/activity-page';

export function LogsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AcitivityPage />}></Route>
    </Routes>
  );
}
