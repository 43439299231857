import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { PlanPriceDto } from '@docpack/shared/dtos';
import useSwr from 'swr';

export function useStripe() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function changePlan(plan: string, new_price: string) {
    const result = await fetcher(`/api/workspace/change-plan`, {
      method: 'POST',
      body: {
        plan: plan,
        new_price: new_price,
      },
    });
    console.log(result);

    return handle<any>(result);
  }

  const { data } = useSwr<PlanPriceDto>(`/api/workspace/plan-prices`, fetcher);
  /*
  async function retrievePlanPrices() {
    const result = await fetcher(`/api/workspace/plan-prices`, {
      method: 'GET',
    });
    //console.log(result);

    return handle<PlanPriceDto>(result);
  }
  
  const {data, error, isValidating} = useSwr<boolean>(`/api/user/can-create`, fetcher);

  return {
    canCreateNewUser: data,
    canCreateNewUserError: error,
    isValidating,
  };*/

  return {
    error,
    changePlan,
    planPrices: data,
  };
}
