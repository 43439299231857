import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UpdateNoteDto, NoteDto, NewNoteDto } from '@docpack/shared/dtos';

export function useUpdateNote() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function newNote(data: NewNoteDto) {
    const result = await fetcher(`/api/notes/new`, {
      method: 'POST',
      body: data,
    });

    return handle<NoteDto>(result);
  }

  async function updateNote(data: UpdateNoteDto) {
    const result = await fetcher(`/api/notes/update`, {
      method: 'PUT',
      body: data,
    });

    return handle<NoteDto>(result);
  }

  return {
    error,
    newNote,
    updateNote,
  };
}
