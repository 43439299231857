import { InputText } from '@docpack/frontend/core-components';
import { PaymentInfoDto, UpdatePaymentInfoDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';
import { PaymentInfoForm } from './payment-info-form';
import {
  FormLabel,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Container,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export function PaymentInfoFormColumns(props: PaymentInfoForm) {
  const form = useForm({ defaultValues: props.defautlValues });
  const labelTheme = { color: 'primary.main' };
  const gridSx = { minWidth: '50%' };
  return (
    <FormProvider {...form}>
      <form id="payment-info-form" onSubmit={form.handleSubmit(props.onSubmit)}>
        <Stack>
          <Grid container columns={2} gap={8} flexWrap={'nowrap'}>
            <Grid item xs={1}>
              <Stack spacing={2}>
                <InputLabel sx={labelTheme}>Nome</InputLabel>
                <InputText
                  name="business_name"
                  placeholder="Inserisci nome società"
                />
                <InputLabel sx={labelTheme}>Città</InputLabel>
                <InputText name="city" placeholder="Inserisci città" />
                <InputLabel sx={labelTheme}>Indirizzo</InputLabel>
                <InputText name="address" label="Indirizzo" />
                <InputLabel sx={labelTheme}>CAP</InputLabel>
                <InputText name="postal_code" label="CAP" />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack spacing={2}>
                <InputLabel sx={labelTheme}>Telefono</InputLabel>
                <InputText name="phone" label="Telefono" />
                <InputLabel sx={labelTheme}>C.F</InputLabel>
                <InputText name="c_f" label="C.F." />
                <InputLabel sx={labelTheme}>Partita IVA</InputLabel>
                <InputText name="partita_iva" label="P.IVA" />
                <InputLabel sx={labelTheme}>Codice SDI</InputLabel>
                <InputText name="sdi_code" label="Codice SDI" />
              </Stack>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0 0 0 -1rem',
            }}
          >
            {props.functionButtons ? (
              <Button
                disabled={form.formState.isSubmitting}
                variant="outlined"
                sx={{ mt: 4, ml: 'auto', mr: '3rem' }}
                component={RouterLink}
                to={props.backLink || ''}
                startIcon={<ArrowBackIcon />}
              >
                Indietro
              </Button>
            ) : (
              <></>
            )}
            <Button
              disabled={form.formState.isSubmitting}
              type="submit"
              variant="contained"
              sx={{ mt: 4, mr: 'auto' }}
            >
              Salva
            </Button>
          </div>
          {props.functionButtons ? (
            <Button
              variant="text"
              sx={{ mt: 4, ml: 'auto', mr: 'auto' }}
              component={RouterLink}
              to={props.forwardLink || ''}
            >
              Salta per ora
            </Button>
          ) : (
            <></>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
}
