import { Box, Typography, useThemeProps } from '@mui/material';
import { CardDataDto } from '@docpack/shared/dtos';

interface CardDataProps {
  cards: CardDataDto[];
}

export function CardData(props: CardDataProps) {
  const { cards } = props;

  let cardList: any[] = [];
  if (!cards)
    return (
      <Box sx={{ padding: '5%' }}>
        <Typography color={'red'}>Nessuna carta inserita</Typography>
      </Box>
    );
  cards.forEach((card) => {
    cardList.push(
      <Typography key={card.last4 + '' + card.exp_year}>
        {card.brand?.toLocaleUpperCase()} {'\u2022'}
        {'\u2022'}
        {'\u2022'}
        {'\u2022'}
        {card.last4} - {card.exp_month}/{card.exp_year}
      </Typography>
    );
  });
  return <Box>{cardList}</Box>;
}
