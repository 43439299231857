import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { UserDto, UserPermission } from '@docpack/shared/dtos';
import UserRowDetails from './user-row-details';

export interface UserItemProps {
  user: UserDto;
  selected: boolean;
  onClick: () => void;
}

const customColumnsSizes: Array<string> = [
  '160px',
  '82px',
  '82px',
  '82px',
  '73px',
  '57px',
  '57px',
  '66px',
];

export function UserItem(props: UserItemProps) {
  const userDetails = [
    props.user.email || '',
    props.user.details?.company_name || '',
    props.user.details?.phone || '',

    `${props.user.details?.user_role}` || '',
    `${props.user.details?.user_type}` || '',
    props.user.details?.user_permission?.find(
      (item) => item === UserPermission['Scaricare']
    )
      ? true
      : false,
    props.user.details?.user_permission?.find(
      (item) => item === UserPermission['Caricare']
    )
      ? true
      : false,
    new Date(props.user.updated_at).toLocaleDateString(),
  ];

  return (
    <Grid
      container
      alignItems="center"
      data-testid="user-row-details"
      sx={{
        padding: { xs: '16px', md: '16px 0 16px 32px' },
        borderStyle: 'solid',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        borderRadius: '8px',
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      <Grid item xs={12} md={3} container alignItems="center">
        <Avatar
          sx={{
            width: { xs: '32px', md: '40px' },
            height: { xs: '32px', md: '40px' },
            marginRight: { xs: '8px', md: '16px' },
          }}
          variant="circular"
          src={props.user.details?.image_profile?.link}
          alt={props.user.details?.first_name}
        />
        <Typography variant="body3" data-testid="user-firstname">
          {props.user.details?.first_name} {props.user.details?.last_name}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ display: { xs: 'none', md: 'flex' } }}
        md={9}
        container
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="nowrap"
      >
        <UserRowDetails
          type="content"
          details={userDetails}
          customColumnsSizes={customColumnsSizes}
        />
      </Grid>
    </Grid>
  );
}

export default UserItem;
