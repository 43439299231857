import {
  BrowserRouter,
  Route,
  Routes as RoutesReactRouter,
} from 'react-router-dom';

import AppLoggedInShell from './logged-in-shell';
import { RegistrationRoutes } from '@docpack/frontend/registration-feature';
import { BrandsRoutes } from '@docpack/frontend/brands';
import {
  CategoriesRoutes,
  ProjectsFeatureRoutes,
  ShareProjectsFeatureRoutes,
} from '@docpack/frontend/projects-feature';
import { LogsRoutes } from '@docpack/frontend/logs-feature';
import { UsersRoutes } from '@docpack/frontend/users';
import { SettingsRoutes } from '@docpack/frontend/settings';
import { ContactsRoutes } from '@docpack/frontend/contacts-feature';
import { UserAccessControl } from '@docpack/frontend/core-components';
import { UserRole } from '@docpack/shared/dtos';
import { PaymentsFeatureRoutes } from '@docpack/frontend/payments-feature';
import { SuppliersRoutes } from '@docpack/frontend/suppliers-feature';
import { LandingPage } from './landing.page';
import { PlatformRegistrations } from './platform-registrations';
import { RequiredAuth } from '@docpack/frontend/client-authorization';
import { WizardRoutes } from '@docpack/frontend/wizard-feature';
import { PlanSelectionRoutes } from '../../../../libs/frontend/plan-selection/src/index';
//@docpack/frontend/plan-selection non funziona

export function Routes() {
  return (
    <BrowserRouter>
      <RoutesReactRouter>
        <Route path="landing" element={<LandingPage />} />
        <Route path="/*" element={<RegistrationRoutes />} />
        <Route
          path="/share/projects/*"
          element={<ShareProjectsFeatureRoutes />}
        />
        <Route
          path="registrazioni-piattaforma"
          element={<PlatformRegistrations />}
        />
        <Route path="setup/*" element={<WizardRoutes />} />
        <Route path="activate-plan" element={<PlanSelectionRoutes />} />
        <Route
          path="suppliers/*"
          element={
            <UserAccessControl
              options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
              redirect="/"
            >
              <SuppliersRoutes />
            </UserAccessControl>
          }
        />
        {/* Authenticated routed below */}
        <Route path="/app" element={<AppLoggedInShell />}>
          <Route path="projects/*" element={<ProjectsFeatureRoutes />} />
          <Route
            path="brands/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <BrandsRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="contacts/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <ContactsRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="settings/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <SettingsRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="activity/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <LogsRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="users/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <UsersRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="payments/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore] }}
                redirect="/"
              >
                <PaymentsFeatureRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="categories/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <CategoriesRoutes />
              </UserAccessControl>
            }
          />
          <Route
            path="suppliers/*"
            element={
              <UserAccessControl
                options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
                redirect="/"
              >
                <SuppliersRoutes />
              </UserAccessControl>
            }
          />
          <Route path="plan-selection" element={<PlanSelectionRoutes />} />
          {/* Add routes here */}
        </Route>
      </RoutesReactRouter>
    </BrowserRouter>
  );
}
