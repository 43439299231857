import {
  AddButton,
  AlertController,
  DotsMenu,
  PlaceholderText,
  SearchBar,
  Toolbar,
  ToolbarLeftContainer,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { Link, Outlet } from 'react-router-dom';
import { useGetCategories } from '../data-access/get-categories.hook';
import { useUpdateCategory } from '../data-access/update-category.hook';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { CategoryItem, ProjectItemSkeleton } from '../components';
import { useUpdateSubCategory } from '../data-access/update-subcategory.hook';
import { useGetCategoriesSelected } from '../data-access/get-categories-selected.hook';

export interface CategoriesListPageOutletContext {
  reloadCategories: () => void;
}

export const CategoriesListPage = () => {
  const formMethods = useForm({ mode: 'onChange' });
  const searchText = formMethods.watch('search_text');
  const { data, error, mutate, isLoadingMore } = useGetCategories({
    fullTextSearch: searchText,
  });
  const { deleteCategory, error: errorDeleteCategory } = useUpdateCategory();
  const { deleteSubCategory, error: errorDeleteSubCategory } =
    useUpdateSubCategory();
  const {
    categoriesSelected,
    toggleCategoriesSelected,
    isCategorySelected,
    selectAll,
    deselectAll,
  } = useGetCategoriesSelected();
  const showMessage = useMessagePopup();

  const categoriesIds = data?.map((category) => {
    return {
      id: category.category_uuid,
      type: 'category' as 'category' | 'subcategory',
      subCategories: category.subCategories,
      projects_count: category.projects_count,
    };
  });

  return (
    <>
      <AlertController
        message={
          errorDeleteCategory
            ? { type: 'error', text: errorDeleteCategory }
            : undefined
        }
      />
      <AlertController
        message={
          errorDeleteSubCategory
            ? { type: 'error', text: errorDeleteSubCategory }
            : undefined
        }
      />
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <SearchBar removeSelect formMethods={formMethods} hideDatesSearch />
      <Toolbar itemsSelectedCount={categoriesSelected.length}>
        <ToolbarLeftContainer>
          <AddButton
            linkTo={'new'}
            label={'Inserisci categoria e/o sottocategoria'}
          />
        </ToolbarLeftContainer>
        <Stack direction="row" spacing={3}>
          {categoriesSelected.length === 1 && (
            <IconButton
              color="primary"
              component={Link}
              to={
                categoriesSelected[0].type === 'category'
                  ? `edit/category/${categoriesSelected[0].id}`
                  : `edit/subcategory/${categoriesSelected[0].id}`
              }
            >
              <EditIcon fontSize={'medium'} />
            </IconButton>
          )}
          {categoriesSelected.length > 0 && (
            <IconButton
              color="primary"
              onClick={() => {
                if (
                  window.confirm(
                    `Sei sicuro di voler eliminare ${categoriesSelected.length} categorie?`
                  )
                ) {
                  Promise.all(
                    categoriesSelected.map(async (category) => {
                      if (category.type === 'category') {
                        return await deleteCategory(category.id);
                      } else {
                        return await deleteSubCategory(category.id);
                      }
                    })
                  )
                    .then(() => {
                      deselectAll();
                      mutate();
                      showMessage(
                        'Categorie eliminate con successo!',
                        'success'
                      );
                    })
                    .catch((error) => {
                      showMessage(error.message, 'error');
                    });
                }
              }}
              data-testid="delete-brand-button"
            >
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(categoriesIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Stack>
        {data && data?.length > 0 ? (
          data?.map((category) => {
            return (
              <div key={`cat-${category.category_uuid}`}>
                <CategoryItem
                  data={category}
                  selected={isCategorySelected(
                    (item) => item.id === category.category_uuid
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCategoriesSelected({
                      id: category.category_uuid,
                      type: 'category',
                      subCategories: category.subCategories,
                    });
                  }}
                  type="category"
                />
                {category.subCategories?.map((subcategory) => {
                  return (
                    <CategoryItem
                      key={`subcat-${subcategory.sub_category_uuid}`}
                      data={subcategory}
                      selected={isCategorySelected(
                        (item) => item.id === subcategory.sub_category_uuid
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCategoriesSelected({
                          id: subcategory.sub_category_uuid,
                          type: 'subcategory',
                        });
                      }}
                      type="subcategory"
                    />
                  );
                })}
              </div>
            );
          })
        ) : (
          <PlaceholderText>
            Clicca sul bottone in alto a sinistra
            <br />
            per aggiungere la tua prima categoria
          </PlaceholderText>
        )}
        {isLoadingMore && <ProjectItemSkeleton />}
      </Stack>
      <Outlet context={{ reloadCategories: mutate }} />
    </>
  );
};

export default CategoriesListPage;
