import Drawer, { DrawerProps } from '@mui/material/Drawer';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ContactsIcon from '@mui/icons-material/Contacts';
import PeopleIcon from '@mui/icons-material/People';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { useLogin } from '@docpack/frontend/client-authorization';
import FolderIcon from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import {
  useGetUserInfo,
  UserAccessControl,
  useWorkspaceSettings,
} from '@docpack/frontend/core-components';
import { UserRole } from '@docpack/shared/dtos';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

export function DrawerMenu(props: DrawerProps) {
  const { logout } = useLogin();
  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose({}, 'backdropClick');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const location = useLocation();
  const { settings } = useWorkspaceSettings();

  const lastLocation = useRef<string | null>(null);
  const { data } = useGetUserInfo();
  const theme = useTheme();
  const buttonStyle = {
    paddingLeft: '40px',
    width: '100%',
    justifyContent: 'start',
    borderRadius: 0,
    height: '60px',
    ':hover': {
      backgroundColor: 'rgba(36, 38, 40, 0.05)',
    },
  };
  const buttonStyleDisabled = {
    pointerEvents: 'none',
    color: theme.palette.secondary.dark,
  };

  useEffect(() => {
    if (lastLocation.current === null) {
      lastLocation.current = location.pathname;
    } else {
      if (lastLocation.current !== location.pathname && props.open) {
        onClose();
      }
      lastLocation.current = location.pathname;
    }
  }, [location.pathname, onClose, props.open]);

  return (
    <Drawer variant="permanent" anchor="right" {...props}>
      <Box marginLeft="auto">
        <Button variant="text" onClick={onClose}>
          <CloseIcon fontSize="large" />
        </Button>
      </Box>

      <Stack alignItems="flex-start" justifyContent="center" height="100%">
        <Button
          variant="text"
          component={Link}
          to="projects"
          sx={{
            ...buttonStyle,
            ...(lastLocation.current === '/app/projects' && {
              ...buttonStyleDisabled,
            }),
          }}
        >
          <AutoAwesomeMosaicIcon
            sx={{ mr: '32px', maxHeight: '32px' }}
            fontSize="large"
          />
          Progetti
        </Button>

        <UserAccessControl
          options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
        >
          <Button
            variant="text"
            component={Link}
            to="contacts"
            sx={{
              ...buttonStyle,
              ...(lastLocation.current === '/app/contacts' && {
                ...buttonStyleDisabled,
              }),
            }}
          >
            <ContactsIcon
              sx={{ mr: '32px', maxHeight: '32px' }}
              fontSize="large"
            />
            Rubrica
          </Button>

          <Button
            variant="text"
            component={Link}
            to="users"
            sx={{
              ...buttonStyle,
              ...(lastLocation.current === '/app/users' && {
                ...buttonStyleDisabled,
              }),
            }}
          >
            <PeopleIcon
              sx={{ mr: '32px', maxHeight: '32px' }}
              fontSize="large"
            />
            Utenti
          </Button>

          <Button
            variant="text"
            component={Link}
            to="brands"
            sx={{
              ...buttonStyle,
              ...(lastLocation.current === '/app/brands' && {
                ...buttonStyleDisabled,
              }),
            }}
          >
            <FolderIcon
              sx={{ mr: '32px', maxHeight: '32px' }}
              fontSize="large"
            />
            Marchi
          </Button>

          {settings?.categories_and_subs_flag && (
            <Button
              variant="text"
              component={Link}
              to="categories"
              sx={{
                ...buttonStyle,
                ...(lastLocation.current === '/app/categories' && {
                  ...buttonStyleDisabled,
                }),
              }}
            >
              <CategoryIcon
                sx={{ mr: '32px', maxHeight: '32px' }}
                fontSize="large"
              />
              Categorie
            </Button>
          )}

          {settings?.suppliers_flag && (
            <Button
              variant="text"
              component={Link}
              to="suppliers"
              sx={{
                ...buttonStyle,
                ...(lastLocation.current === '/app/suppliers' && {
                  ...buttonStyleDisabled,
                }),
              }}
            >
              <PeopleOutlineIcon
                sx={{ mr: '32px', maxHeight: '32px' }}
                fontSize="large"
              />
              Fornitori
            </Button>
          )}

          <Button
            variant="text"
            component={Link}
            to="activity"
            sx={{
              ...buttonStyle,
              ...(lastLocation.current === '/app/activity' && {
                ...buttonStyleDisabled,
              }),
            }}
          >
            <HistoryIcon
              sx={{ mr: '32px', maxHeight: '32px' }}
              fontSize="large"
            />
            Report attività
          </Button>

          <UserAccessControl options={{ roles: [UserRole.Amministratore] }}>
            <Button
              variant="text"
              component={Link}
              to="payments"
              sx={{
                ...buttonStyle,
                ...(lastLocation.current === '/app/payments' && {
                  ...buttonStyleDisabled,
                }),
              }}
            >
              <CreditCardIcon
                sx={{ mr: '32px', maxHeight: '32px' }}
                fontSize="large"
              />
              Dati società
            </Button>
          </UserAccessControl>

          <Button
            variant="text"
            component={Link}
            to="settings"
            sx={{
              ...buttonStyle,
              ...(lastLocation.current === '/app/settings' && {
                ...buttonStyleDisabled,
              }),
            }}
          >
            <SettingsIcon
              sx={{ mr: '32px', maxHeight: '32px' }}
              fontSize="large"
            />
            Impostazioni
          </Button>
        </UserAccessControl>

        <Button
          variant="text"
          onClick={logout}
          sx={{
            ...buttonStyle,
          }}
        >
          <LogoutIcon
            sx={{ mr: '32px', maxHeight: '32px', transform: 'rotate(180deg)' }}
            fontSize="large"
          />
          Log out
        </Button>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '24px',
        }}
      >
        <AccountCircleIcon sx={{ mr: '10px', width: '22px', height: '22px' }} />
        {data?.username}
      </Box>
    </Drawer>
  );
}
