import { IsEmail, IsOptional, IsString, IsUUID } from 'class-validator';

export class UpdateContactDto {
  @IsString()
  contact_uuid: string;

  @IsString({ message: 'Campo richiesto' })
  first_name: string;

  @IsString({ message: 'Campo richiesto' })
  last_name: string;

  @IsEmail({}, { message: 'Fornire un email valida' })
  email: string;

  @IsOptional()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  company_name: string;

  @IsOptional()
  @IsString()
  description: string;

  @IsOptional()
  @IsUUID()
  image_contact_asset_uuid?: string;
}
