import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SendEmailProjectForm } from '../components/send-email-project-form/send-email-project-form';
import { ProjectsListPageOutletContext } from './projects-list-page';
import { extractDisabledAssets } from './project-assets-download-page';
import { useSendEmailProjects } from '../data-access';
import {
  AlertController,
  useFormMethods,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { ProjectDto, ShareMultipleProjectsDto } from '@docpack/shared/dtos';

function generateSubjectFrom(project: ProjectDto) {
  return `${project.name} - ${project.project_code}`;
}

export function SendEmailProjectPage() {
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();
  const disabledAssets = extractDisabledAssets(outletContext.selectedProjects, {
    photo: true,
    layout: true,
    exe: true,
  });
  const { sendEmail, error } = useSendEmailProjects();
  const formMethods = useFormMethods(ShareMultipleProjectsDto, true, {
    projects: outletContext.selectedProjects?.map(
      (project) => project.project_uuid
    ),
    recipient: [],
    cc: [],
    ccn: [],
    types: [],
    subject: `Condivisione: ${outletContext.selectedProjects
      ?.map(generateSubjectFrom)
      ?.join(', ')}`,
  });
  const navigate = useNavigate();

  const showMessage = useMessagePopup();

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <Modal
        open={true}
        onClose={() => navigate('..')}
        data-testid="send-email-project-modal"
      >
        <WhiteBoxModalContainer>
          <Typography
            variant="h3"
            id="parent-modal-title"
            mb={3}
            sx={{ display: 'block', textAlign: { xs: 'center', md: 'left' } }}
          >
            Spedisci via mail
          </Typography>
          <SendEmailProjectForm
            onClose={() => navigate('..')}
            onSubmit={(values) =>
              sendEmail(values)
                .then(() => navigate('..'))
                .then(() =>
                  showMessage('Progetto condiviso correttamente!', 'success')
                )
                .catch((error) => console.error(error))
            }
            disabledAssets={disabledAssets}
            formMethods={formMethods}
          />
        </WhiteBoxModalContainer>
      </Modal>
    </>
  );
}

export default SendEmailProjectPage;
