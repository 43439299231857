import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { BrandDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useOptionsBrands() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<Array<BrandDto>>(`/api/brand?orderBy=a-z`, fetcher);

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data: data?.map((brand) => ({
      label: brand.name,
      value: brand.brand_uuid,
    })),
  };
}
