import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { BrandDto } from '@docpack/shared/dtos';
import Skeleton from '@mui/material/Skeleton';

export interface BrandItemProps {
  data: BrandDto;
  selected: boolean;
  onClick: () => void;
}

export function BrandItem(props: BrandItemProps) {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        padding: { xs: '16px', md: '16px 32px' },
        borderStyle: 'solid',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        borderRadius: '8px',
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
      data-testid="brand-item"
    >
      <Grid item xs={8} lg={9} container alignItems="center">
        <Avatar
          sx={{
            width: { xs: '32px', md: '48px' },
            height: { xs: '32px', md: '48px' },
            marginRight: { xs: '16px', md: '32px' },
          }}
          variant="square"
          src={props.data.logo}
          alt={props.data.name}
        />
        <Typography variant="body3" data-testid="brand-item-name">
          {props.data.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        lg={3}
        container
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="nowrap"
      >
        {
          <Typography variant="body1">
            {props.data.projectCount === 1
              ? `1 progetto`
              : `${props.data.projectCount} progetti`}
          </Typography>
        }
      </Grid>
    </Grid>
  );
}

export function BrandItemSkeleton() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        p: '16px 32px',
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'gray.light',
        borderRadius: '8px',
        mb: 1,
      }}
    >
      <Skeleton variant="rectangular" width={50} height={50} />
      <Skeleton width="calc(100% - 90px)" style={{ marginLeft: 20 }} />
    </Grid>
  );
}

export default BrandItem;
