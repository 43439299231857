import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormProvider, useController, UseFormReturn } from 'react-hook-form';
import {
  formatTypes,
  orderTypes,
  ReportProjectDto,
} from '@docpack/shared/dtos';
import {
  CheckboxInput,
  InfoTooltip,
  SingleSelect,
} from '@docpack/frontend/core-components';
import AssetTypeButton from '../asset-type-button/asset-type-button';
import { Stack, Typography } from '@mui/material';
import { Dispatch, useState } from 'react';

export interface ReportFormProps {
  handleClose: () => void;
  onSubmit: (val: ReportProjectDto) => Promise<unknown>;
  formMethods: UseFormReturn<ReportProjectDto, object>;
}

export const ExcelIcon = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.347 17.116h-5.09v-2.604h5.09v2.604zm0 1.489h-5.09v2.604h5.09v-2.604zm0-12.28h-5.09V8.93h5.09V6.326zm0 4.094h-5.09v2.604h5.09V10.42zm0 12.279h-5.09v2.604h5.09v-2.604zm3.515 5.135c-.146.774-1.054.792-1.661.818H18.895V32h-2.257L0 29.023V2.98L16.736 0h2.16v2.966h10.916c.614.026 1.29-.019 1.824.357.375.55.339 1.25.364 1.883l-.015 19.37c-.018 1.084.099 2.189-.123 3.257zM13.33 21.994c-1.003-2.083-2.025-4.152-3.024-6.236a777.154 777.154 0 0 0 2.933-6.098c-.825.04-1.65.093-2.472.152-.614 1.53-1.33 3.018-1.81 4.6-.447-1.493-1.04-2.93-1.581-4.384-.8.044-1.6.093-2.4.141.844 1.905 1.742 3.784 2.56 5.7-.964 1.861-1.865 3.748-2.8 5.62.797.033 1.593.066 2.389.078.567-1.481 1.272-2.907 1.767-4.417.443 1.622 1.195 3.122 1.813 4.673.877.064 1.749.12 2.625.171zM30.266 4.733h-11.37v1.593h2.907V8.93h-2.908v1.489h2.909v2.604h-2.909v1.489h2.909v2.604h-2.909v1.489h2.909v2.604h-2.909v1.489h2.909v2.604h-2.909v1.721h11.371V4.733z"
        fill="#242628"
      />
    </svg>
  );
};

export const PdfIcon = () => {
  return (
    <svg width="24" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 28V10.657a4 4 0 0 0-1.172-2.829l-6.656-6.656A4 4 0 0 0 13.343 0H4a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4zM15 6a3 3 0 0 0 3 3h2a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v2z"
        fill="#242628"
      />
      <path
        d="M5.207 28.174a1.62 1.62 0 0 1-.876-.84c-.39-.776-.26-1.552.16-2.204.396-.614 1.052-1.136 1.794-1.574a15.36 15.36 0 0 1 2.964-1.29 39.38 39.38 0 0 0 2.124-4.454 14.533 14.533 0 0 1-.86-2.59c-.172-.8-.238-1.592-.092-2.272.15-.708.548-1.344 1.3-1.646.384-.154.8-.24 1.204-.154a1.4 1.4 0 0 1 .954.73c.176.328.24.712.254 1.076a6.39 6.39 0 0 1-.094 1.228c-.168 1.02-.54 2.268-1.04 3.588a21.91 21.91 0 0 0 1.96 3.372 11.51 11.51 0 0 1 2.668.1c.728.132 1.468.39 1.92.93.24.288.386.64.4 1.036.014.384-.094.764-.276 1.126a2.08 2.08 0 0 1-.708.832c-.302.198-.66.294-1.02.276-.662-.028-1.308-.392-1.866-.834a11.426 11.426 0 0 1-1.822-1.9 23.301 23.301 0 0 0-3.994.812 22.614 22.614 0 0 1-2.04 3.02c-.584.7-1.218 1.312-1.854 1.574-.365.166-.78.186-1.16.058zm2.758-3.802c-.332.152-.64.312-.918.476-.656.388-1.082.766-1.294 1.094-.188.29-.192.5-.08.722.02.044.04.072.052.088a.54.54 0 0 0 .07-.024c.274-.112.71-.47 1.27-1.144.318-.39.619-.794.9-1.212zm3.28-2.66a25.411 25.411 0 0 1 2.02-.386 23.468 23.468 0 0 1-1.02-1.716c-.314.71-.647 1.41-1 2.1v.002zm4.892.9c.3.326.592.6.87.82.48.38.814.506.996.512a.214.214 0 0 0 .14-.03.614.614 0 0 0 .188-.25.872.872 0 0 0 .118-.4.19.19 0 0 0-.052-.126c-.104-.124-.4-.304-1.036-.418a7.749 7.749 0 0 0-1.224-.106v-.002zm-3.98-7.012c.168-.543.302-1.096.4-1.656a4.71 4.71 0 0 0 .076-.93c0-.134-.021-.268-.064-.396-.1.013-.198.04-.29.08-.174.07-.316.212-.392.566-.08.384-.06.938.092 1.644.048.222.108.454.18.692h-.002z"
        fill="#242628"
      />
    </svg>
  );
};

export function FormatFileSelection(props: { onSelection: Dispatch<boolean> }) {
  const { field } = useController({ name: 'format' });

  return (
    <Stack spacing={2}>
      <Typography variant="body3">Formato File</Typography>
      <Stack direction="row" spacing={3}>
        <AssetTypeButton
          name="Excel"
          isSelected={field.value === formatTypes.Csv}
          onClick={() => {
            field.onChange(formatTypes.Csv);
            props.onSelection(true);
          }}
        >
          <ExcelIcon />
        </AssetTypeButton>
        <AssetTypeButton
          name="PDF"
          isSelected={field.value === formatTypes.Pdf}
          onClick={() => {
            field.onChange(formatTypes.Pdf);
            props.onSelection(false);
          }}
          cy="report-projects-pdf"
        >
          <PdfIcon />
        </AssetTypeButton>
      </Stack>
    </Stack>
  );
}

export const ReportForm = (props: ReportFormProps) => {
  const [disabledPhoto, setDisablePhoto] = useState(false);

  return (
    <FormProvider {...props.formMethods}>
      <form
        onSubmit={props.formMethods.handleSubmit(props.onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <InfoTooltip text=" Hai la possibilità di scaricare tutti i progetti, anche per singolo marchio o gruppi di marchi, che hai caricato sulla tua DOCpack. Il report ti permetterà di visualizzare le fotografie dei singoli progetti, la data di caricamento e il nome del progetto." />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column" sx={{ pr: 4 }}>
            <SingleSelect
              name="orderBy"
              label="Ordinamento"
              required
              options={[
                { label: 'A-Z', value: orderTypes.name_asc },
                { label: 'Z-A', value: orderTypes.name_desc },
                {
                  label: 'Numero di progetto cresc.',
                  value: orderTypes.job_asc,
                },
                {
                  label: 'Numero di progetto decresc.',
                  value: orderTypes.job_desc,
                },
                { label: 'Data più lontana', value: orderTypes.date_asc },
                { label: 'Data più recente', value: orderTypes.date_desc },
              ]}
              selectCy="report-projects-order-input"
              optionCy="report-projects-order-option"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormatFileSelection onSelection={setDisablePhoto} />
          </Grid>
          <Grid container item xs={12} justifyContent="center" mt={5}>
            <CheckboxInput
              name="photo"
              options={{
                label: 'Foto',
                value: '1',
                disabled: disabledPhoto,
              }}
              cy="report-include-photo-input"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '32px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="report-projects-submit-button"
            >
              Scarica
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReportForm;
