import {
  IsArray,
  IsEmail,
  IsEnum,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { UserRole, UserType, UserPermission } from './enums';
export class NewUserDto {
  @IsEmail({}, { message: 'Fornire un email valida' })
  email: string;

  @IsString({ message: 'Campo richiesto' })
  first_name: string;

  @IsString({ message: 'Campo richiesto' })
  last_name: string;

  @IsOptional()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  company_name: string;

  @IsArray({ message: 'Seleziona un o piu brand' })
  brands: string[];

  @IsEnum(UserRole, { message: 'Selezionare un ruolo' })
  user_role: UserRole;

  @IsEnum(UserType, { message: 'Selezionare una tipologia di utente' })
  user_type: UserType;

  @IsEnum(UserPermission, { each: true })
  user_permission: UserPermission[];

  @IsOptional()
  @IsString()
  description: string;

  @IsOptional()
  @IsUUID()
  image_profile_asset_uuid: string;
}
