import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { SearchUsersQueryDto, UserDto } from '@docpack/shared/dtos';

export function useGetUsers(filter?: SearchUsersQueryDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const params = new URLSearchParams();

  if (filter?.orderBy) {
    params.append('orderBy', filter?.orderBy);
  }

  if (filter?.search) {
    params.append('search', filter?.search);
  }

  if (filter?.search_date_from) {
    params.append('search_date_from', filter?.search_date_from.toString());
  }

  if (filter?.search_date_to) {
    params.append('search_date_to', filter?.search_date_to.toString());
  }

  const result = useSwr<Array<UserDto>>(`/api/user?${params}`, fetcher);

  const data = handle(result.data);

  return {
    ...result,
    data,
    error,
  };
}
