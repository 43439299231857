import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  ProjectAssetsDownload,
  SelectAssetsToDownloadForm,
} from '../components/project-assets-download/project-assets-download';
import { ProjectsListPageOutletContext } from './projects-list-page';
import { saveAs } from 'file-saver';
import { useGetSharedProjects } from '../data-access';
import { AssetsDownloadTypes } from '@docpack/shared/dtos';
import {
  useFormMethods,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';

export function invertAviableAssetTypes(
  aviableTypes?: Array<AssetsDownloadTypes>
) {
  return Object.entries(AssetsDownloadTypes)
    .map(([_, type]) => type)
    .filter((type) => !aviableTypes?.includes(type));
}

export function SharedProjectAssetsDownloadPage() {
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();

  const { aviableTypes } = useGetSharedProjects(outletContext.share_token);

  const selectedProjects = outletContext.selectedProjects || [];

  const disabledAssets = invertAviableAssetTypes(aviableTypes);

  const formMethods = useFormMethods(SelectAssetsToDownloadForm);
  const navigate = useNavigate();

  return (
    <Modal
      open={true}
      onClose={() => navigate('..')}
      data-testid="download-assets-project-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={573}>
        <Typography variant="h3" id="parent-modal-title" mb={4}>
          Download
        </Typography>
        <ProjectAssetsDownload
          onClose={() => navigate('..')}
          onSubmit={(data) => {
            saveAs(
              `/api/project/download/share?projects=${selectedProjects
                .map((proj) => proj.project_uuid)
                .join(',')}&types=${data.assetsTypes.join(',')}&share_token=${
                outletContext?.share_token
              }`
            );
            navigate('..');
          }}
          disabledAssets={disabledAssets}
          formMethods={formMethods}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default SharedProjectAssetsDownloadPage;
