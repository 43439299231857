import { InputText } from '@docpack/frontend/core-components';
import { NewSupplierDto, UpdateSupplierDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export interface SupplierFormPropsNew {
  handleClose: () => void;
  onSubmit: (val: NewSupplierDto) => Promise<unknown>;
  defaultValues?: Partial<NewSupplierDto>;
  formMethods: UseFormReturn<NewSupplierDto, object>;
  isNew: true;
  isEdit?: false;
}

export interface SupplierFormPropsEdit {
  handleClose: () => void;
  onSubmit: (val: UpdateSupplierDto) => Promise<unknown>;
  defaultValues?: Partial<UpdateSupplierDto>;
  formMethods: UseFormReturn<UpdateSupplierDto, object>;
  isNew?: false;
  isEdit: true;
}

export const SupplierForm = (
  props: SupplierFormPropsNew | SupplierFormPropsEdit
) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormProvider {...(props.formMethods as any)}>
      <form
        onSubmit={props.formMethods.handleSubmit((values) => {
          if (props.isNew) {
            return props.onSubmit(values as NewSupplierDto);
          }
          return props.onSubmit(values as UpdateSupplierDto);
        })}
      >
        <Stack spacing={2}>
          <InputText name="name" label="Nome fornitore" required />
          <InputText name="code" label="Codice fornitore" />
        </Stack>
        <Grid container sx={{ mt: '32px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="add-project-submit-button"
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SupplierForm;
