import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { CategoriesPaginatedDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useOptionsCategories() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<CategoriesPaginatedDto>(
    `/api/project/categories`,
    fetcher
  );

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data: data?.data?.map((category) => ({
      label: category.name,
      value: category.category_uuid,
    })),
  };
}
