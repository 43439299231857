import { useFetcher } from '@docpack/frontend/client-authorization';
import { NoteDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import useSWR from 'swr';

export function useGetNotes() {
  const fetcher = useFetcher();
  const { error } = useHandleRespError(false);

  const result = useSWR<Array<NoteDto>>(() => {
    return `/api/notes`;
  }, fetcher);

  return {
    ...result,
    error,
  };
}
