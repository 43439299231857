import { useController, useForm } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUploadFile } from '../data-access/upload-file.hook';
import { useGetAssetEntity } from '../data-access';
import { AssetDto } from '@docpack/shared/dtos';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { useHandleDrag } from '../hooks';
import Box from '@mui/material/Box';
import { useDeleteAssetEntity } from '../data-access/delete-asset-entity.hook';
import { InfoTooltip } from '../components';
import { PreviewAvatar } from './upload-image';

export function PdfUploadIcon(props: { color: 'white' | 'gray' | 'red' }) {
  const fill = useMemo(() => {
    if (props.color === 'gray') {
      return '#bcbfc2';
    }
    if (props.color === 'white') {
      return '#ffffff';
    }
    if (props.color === 'red') {
      return '#ce4525';
    }
    return 'black';
  }, [props.color]);

  return (
    <svg
      width="90"
      height="150"
      viewBox="0 0 48 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(0.7)', marginTop: 0 }}
    >
      <path
        d="M48 56V19.657a4 4 0 0 0-1.172-2.829L31.172 1.172A4 4 0 0 0 28.343 0H8a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8zM30 12a6 6 0 0 0 6 6h6a2 2 0 0 1 2 2v36a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a2 2 0 0 1 2 2v6z"
        fill={fill}
      />
      <path
        d="M10.412 56.349a3.24 3.24 0 0 1-1.752-1.68c-.78-1.552-.52-3.104.32-4.408.792-1.228 2.104-2.272 3.588-3.148a30.723 30.723 0 0 1 5.928-2.58 78.767 78.767 0 0 0 4.248-8.908 29.079 29.079 0 0 1-1.72-5.18c-.344-1.6-.476-3.184-.184-4.544.3-1.416 1.096-2.688 2.6-3.292.768-.308 1.6-.48 2.408-.308a2.8 2.8 0 0 1 1.908 1.46c.352.656.48 1.424.508 2.152.028.752-.048 1.584-.188 2.456-.336 2.04-1.08 4.536-2.08 7.176a43.816 43.816 0 0 0 3.92 6.744c1.78-.141 3.571-.074 5.336.2 1.456.264 2.936.78 3.84 1.86.48.576.772 1.28.8 2.072.028.768-.188 1.528-.552 2.252a4.16 4.16 0 0 1-1.416 1.664 3.424 3.424 0 0 1-2.04.552c-1.324-.056-2.616-.784-3.732-1.668a22.847 22.847 0 0 1-3.644-3.8c-2.705.307-5.378.85-7.988 1.624a45.23 45.23 0 0 1-4.08 6.04c-1.168 1.4-2.436 2.624-3.708 3.148-.73.33-1.56.372-2.32.116zm5.516-7.604a20.07 20.07 0 0 0-1.836.952c-1.312.776-2.164 1.532-2.588 2.188-.376.58-.384 1-.16 1.444a.91.91 0 0 0 .104.176c.048-.013.095-.03.14-.048.548-.224 1.42-.94 2.54-2.288a32.718 32.718 0 0 0 1.8-2.424zm6.56-5.32a50.84 50.84 0 0 1 4.04-.772 47.006 47.006 0 0 1-2.04-3.432 83.186 83.186 0 0 1-2 4.2v.004zm9.784 1.8c.6.652 1.184 1.2 1.74 1.64.96.76 1.628 1.012 1.992 1.024a.427.427 0 0 0 .28-.06c.166-.132.296-.304.376-.5.142-.244.223-.519.236-.8a.38.38 0 0 0-.104-.252c-.208-.248-.8-.608-2.072-.836a15.507 15.507 0 0 0-2.448-.212v-.004zM24.312 31.2a26.78 26.78 0 0 0 .8-3.312c.124-.752.172-1.372.152-1.86a2.451 2.451 0 0 0-.128-.792 2.07 2.07 0 0 0-.58.16c-.348.14-.632.424-.784 1.132-.16.768-.12 1.876.184 3.288.096.444.216.908.36 1.384h-.004z"
        fill={fill}
      />
    </svg>
  );
}

export function ZipUploadIcon(props: { color: 'white' | 'gray' | 'red' }) {
  const fill = useMemo(() => {
    if (props.color === 'gray') {
      return '#bcbfc2';
    }
    if (props.color === 'white') {
      return '#ffffff';
    }
    if (props.color === 'red') {
      return '#ce4525';
    }
    return 'black';
  }, [props.color]);

  return (
    <svg
      width="90"
      height="150"
      viewBox="0 0 48 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(0.7)', marginTop: 0 }}
    >
      <path
        d="M18 30a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3.752l1.6 6.396a4 4 0 0 1-1.664 4.296l-3.72 2.48a4 4 0 0 1-4.436 0l-3.72-2.48a4 4 0 0 1-1.66-4.296l1.6-6.396V30zm8 0h-4v3.752c0 .328-.04.654-.12.972l-1.6 6.392 3.72 2.48 3.72-2.48-1.6-6.392a4.007 4.007 0 0 1-.12-.972V30z"
        fill={fill}
      />
      <path
        d="M0 8a8 8 0 0 1 8-8h32a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8zm22-4H8a4 4 0 0 0-4 4v48a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4H28v4h-4v4h4v4h-4v4h4v4h-6v-4h-4v-4h4v-4h-4V8h4V4z"
        fill={fill}
      />
    </svg>
  );
}

export function ImageUploadIcon(props: { color: 'white' | 'gray' | 'red' }) {
  const fill = useMemo(() => {
    if (props.color === 'gray') {
      return '#bcbfc2';
    }
    if (props.color === 'white') {
      return '#ffffff';
    }
    if (props.color === 'red') {
      return '#ce4525';
    }
    return 'black';
  }, [props.color]);

  return (
    <svg
      width="120"
      height="150"
      viewBox="0 0 64 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(0.7)', marginTop: 0 }}
    >
      <path d="M24 18a6 6 0 1 1-12 0 6 6 0 0 1 12 0z" fill={fill} />
      <path
        d="M8 0a8 8 0 0 0-8 8v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8zm48 4a4 4 0 0 1 4 4v26l-15.108-7.788a2 2 0 0 0-2.308.372l-14.84 14.84-10.64-7.088a2 2 0 0 0-2.52.248L4 44V8a4 4 0 0 1 4-4h48z"
        fill={fill}
      />
    </svg>
  );
}

export function PreviewItem(props: { asset: AssetDto; hideImage?: Boolean }) {
  if (props.hideImage) return <></>;
  if (props.asset.mime_type.includes('image')) {
    return <ImageUploadIcon color="white" />;
  }
  if (props.asset.mime_type.includes('pdf')) {
    // pdf svg
    return <PdfUploadIcon color="white" />;
  } else {
    // Zip svg
    return <ZipUploadIcon color="white" />;
  }
}

export function AcceptItem(props: {
  accept: UploadFileProps['accept'];
  error?: boolean;
}) {
  if (props.accept === 'image/*') {
    return <ImageUploadIcon color={props.error ? 'red' : 'gray'} />;
  }
  if (props.accept === '.pdf') {
    // pdf svg
    return <PdfUploadIcon color={props.error ? 'red' : 'gray'} />;
  } else {
    // Zip svg
    return <ZipUploadIcon color={props.error ? 'red' : 'gray'} />;
  }
}

export const DisableInteractivity = styled(Stack)`
  pointer-events: none;
`;

export interface UploadFileProps {
  label?: string;
  name: string;
  required?: boolean;
  accept?: 'image/*' | '.pdf' | '.zip,.rar,.gz';
  height?: string;
  brand_uuid?: string;
  project_uuid?: string;
  disabled?: boolean;
  tooltipText?: string;
  cy?: string;
  wizard?: Boolean;
}

export function isAccetableFile(mimeType?: string | null, accept?: string) {
  if (!mimeType) {
    return false;
  }

  if (!accept) {
    return true;
  }

  if (accept.includes(',')) {
    if (
      accept
        .split('*')
        .join('')
        .split('.')
        .join('')
        .split(',')
        .some((v) => mimeType.includes(v))
    ) {
      return true;
    }
    return false;
  }

  return mimeType.includes(accept.split('*').join('').split('.').join(''));
}

export function UploadFile(props: UploadFileProps) {
  const { setError } = useForm();
  const { fieldState, field } = useController({
    name: props.name,
    rules: { required: props.required },
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadFile } = useUploadFile();
  const { data, error } = useGetAssetEntity(field.value);
  const [loading, setLoading] = useState(false);
  const { handlerProps, dragging, draggedFileType } = useHandleDrag({
    onLeave: (files) => upload(files[0]),
  });
  const { deleteAssetEntity } = useDeleteAssetEntity();

  const upload = (file?: File) => {
    if (file) {
      if (!isAccetableFile(file.type, props.accept)) {
        return;
      }
      setLoading(true);
      uploadFile({
        file,
        project_uuid: props.project_uuid,
        brand_uuid: props.brand_uuid,
      })
        .then((res) => {
          setLoading(false);
          field.onChange(res?.asset_uuid);
        })
        .catch((error) => {
          setLoading(false);
          alert(`Unable to upload file: ${error.message}`);
        });
    }
  };

  useEffect(() => {
    if (error && fieldState.error?.message !== error) {
      setError(props.name, { message: error });
    }
  }, [error, fieldState.error?.message, props.name, setError]);

  const isAccettableFileTheOneOnDrag =
    dragging && isAccetableFile(draggedFileType, props.accept);

  return (
    <Stack direction="row" spacing={1}>
      <Stack
        width="100%"
        height={props.height ? props.height : '100%'}
        sx={{ opacity: props.disabled ? '0.5' : 1 }}
      >
        {!loading ? (
          !data ? (
            <Stack
              sx={{
                borderRadius: '20px',
                border: '4px dashed',
                borderColor: dragging
                  ? isAccettableFileTheOneOnDrag
                    ? 'gray.main'
                    : 'error.main'
                  : 'gray.main',
                opacity: dragging ? 0.5 : 1,
                paddingBottom: '24px',
                height: '100%',
                width: '100%',
                mt: 1,
                ':hover': {
                  cursor: props.disabled ? 'not-allowed' : 'pointer',
                },
              }}
              {...handlerProps}
              onClick={() => inputRef.current?.click()}
            >
              <DisableInteractivity justifyContent="center" alignItems="center">
                <AcceptItem
                  accept={props.accept}
                  error={dragging && !isAccettableFileTheOneOnDrag}
                />
                <Typography
                  variant="caption"
                  color={
                    dragging
                      ? isAccettableFileTheOneOnDrag
                        ? undefined
                        : 'error.main'
                      : undefined
                  }
                >
                  {dragging
                    ? isAccettableFileTheOneOnDrag
                      ? 'Lascia per caricare'
                      : 'Tipo file non supportato'
                    : props.label}
                </Typography>
              </DisableInteractivity>
            </Stack>
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{
                position: 'relative',
                borderRadius: '20px',
                backgroundColor: 'secondary.main',
                paddingBottom: '30px',
                height: '100%',
                width: '100%',
                mt: 1,
                ':hover': {
                  cursor: props.disabled ? 'not-allowed' : 'pointer',
                },
                ':hover > div': props.disabled
                  ? null
                  : {
                      opacity: 1,
                      visibility: 'visible',
                    },
              }}
              onClick={() => inputRef.current?.click()}
            >
              <Box
                sx={{
                  opacity: 0,
                  visibility: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: '20px',
                  backgroundColor: 'secondary.light',
                  paddingBottom: '30px',
                  height: '100%',
                  width: '100%',
                  zIndex: 9,
                  ':hover': {
                    cursor: 'pointer',
                  },
                  pointerEvents: props.disabled ? 'none' : 'all',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm(`Sei sicuro di voler eliminare questo file?`)
                  ) {
                    deleteAssetEntity(data.asset_uuid)
                      .then(() => {
                        field.onChange(undefined);
                      })
                      .catch((error) => {
                        console.error('Error deleting asset: ', error);
                        alert(`Unable to delete asset: ${error.message}`);
                      });
                  }
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    padding: '6px 12px',
                    border: '1px solid #fff',
                    borderRadius: '8px',
                    color: '#fff',
                  }}
                >
                  Remove
                </Typography>
              </Box>

              <PreviewItem asset={data} hideImage={props.wizard} />
              {props.wizard ? (
                <PreviewAvatar uri={data.link} alt={props.name} />
              ) : (
                <></>
              )}
              <Typography
                variant="body1"
                color="white.main"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                maxWidth="100%"
                sx={{ marginTop: '0 !important' }}
              >
                {data.user_file_name}
              </Typography>
            </Stack>
          )
        ) : (
          <Stack sx={{ height: '266px' }}>
            <CircularProgress sx={{ margin: 'auto' }} />
          </Stack>
        )}
        <input
          type="file"
          ref={inputRef}
          accept={props.accept}
          onChange={() => upload(inputRef?.current?.files?.[0])}
          disabled={loading || props.disabled}
          style={{ visibility: 'hidden', position: 'absolute' }}
          data-testid={props.cy}
        />
        {fieldState.error?.message && (
          <Alert severity="error">
            <Typography>{fieldState.error?.message}</Typography>
          </Alert>
        )}
      </Stack>
      {props.tooltipText && (
        <InfoTooltip text={props.tooltipText} alignVerticalTop />
      )}
    </Stack>
  );
}

export default UploadFile;
