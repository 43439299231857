import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetSingleBrand } from '../data-access/get-single-brand.hook';
import Skeleton from '@mui/material/Skeleton';
import { EditBrandForm } from '../components';
import { BrandsListPageOutletContext } from './brands-list-page';
import { useUpdateBrand } from '../data-access/update-brand.hook';
import {
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { UpdateBrandDto } from '@docpack/shared/dtos';

export function EditBrandPage() {
  const params = useParams();
  const brand_uuid = params['brand_uuid'] || '';
  const { data, mutate } = useGetSingleBrand(brand_uuid);
  const outletContext = useOutletContext<BrandsListPageOutletContext>();
  const { updateBrand } = useUpdateBrand();
  const defaultValues = {
    brand_uuid: data?.brand_uuid,
    name: data?.name,
    asset_uuid: data?.asset_uuid,
  };
  const formMethods = useFormMethods(UpdateBrandDto, !!data, defaultValues);
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      data-testid="edit-brand-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={500}>
        <Typography
          variant="h3"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Modifica marchio
        </Typography>
        {data ? (
          <EditBrandForm
            cancelBtnOnClick={() => handleClose()}
            brand_uuid={data.brand_uuid}
            onSubmit={(values) =>
              updateBrand({
                brand_uuid: data.brand_uuid,
                name: values.name,
                asset_uuid: values.asset_uuid,
              })
                .then(() => {
                  mutate();
                  if (outletContext?.reloadBrands) outletContext.reloadBrands();
                })
                .then(() =>
                  showMessage(
                    'Marchio modificato con successo',
                    'success',
                    () => handleClose(true)
                  )
                )
                .catch((error) => {
                  console.error('Error fetching data: ', error);
                })
            }
            formMethods={formMethods}
          />
        ) : (
          <>
            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="rectangular" width={'100%'} height={400} />
          </>
        )}
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default EditBrandPage;
