import React from 'react';
import { Box, Button, Stack, Alert } from '@mui/material';
import { PlanData } from './plan-data';
import { SWRResponse } from 'swr';
import { useNavigate } from 'react-router-dom';
import { PlanDataDto } from '@docpack/shared/dtos';
import { Typography, useThemeProps } from '@mui/material';
import moment from 'moment';

interface PlanStatusProps {
  planData: SWRResponse<PlanDataDto, any>;
  unsubscribePlan: () => void;
  changePlan: () => void;
}
export function PlanStatus(props: PlanStatusProps) {
  const planData = props.planData;
  console.log(planData);
  const navigate = useNavigate();
  return (
    <>
      <Box
        padding={4}
        sx={{
          border: '1px solid black',
          alignContent: 'center',
          borderRadius: '20px',
        }}
      >
        {planData?.data?.message !== 'plan_not_found' ? (
          <>
            {planData?.data ? (
              <PlanData
                current_period_end={planData?.data.current_period_end}
                name={planData?.data.name}
                status={planData?.data.status}
                canceled_at={planData?.data.canceled_at}
              />
            ) : (
              <></>
            )}
            <Stack direction="row" spacing={2} marginTop={3}>
              {!planData.data?.cancel_at_period_end &&
              (planData.data?.status == 'active' ||
                planData.data?.status == 'trialing') ? (
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={
                    !planData?.data || planData?.data.cancel_at_period_end
                  }
                  onClick={props.unsubscribePlan}
                >
                  Cancella Abbonamento
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={true}
                    onClick={props.unsubscribePlan}
                  >
                    Cancellato
                  </Button>
                </>
              )}
              <Button
                type="submit"
                variant="contained"
                disabled={!planData?.data}
                onClick={props.changePlan}
              >
                Cambia Piano
              </Button>
            </Stack>
            {planData?.data?.cancel_at_period_end && (
              <Alert severity="warning" sx={{ marginTop: 3 }}>
                <Typography>
                  Il piano è stato cancellato, il{' '}
                  {moment(planData.data?.current_period_end).format(
                    'DD/MM/YYYY'
                  )}{' '}
                  la sottoiscrizione al piano verrà annullata
                </Typography>
              </Alert>
            )}
          </>
        ) : (
          <>
            <Stack spacing={2}>
              <Typography variant={'h5'}>Nessun piano attivo</Typography>
              <Button
                type="submit"
                variant="contained"
                onClick={props.changePlan}
              >
                Scegli Piano
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
}

export default PlanStatus;
