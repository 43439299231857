import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Theme, SxProps } from '@mui/material/styles';
import { UserType } from '@docpack/shared/dtos';

export interface SelectUserTypeInputProps {
  name: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  placeholderItem?: string;
  sx?: SxProps<Theme> | undefined;
  small?: boolean;
}

export function SelectUserTypeInput(props: SelectUserTypeInputProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
    defaultValue: props.defaultValue || '',
  });

  return (
    <>
      {props.label && (
        <InputLabel id={`select-${props.name}`}>{props.label}</InputLabel>
      )}
      <Select
        labelId={`select-${props.name}`}
        data-testid={`select-${props.name}`}
        value={field.value}
        label={props.label}
        displayEmpty
        defaultValue={props.defaultValue}
        onChange={field.onChange}
        sx={props.sx}
        size={props.small ? 'small' : 'medium'}
      >
        {props.placeholderItem && (
          <MenuItem value="">{props.placeholderItem}</MenuItem>
        )}
        <MenuItem value={UserType.Interno}>Interno</MenuItem>
        <MenuItem value={UserType.Esterno}>Esterno</MenuItem>
      </Select>
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
