import { IsString, Matches, MaxLength } from 'class-validator';
import { Match } from './match.decorator';
import { passwordRegex } from './new-root-user.dto';

export class ResetPasswordDto {
  @IsString({ message: 'Fornire un token valido' })
  token: string;

  @MaxLength(40, { message: 'Password deve essere piu corta di 40 caratteri' })
  @Matches(passwordRegex, {
    message:
      'Password deve contenere almeno un numero, un simbolo (@, $, !, %, *, #, ?, &, .) e una lettera maiuscola',
  })
  password: string;

  @Match('password', { message: 'Le password non corrispondono' })
  repeat_password: string;
}
