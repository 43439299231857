import useSwr from 'swr';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { AssetsDownloadTypes, ProjectDto } from '@docpack/shared/dtos';
import { useCallback } from 'react';

export function useGetSharedProjects(shareToken?: string) {
  const headerAuthFetcher = useCallback(
    (url: string) => {
      const headers = new Headers();
      headers.set('authorization', `Bearer ${shareToken}`);
      return fetch(url, {
        headers,
      }).then((res) => res.json());
    },
    [shareToken]
  );
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<Array<ProjectDto>>(
    `/api/project/download/share/projects`,
    headerAuthFetcher
  );

  const aviableTypesResult = useSwr<Array<AssetsDownloadTypes>>(
    `/api/project/download/share/aviable-types`,
    headerAuthFetcher
  );

  const data = handle(result.data);
  const aviableTypes = handle(aviableTypesResult.data);

  return {
    ...result,
    data,
    error,
    aviableTypes,
  };
}
