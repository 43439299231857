import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function DividerIcon() {
  return (
    <SvgIcon viewBox="0 0 124 8" sx={{ width: 120 }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 124 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.833333 4C0.833333 5.47276 2.02724 6.66667 3.5 6.66667C4.97276 6.66667 6.16667 5.47276 6.16667 4C6.16667 2.52724 4.97276 1.33333 3.5 1.33333C2.02724 1.33333 0.833333 2.52724 0.833333 4ZM123.854 4.35355C124.049 4.15829 124.049 3.84171 123.854 3.64645L120.672 0.464466C120.476 0.269204 120.16 0.269204 119.964 0.464466C119.769 0.659728 119.769 0.976311 119.964 1.17157L122.793 4L119.964 6.82843C119.769 7.02369 119.769 7.34027 119.964 7.53553C120.16 7.7308 120.476 7.7308 120.672 7.53553L123.854 4.35355ZM3.5 4.5H123.5V3.5H3.5V4.5Z"
          fill="#BCBFC2"
        />
      </svg>
    </SvgIcon>
  );
}
export default DividerIcon;
