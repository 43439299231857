import { IsString } from 'class-validator';

export class UpdateNoteDto {
  @IsString()
  user_notes_uuid: string;

  @IsString({ message: 'Fornire un titolo' })
  title: string;

  @IsString({ message: 'Fornire un testo' })
  text: string;
}
