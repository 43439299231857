import { IsArray, IsEnum, IsOptional, IsString, IsUUID } from 'class-validator';
import { UserRole, UserType, UserPermission } from './enums';
export class UpdateUserDto {
  @IsString()
  user_uuid: string;

  @IsOptional()
  @IsString()
  first_name?: string;

  @IsOptional()
  @IsString()
  last_name?: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  company_name?: string;

  @IsOptional()
  @IsArray()
  brands?: string[];

  @IsOptional()
  @IsEnum(UserRole)
  user_role?: UserRole;

  @IsOptional()
  @IsEnum(UserType)
  user_type?: UserType;

  @IsOptional()
  @IsArray()
  @IsEnum(UserPermission, { each: true })
  user_permission?: UserPermission[];

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsUUID()
  image_profile_asset_uuid?: string;
}
