import { PropsWithChildren, useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';

import { createDefualtDockpackTheme } from '@docpack/frontend/ui-theme';

export function AppThemeSchell(props: PropsWithChildren<unknown>) {
  const theme = useMemo(
    () => createDefualtDockpackTheme({ darkMode: false }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={DateAdapter}>
        {props.children}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default AppThemeSchell;
