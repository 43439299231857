import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';
import { useCallback, useState, useEffect } from 'react';
import { InfoTooltip } from '../components';
import Stack from '@mui/material/Stack';

export interface InputTextMultipleItemProps {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  tooltipText?: string;
}

export function InputTextMultipleItem(props: InputTextMultipleItemProps) {
  const [text, setText] = useState('');
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  const connectToForm = useCallback(() => {
    const splitted = text
      .split(',')
      .map((str) => str.trim())
      .filter((item) => item !== '');
    if (JSON.stringify(splitted) !== JSON.stringify(field.value)) {
      field.onChange(splitted);
      setText(splitted.join(', '));
    }
  }, [field, text]);

  useEffect(() => {
    setText(field.value?.join(', '));
  }, [field.value]);

  return (
    <Stack>
      {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
      <TextField
        variant="outlined"
        placeholder={props.placeholder}
        label={props.label}
        ref={field.ref}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => connectToForm()}
        value={text}
        required={props.required}
        disabled={props.disabled}
      />
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </Stack>
  );
}

export default InputTextMultipleItem;
