import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { NewSupplierDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useOutletContext } from 'react-router-dom';
import { useNewSupplier } from '../data-access';
import SupplierForm from '../components/supplier-form/supplier-form';
import { SuppliersListPageOutletContext } from './suppliers-list-page';

export const NewSupplierPage = () => {
  const outletContext = useOutletContext<SuppliersListPageOutletContext>();
  const { newSupplier, error: errorNewSupplier } = useNewSupplier();
  const formMethods = useFormMethods(NewSupplierDto, true);
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={450}>
        <AlertController
          message={
            errorNewSupplier
              ? { type: 'error', text: errorNewSupplier }
              : undefined
          }
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Nuovo fornitore
        </Typography>
        <SupplierForm
          handleClose={() => handleClose()}
          onSubmit={(data) =>
            newSupplier(data)
              .then(() => outletContext?.reloadSuppliers())
              .then(() =>
                showMessage('Fornitore creato con successo!', 'success', () => {
                  handleClose(true);
                })
              )
              .catch((error) => console.error(error))
          }
          formMethods={formMethods}
          isNew
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default NewSupplierPage;
