import { IsOptional, IsString, Length } from 'class-validator';

export class UpdateBrandDto {
  @IsString()
  brand_uuid: string;

  @IsOptional()
  @Length(3, 40, {
    message: 'Il nome di un brand deve essere compreso tra 3 e 40 caratteri',
  })
  name?: string;

  @IsString()
  @IsOptional()
  asset_uuid?: string;
}
