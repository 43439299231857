import { StartPaymentFlowDto } from '@docpack/shared/dtos';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import useSwr from 'swr';

export function useStartPaymentProcess() {
  const fetcher = useFetcher();
  const { handle } = useHandleRespError();

  const resultCanPay = useSwr<boolean>(
    `/api/workspace/payment-info/can-pay`,
    fetcher
  );

  async function getUrlPaymentProcess() {
    const result = await fetcher(`/api/workspace/pay`, {
      method: 'GET',
      body: null,
    });

    return handle<StartPaymentFlowDto>(result);
  }

  async function openClientPortal(flow_data?: string) {
    const result = await fetcher(`/api/workspace/portal`, {
      method: 'POST',
      body: { portalFunction: flow_data },
    });

    return handle<StartPaymentFlowDto>(result);
  }

  return {
    resultCanPay: resultCanPay,
    getUrlPaymentProcess,
    openClientPortal,
  };
}
