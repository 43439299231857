import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { Children, PropsWithChildren, ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

export const SelectOrderContainer = (
  props: PropsWithChildren<unknown>
  // eslint-disable-next-line react/jsx-no-useless-fragment
) => <>{props.children}</>;

export const ToolbarLeftContainer = (
  props: PropsWithChildren<unknown>
  // eslint-disable-next-line react/jsx-no-useless-fragment
) => <>{props.children}</>;

export const ToolbarUpperContainer = (
  props: PropsWithChildren<unknown>
  // eslint-disable-next-line react/jsx-no-useless-fragment
) => <>{props.children}</>;

export const ShowDetailsContainer = (
  props: PropsWithChildren<unknown>
  // eslint-disable-next-line react/jsx-no-useless-fragment
) => <>{props.children}</>;

export interface ToolbarProps {
  itemsSelectedCount?: number;
  selectOrderBottom?: boolean;
  hideOrderByOnDesktop?: boolean;
  customTopStickyOnMobile?: number;
}

export function Toolbar(props: PropsWithChildren<ToolbarProps>) {
  const theme = useTheme();
  const mediaQueryMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const selectOrderChilds = Children.toArray(props.children).filter(
    (child) => (child as ReactElement).type === SelectOrderContainer
  );

  const leftChilds = Children.toArray(props.children).filter(
    (child) => (child as ReactElement).type === ToolbarLeftContainer
  );

  const upperChilds = Children.toArray(props.children).filter(
    (child) => (child as ReactElement).type === ToolbarUpperContainer
  );

  const showDetailsChilds = Children.toArray(props.children).filter(
    (child) => (child as ReactElement).type === ShowDetailsContainer
  );

  const childern = Children.toArray(props.children).filter(
    (child) =>
      (child as ReactElement).type !== ToolbarLeftContainer &&
      (child as ReactElement).type !== ToolbarUpperContainer &&
      (child as ReactElement).type !== SelectOrderContainer &&
      (child as ReactElement).type !== ShowDetailsContainer
  );

  return (
    <Stack
      sx={{
        position: 'sticky',
        top: {
          xs: props.customTopStickyOnMobile
            ? `${props.customTopStickyOnMobile}px`
            : '80px',
          md: '80px',
        },
        backgroundColor: '#fff',
        zIndex: 10,
      }}
    >
      {upperChilds && <Box>{upperChilds}</Box>}
      <Grid container alignItems="center" mt={1} mb={2}>
        <Grid item xs={7} md={4} container alignItems="center">
          {leftChilds.length > 0 && leftChilds}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          container
          justifyContent={{ xs: 'space-between', md: 'flex-end' }}
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            position: { xs: 'fixed', md: 'static' },
            bottom: { xs: 0, md: 'unset' },
            left: { xs: 0, md: 'unset' },
            right: { xs: 0, md: 'unset' },
            backgroundColor: '#fff',
            zIndex: '10',
            padding: {
              xs: '18px 24px',
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: { xs: '1rem', sm: 0 },
            }}
          >
            {showDetailsChilds.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '2rem',
                }}
              >
                {showDetailsChilds}
              </Box>
            )}
            {selectOrderChilds.length > 0 &&
              !props.selectOrderBottom &&
              !mediaQueryMdDown && (
                <>
                  {' '}
                  <Typography
                    variant="body3"
                    sx={{
                      paddingRight: { xs: '8px', md: '20px' },
                    }}
                  >
                    {`Visualizza per `}
                  </Typography>
                  <FormControl
                    variant="standard"
                    data-testid="page-order"
                    sx={{
                      margin: { xs: '0 8px 0 0', md: '8px 32px 8px 8px' },
                      minWidth: 120,
                    }}
                  >
                    {selectOrderChilds}
                  </FormControl>
                </>
              )}
            {props.itemsSelectedCount && props.itemsSelectedCount > 0 ? (
              <Stack sx={{ marginRight: { xs: 'auto', md: '24px' } }}>
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: '#fff',
                    backgroundColor: '#51b943',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100%',
                    width: '28px',
                    height: '28px',
                  }}
                >
                  {props.itemsSelectedCount}
                </span>
              </Stack>
            ) : null}
          </Box>
          {childern}
        </Grid>
        {(props.selectOrderBottom || mediaQueryMdDown) && (
          <Grid
            item
            xs={5}
            md={12}
            container
            alignItems={{ xs: 'flex-end', md: 'center' }}
            justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            sx={{
              display: {
                xs: 'flex',
                md: props.hideOrderByOnDesktop ? 'none' : 'flex',
              },
            }}
          >
            <Typography
              variant="body3"
              sx={{
                paddingRight: { xs: '8px', md: '20px' },
              }}
            >
              {`Visualizza per `}
            </Typography>
            <FormControl
              variant="standard"
              data-testid="page-order"
              sx={{
                margin: { xs: '0 8px 0 0', md: '8px' },
                minWidth: 120,
              }}
            >
              {selectOrderChilds}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default Toolbar;
