import { useState } from 'react';

export const useShowElementOnScroll = (scroll: number) => {
  const [showElement, setShowElement] = useState(false);

  window.onscroll = () => {
    setShowElement(
      (document.documentElement.scrollTop || document.body.scrollTop) > scroll
    );
  };

  return showElement;
};

export default useShowElementOnScroll;
