import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';
import { ActivityDto, LogTypes } from '@docpack/shared/dtos';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  PlaceholderText,
  useGetAssetEntity,
} from '@docpack/frontend/core-components';
import Grid from '@mui/material/Grid';

export interface ActivityTableProps {
  data: Array<ActivityDto>;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  count?: number;
  offset?: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ecedee',
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: '8px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '12px',
    height: '44px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 8px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: '1px solid #BCBFC2',
  '&:nth-of-type(even)': {
    backgroundColor: '#fafdff',
  },
}));

const FileLink = (props: {
  file: string;
  projects_uuid: string;
  project_job: string | undefined;
}) => {
  const theme = useTheme();
  const { data, error } = useGetAssetEntity(props.file);

  return data && !error ? (
    <Link
      color={theme.palette.secondary.main}
      key={data?.asset_uuid}
      to={`/app/projects/edit/${props.projects_uuid}`}
      style={{ margin: ' 0', color: 'secondary.main' }}
    >
      {props.project_job + '_' + data?.user_file_name}
    </Link>
  ) : (
    <p>Impossibile recuperare informazioni aggiuntive</p>
  );
};

function Row(props: { row: ActivityDto }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const mediaQueryLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <StyledTableRow>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{ width: { xs: '20%', md: '13%' } }}
      >
        {new Date(row.created_at).toLocaleDateString()}{' '}
        {new Date(row.created_at).toLocaleTimeString()}
      </StyledTableCell>
      <StyledTableCell sx={{ width: { xs: '27%', md: '29%' } }}>
        {/* TO-DO: aggiungere avatar utilizzando il dettaglio dell'utente */}
        <Grid container alignItems="center" sx={{ wordBreak: 'break-all' }}>
          <Avatar
            alt="Utente"
            src=""
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              display: { xs: 'none', sm: 'flex' },
            }}
          />
          {row.event_type === LogTypes.DownloadFromEmail
            ? props.row?.shared_to_email
            : props.row.user?.username}
        </Grid>
      </StyledTableCell>
      <StyledTableCell sx={{ width: { xs: '27%', md: '29%' } }}>
        {row.event_type}
        {row.event_type === LogTypes.Download ||
        row.event_type === LogTypes.DownloadFromEmail ||
        row.event_type === LogTypes.SendEmail ||
        row.event_type === LogTypes.DeleteUser ||
        row.event_type === LogTypes.CreateUser ? (
          <>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ padding: 0, marginLeft: { xs: '1px', md: '16px' } }}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </IconButton>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                '& > div > div': { display: 'flex', flexDirection: 'column' },
              }}
            >
              <Stack>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    lg={row.event_type === LogTypes.SendEmail ? 6 : 12}
                  >
                    {row.event_type === LogTypes.CreateUser ||
                    row.event_type === LogTypes.DeleteUser ? (
                      <p style={{ margin: '4px 0' }}>{row.event_username}</p>
                    ) : null}
                    {row.event_type === LogTypes.SendEmail ? (
                      <>
                        <p style={{ margin: '4px 0' }}>
                          <strong>To:</strong> {row.event_email}
                        </p>
                        {row.event_email_cc && (
                          <p style={{ margin: '4px 0' }}>
                            <strong>Cc:</strong> {row.event_email_cc}
                          </p>
                        )}
                        {row.event_email_ccn && (
                          <p style={{ margin: '4px 0' }}>
                            <strong>Ccn:</strong> {row.event_email_ccn}
                          </p>
                        )}
                      </>
                    ) : null}
                  </Grid>
                  {(row.event_type === LogTypes.SendEmail ||
                    row.event_type === LogTypes.DownloadFromEmail ||
                    row.event_type === LogTypes.Download) && (
                    <Grid item xs={12} lg={6} pl={mediaQueryLgUp ? 2 : 0}>
                      <p style={{ margin: '4px 0 2px' }}>
                        {row.project?.project_code}
                      </p>
                      <Stack>
                        {row.event_files_uuid &&
                          row.event_files_uuid?.map((file) => {
                            return (
                              <FileLink
                                file={file}
                                projects_uuid={row.project_uuid || ''}
                                project_job={row.project?.project_code}
                              />
                            );
                          })}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Collapse>
          </>
        ) : null}
      </StyledTableCell>
      <StyledTableCell sx={{ width: { xs: '27%', md: '29%' } }}>
        {row.ip_address}
      </StyledTableCell>
    </StyledTableRow>
  );
}

export const ActivityTable = (props: ActivityTableProps) => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    props.count && props.offset
      ? setCount(Math.floor(props.count / props.offset) + 1)
      : setCount(1);
  }, [props.data, props.count, props.offset]);

  return props.data.length > 0 ? (
    <>
      <TableContainer
        component={Paper}
        sx={{ mt: 4, boxShadow: 'none' }}
        style={{ minHeight: 450 }}
      >
        <Table aria-label="activity table">
          <TableHead>
            <TableRow sx={{ border: '1px solid #BCBFC2' }}>
              <StyledTableCell sx={{ width: { xs: '14%', md: '10%' } }}>
                Data e ora
              </StyledTableCell>
              <StyledTableCell sx={{ width: { xs: '28%', md: '30%' } }}>
                Utente
              </StyledTableCell>
              <StyledTableCell sx={{ width: { xs: '28%', md: '30%' } }}>
                Evento
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                Indirizzo IP
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <Row key={row.activity_uuid} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{
          marginTop: '10px',
        }}
      >
        <Pagination
          count={count}
          page={props.data.length > 0 ? (props.page ? props.page : 1) : 1}
          onChange={props.handleChange}
          style={{ margin: '10px 0 30px' }}
        />
      </Stack>
    </>
  ) : (
    <PlaceholderText>Non sono presenti log da mostrare</PlaceholderText>
  );
};
export default ActivityTable;
