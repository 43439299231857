import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UserDto } from '@docpack/shared/dtos';

export function useGetUser(user_uuid?: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<UserDto>(`/api/user/${user_uuid}`, fetcher);

  const data = handle(result.data);

  return {
    ...result,
    data,
    error,
  };
}
