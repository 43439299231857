import { IsDateString, IsEnum, IsOptional, IsString } from 'class-validator';

export enum orderTypes {
  name_asc = 'name_asc',
  name_desc = 'name_desc',
  job_asc = 'job_asc',
  job_desc = 'job_desc',
  date_asc = 'date_asc',
  date_desc = 'date_desc',
}

export enum formatTypes {
  Pdf = 'Pdf',
  Csv = 'Csv',
}

export class ReportProjectDto {
  @IsEnum(orderTypes, { each: true })
  orderBy: orderTypes;

  @IsString()
  @IsOptional()
  photo?: string;

  @IsEnum(formatTypes, { each: true })
  format: formatTypes;

  @IsString()
  @IsOptional()
  search?: string;

  @IsString()
  @IsOptional()
  search_brand?: string;

  @IsString()
  @IsOptional()
  search_category?: string;

  @IsString()
  @IsOptional()
  search_sub_category?: string;

  @IsString()
  @IsOptional()
  search_supplier?: string;

  @IsDateString()
  @IsOptional()
  search_date_from?: Date;

  @IsDateString()
  @IsOptional()
  search_date_to?: Date;

  @IsString()
  @IsOptional()
  selected?: string;
}
