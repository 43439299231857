import { AlertController } from '@docpack/frontend/core-components';
import { ContactDto } from '@docpack/shared/dtos';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useGetContacts } from '../data-access';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export interface EmailContactsAutocompleteFormInputProps {
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  cy?: string;
  open?: boolean
}

export function EmailContactsAutocompleteFormInput(
  props: EmailContactsAutocompleteFormInputProps
) {
  const [inputValue, setInputValue] = useState('');

  const [selectedOption, setSelectedOption] = useState<Array<ContactDto>>([]);

  const { field } = useController({
    name: props.name,
  });
  
   const { data: options, error } = props.open === false
    ? { data: [],  error: undefined} 
    : useGetContacts({ query: inputValue });

  useEffect(() => {
    if (field.value.length !== selectedOption.length) {
      field.onChange(selectedOption.map((item) => item.email));
    }
  }, [field, selectedOption]);

  function handleInputChange(inputValue: string, isEnter?: boolean) {
    if (
      typeof inputValue === 'string' &&
      (inputValue?.includes(',') || isEnter)
    ) {
      const email = inputValue.split(',')[0].trim();
      setSelectedOption((old) => [
        ...old,
        {
          email,
          first_name: '',
          last_name: '',
          contact_uuid: Math.random().toString(),
          updated_at: new Date(),
          created_at: new Date(),
        },
      ]);
      setInputValue('');
    } else {
      setInputValue(inputValue);
    }
  }

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <Autocomplete
        open={props.open}
        multiple
        placeholder={props.placeholder}
        value={selectedOption}
        onChange={(event, newValue, reason) => {
          setSelectedOption(newValue);
        }}
        options={options || []}
        getOptionLabel={(option) =>
          `${option.email} ${
            option.first_name || option.last_name
              ? `- ${option.first_name} ${option.last_name}`
              : ''
          }`
        }
        isOptionEqualToValue={(option, value) =>
          option.contact_uuid === value.contact_uuid
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.contact_uuid}>
            {option.email} - {option.first_name} {option.last_name}
          </li>
        )}
        inputValue={inputValue || ''}
        onInputChange={(event) => {
          if (event && event.type !== 'blur') {
            handleInputChange((event.target as HTMLInputElement).value);
          } else {
            setInputValue('');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            type="email"
            label={props.label}
            onBlur={(event) => {
              event.preventDefault();
              if ((event.target as HTMLInputElement).value) {
                handleInputChange(
                  (event.target as HTMLInputElement).value,
                  true
                );
              }
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleInputChange(
                  (event.target as HTMLInputElement).value,
                  true
                );
              }
            }}
          />
        )}
        data-testid={props.cy}
      />
    </>
  );
}
