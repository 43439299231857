import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetUserInfo } from '@docpack/frontend/core-components';
import { PrivacyAndTermsModal } from 'apps/docpack-web-app/src/app/privacy-and-terms-modal';
import { Link, Typography } from '@mui/material';
export default function WizardShell() {
  const { data, mutate } = useGetUserInfo();
  return (
    <>
      <div>
        {data && !data.privacy_policy && (
          <PrivacyAndTermsModal
            type="privacy_policy"
            title="Informativa sulla privacy"
            onAcceptTerms={() => mutate()}
          >
            <Typography variant="body1">
              Scopri come utilizziamo e proteggiamo i tuoi dati nella nostra{' '}
              <Link
                color="#000"
                target="_blank"
                rel="noreferrer"
                underline="always"
                href="https://docpack.it/privacy-policy/"
              >
                Informativa Privacy
              </Link>
            </Typography>
          </PrivacyAndTermsModal>
        )}
        {data && data.privacy_policy && !data?.terms_conditions && (
          <PrivacyAndTermsModal
            type="terms_conditions"
            title="Termini e condizioni d'uso"
            onAcceptTerms={mutate}
          >
            <Typography variant="body1">
              Approfondisci i nostri{' '}
              <Link
                color="#000"
                target="_blank"
                rel="noreferrer"
                underline="always"
                href="https://docpack.it/condizioni-duso/"
              >
                Termini e Condizioni
              </Link>
            </Typography>
          </PrivacyAndTermsModal>
        )}
        <Outlet />
      </div>
    </>
  );
}
