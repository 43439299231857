import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { UpdateCategoryDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { EditCategoryForm } from '../components';
import { useUpdateCategory } from '../data-access';
import { CategoriesListPageOutletContext } from './categories-list-page';
import { useGetSingleCategory } from '../data-access/get-single-category.hook';

export const EditCategoryPage = () => {
  const outletContext = useOutletContext<CategoriesListPageOutletContext>();
  const params = useParams();
  const category_uuid = params['category_uuid'] || '';
  const { data: category, mutate: categoryMutate } =
    useGetSingleCategory(category_uuid);
  const { updateCategory, error: errorUpdateCategory } = useUpdateCategory();
  const formMethodsCat = useFormMethods(UpdateCategoryDto, true, {
    category_uuid: category_uuid,
    name: category?.name,
  });
  const handleClose = useHandleClose(formMethodsCat);
  const showMessage = useMessagePopup();

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={450}>
        <AlertController
          message={
            errorUpdateCategory
              ? { type: 'error', text: errorUpdateCategory }
              : undefined
          }
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Modifica categoria
        </Typography>
        <EditCategoryForm
          handleClose={() => handleClose()}
          onSubmit={(data) =>
            updateCategory(data)
              .then(() => outletContext?.reloadCategories())
              .then(() => {
                showMessage(
                  'Categoria modificata con successo!',
                  'success',
                  () => {
                    handleClose(true);
                  }
                );
                categoryMutate();
              })
              .catch((error) => console.error(error))
          }
          formMethods={formMethodsCat}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default EditCategoryPage;
