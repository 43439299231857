import {
  IsString,
  IsOptional,
  IsDateString,
  IsEnum,
  IsNumberString,
} from 'class-validator';
import { UserType } from '../user-dtors';

export class SearchActivityDto {
  @IsString()
  @IsOptional()
  search?: string;

  @IsEnum(UserType)
  @IsOptional()
  search_user_type?: UserType;

  @IsDateString()
  @IsOptional()
  search_date_from?: Date;

  @IsDateString()
  @IsOptional()
  search_date_to?: Date;

  @IsNumberString()
  @IsOptional()
  page?: string;

  @IsNumberString()
  @IsOptional()
  offset?: string;
}
