import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export interface UserRowDetailsProps {
  details: Array<string | boolean>;
  type: 'header' | 'content';
  customColumnsSizes?: Array<string>;
}

export const UserRowDetails = (props: UserRowDetailsProps) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="nowrap"
    >
      {props.type === 'header'
        ? props.details.map((item, key) => (
            <Typography
              key={key}
              variant="caption"
              mx={2}
              sx={{
                maxWidth: '100%',
                textAlign: 'center',
                width: props.customColumnsSizes
                  ? props.customColumnsSizes[key]
                  : 'auto',
                flex: `0 0 ${
                  props.customColumnsSizes
                    ? props.customColumnsSizes[key]
                    : 'auto'
                }`,
              }}
            >
              {item}
            </Typography>
          ))
        : null}
      {props.type === 'content'
        ? props.details.map((item, key) => {
            return typeof item === 'string' ? (
              <Typography
                key={key}
                variant="overline"
                mx={2}
                sx={{
                  maxWidth: '100%',
                  textAlign: 'center',
                  width: props.customColumnsSizes
                    ? props.customColumnsSizes[key]
                    : 'auto',
                  flex: `0 0 ${
                    props.customColumnsSizes
                      ? props.customColumnsSizes[key]
                      : 'auto'
                  }`,
                }}
              >
                {item}
              </Typography>
            ) : !item ? (
              <RadioButtonUncheckedIcon
                key={key}
                fontSize="small"
                sx={{
                  maxWidth: '100%',
                  textAlign: 'center',
                  margin: '0 16px',
                  width: props.customColumnsSizes
                    ? props.customColumnsSizes[key]
                    : 'auto',
                  flex: `0 0 ${
                    props.customColumnsSizes
                      ? props.customColumnsSizes[key]
                      : 'auto'
                  }`,
                }}
              />
            ) : (
              <CheckCircleIcon
                key={key}
                fontSize="small"
                sx={{
                  maxWidth: '100%',
                  textAlign: 'center',
                  margin: '0 16px',
                  width: props.customColumnsSizes
                    ? props.customColumnsSizes[key]
                    : 'auto',
                  flex: `0 0 ${
                    props.customColumnsSizes
                      ? props.customColumnsSizes[key]
                      : 'auto'
                  }`,
                }}
                color="success"
              />
            );
          })
        : null}
    </Grid>
  );
};

export default UserRowDetails;
