import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { WhiteBox } from '../components';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useValidateEmail } from '../data-access/validate-email.hook';
import { ValidateEmailDto } from '@docpack/shared/dtos';
import Alert from '@mui/material/Alert';

export const CheckCodePage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<ValidateEmailDto>();
  const { error, validateEmail } = useValidateEmail();
  const params = useParams();
  const user_uuid = params['user_uuid'] || '';

  return (
    <WhiteBox>
      <Typography variant="h5" mb={6} sx={{ textAlign: 'center' }}>
        Conferma profilo
      </Typography>
      <form
        onSubmit={handleSubmit((values) =>
          validateEmail({ user_uuid: user_uuid, code: values.code })
            .then((res) => {
              if (res) navigate('/reset-success');
            })
            .catch((error) => {
              console.error('Error fetching data: ', error);
            })
        )}
      >
        <Stack spacing={2}>
          <Grid container justifyContent="center">
            <Grid container justifyContent="center" item xs={8}>
              <Typography variant="button" sx={{ textAlign: 'center', mb: 6 }}>
                Inserisci il codice <br />
                che ti abbiamo
                <br />
                inviato via mail
              </Typography>
              <TextField
                variant="standard"
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '2rem',
                  },
                }}
                {...register('code')}
                data-testid="check-code-input"
              />
              {error ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                  <Typography>{error}</Typography>
                </Alert>
              ) : null}
            </Grid>
          </Grid>
          <Grid container sx={{ mt: '60px !important' }}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" onClick={() => navigate('/register')}>
                <ArrowBackIcon sx={{ mr: '10px' }} /> Indietro
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                data-testid="check-code-submit"
              >
                Conferma
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </WhiteBox>
  );
};

export default CheckCodePage;
