import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  useAccessControl,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useGetSingleProject } from '../data-access';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { UserPermission, UserRole } from '@docpack/shared/dtos';

export function DetailProjectPage() {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useGetSingleProject(params['project_uuid'] || '');
  const enablePhotoDownload = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
    permissions: [UserPermission.Scaricare],
  });
  const enableLayoutDownload = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
    permissions: [UserPermission.Scaricare],
  });

  const handleClose = () => {
    navigate('..');
  };

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={600}>
        {data && (
          <Stack alignItems="center">
            <Typography variant="button" id="parent-modal-title" mb={2}>
              {data?.name}
            </Typography>
            <Typography variant="body1" mb={4}>
              {data?.project_code}
            </Typography>
            {data?.picture && (
              <img
                src={data?.picture?.link}
                alt={data?.name}
                style={{ width: '100%', objectFit: 'contain', height: '50vh' }}
              />
            )}
            {data?.picture || data?.layout ? (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                mt={5}
              >
                {data?.picture && (
                  <Button
                    variant="contained"
                    component={Link}
                    to={data?.picture?.link}
                    target="_blank"
                    sx={{ mr: data?.layout ? 4 : 0 }}
                    disabled={!enablePhotoDownload}
                  >
                    {data?.picture?.mime_type === 'image/png' ? 'PNG' : 'JPEG'}
                  </Button>
                )}
                {data?.layout && (
                  <Button
                    variant="contained"
                    component={Link}
                    to={data?.layout?.link}
                    target="_blank"
                    disabled={!enableLayoutDownload}
                  >
                    PDF
                  </Button>
                )}
              </Grid>
            ) : null}
          </Stack>
        )}
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default DetailProjectPage;
