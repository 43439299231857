import React from 'react';
import { Modal, Box } from '@mui/material';
import PlanSelectionPage from '../pages/plan-selection-page';
import { alpha, styled } from '@mui/material/styles';

const WhiteModal = styled(Modal)<any>(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'white',
  },
}));
export function PlansSelectionModal() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <WhiteModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PlanSelectionPage />
      </WhiteModal>
    </>
  );
}

export default PlansSelectionModal;
