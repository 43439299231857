import { useCallback, useMemo, useState } from 'react';

export interface HandleDragOptionsInterface {
  onLeave: (files: FileList) => unknown;
}

function handleDrag(ev: React.DragEvent) {
  ev.preventDefault();
  ev.stopPropagation();
}

export const useHandleDrag = (oprions: HandleDragOptionsInterface) => {
  const [dragging, setDragging] = useState(false);
  const [draggedFileType, setDraggedFileType] = useState<string | null>(null);

  const handleDragIn = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer?.items?.length > 0) {
      setDraggedFileType(ev.dataTransfer.items[0]?.type);
      setDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setDraggedFileType(null);
    setDragging(false);
  }, []);

  const handleDrop = useCallback(
    (ev: React.DragEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      setDragging(false);
      setDraggedFileType(null);
      if (ev.dataTransfer?.files?.length > 0) {
        oprions.onLeave(ev.dataTransfer.files);
        try {
          ev.dataTransfer?.clearData();
        } catch (error) {
          console.log('Error during drop, maybe firefox related', error);
        }
      }
    },
    [oprions]
  );

  const handlerProps = useMemo(
    () => ({
      onDragLeave: handleDragOut,
      onDragOver: handleDrag,
      onDrop: handleDrop,
      onDragEnter: handleDragIn,
    }),
    [handleDragIn, handleDragOut, handleDrop]
  );

  return {
    dragging,
    draggedFileType,
    handlerProps,
  };
};
