import {
  AssetsDownloadTypes,
  ShareMultipleProjectsDto,
  UserRole,
} from '@docpack/shared/dtos';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormProvider, useController, UseFormReturn } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { InputText, useAccessControl } from '@docpack/frontend/core-components';
import Typography from '@mui/material/Typography';
import {
  ExecutiveIcon,
  ImageIcon,
  LayoutIcon,
} from '../project-assets-download/project-assets-download';
import AssetTypeButton from '../asset-type-button/asset-type-button';
import Alert from '@mui/material/Alert';
import { EmailContactsAutocompleteFormInput } from '@docpack/frontend/contacts-feature';
import { useGetNotes } from '@docpack/frontend/settings';

export interface SendEmailProjectFormProps {
  onClose: () => void;
  onSubmit: (values: ShareMultipleProjectsDto) => void;
  disabledAssets?: Array<AssetsDownloadTypes>;
  formMethods: UseFormReturn<ShareMultipleProjectsDto, object>;
}

export const AssetsTypesSelection = (props: {
  disabledAssets?: Array<AssetsDownloadTypes>;
}) => {
  const { field } = useController({ name: 'types' });
  const theme = useTheme();
  const mediaQueryMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const isSelected = (assetType: AssetsDownloadTypes) => {
    return !!field.value.find(
      (asset: AssetsDownloadTypes) => asset === assetType
    );
  };

  const isDisabled = (assetType: AssetsDownloadTypes) => {
    return !!props.disabledAssets?.find(
      (asset: AssetsDownloadTypes) => asset === assetType
    );
  };

  const onSelect = (value: AssetsDownloadTypes) => {
    if (field.value.find((item: AssetsDownloadTypes) => item === value)) {
      field.onChange(
        field.value.filter((item: AssetsDownloadTypes) => item !== value)
      );
    } else {
      field.onChange([...field.value, value]);
    }
  };

  return (
    <Stack
      spacing={2}
      direction={mediaQueryMdDown ? 'row' : 'column'}
      sx={{ width: { xs: '100%', md: 'auto' } }}
    >
      <AssetTypeButton
        name="JPG"
        isSelected={isSelected(AssetsDownloadTypes.Picture)}
        isDisabled={isDisabled(AssetsDownloadTypes.Picture)}
        onClick={() => onSelect(AssetsDownloadTypes.Picture)}
        small
        cy="send-email-project-image-selection"
      >
        <ImageIcon small />
      </AssetTypeButton>
      <AssetTypeButton
        name="PDF"
        isSelected={isSelected(AssetsDownloadTypes.Layout)}
        isDisabled={isDisabled(AssetsDownloadTypes.Layout)}
        onClick={() => onSelect(AssetsDownloadTypes.Layout)}
        small
      >
        <LayoutIcon small />
      </AssetTypeButton>
      <AssetTypeButton
        name="ZIP"
        isSelected={isSelected(AssetsDownloadTypes.Executive)}
        isDisabled={isDisabled(AssetsDownloadTypes.Executive)}
        onClick={() => onSelect(AssetsDownloadTypes.Executive)}
        small
      >
        <ExecutiveIcon small />
      </AssetTypeButton>
    </Stack>
  );
};

export const SendEmailProjectForm = (props: SendEmailProjectFormProps) => {
  const isAdmin = useAccessControl({
    roles: [UserRole.Amministratore],
  });
  const message = props.formMethods.watch('message')
    ? props.formMethods.watch('message')
    : '';
  const theme = useTheme();
  const mediaQueryMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = useGetNotes();

  return (
    <FormProvider {...props.formMethods}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Stack spacing={2}>
              <EmailContactsAutocompleteFormInput
                open={isAdmin ? undefined : false}
                name="recipient"
                label="Email"
                required
                cy="send-email-project-email-input"
              />
              <EmailContactsAutocompleteFormInput
                open={isAdmin ? undefined : false}
                name="cc"
                label="Cc"
                placeholder="Email separate da una virgola"
              />
              <EmailContactsAutocompleteFormInput
                open={isAdmin ? undefined : false}
                name="ccn"
                label="Ccn"
                placeholder="Email separate da una virgola"
              />
              <InputText
                name="subject"
                label="Oggetto"
                required
                cy="send-email-project-subject-input"
              />
              <InputText name="message" label="Testo mail" textarea />
              <Typography variant="overline">
                {!!data?.length &&
                  data?.map((item) => {
                    return (
                      <Button
                        key={item.user_notes_uuid}
                        size="small"
                        sx={{ fontWeight: '400' }}
                        onClick={() =>
                          props.formMethods.setValue(
                            'message',
                            message + ' ' + item.text
                          )
                        }
                      >
                        [+] {item.title}
                      </Button>
                    );
                  })}
              </Typography>

              <Typography variant="faint" color="error.main">
                * Campo obbligatorio
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} mt={mediaQueryMdDown ? 3 : 0}>
            <Stack
              alignItems={mediaQueryMdDown ? 'flex-start' : 'flex-end'}
              ml={mediaQueryMdDown ? 0 : 3}
            >
              <Typography variant="caption" mb={4}>
                Seleziona tipologia di file *
              </Typography>
              <AssetsTypesSelection disabledAssets={props.disabledAssets} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '48px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.onClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="send-email-project-submit-button"
              disabled={props.formMethods.formState?.isSubmitting}
            >
              Invia
            </Button>
          </Grid>
        </Grid>
        {Object.keys(props.formMethods.formState.errors).length > 0 && (
          <Alert severity="error" sx={{ mt: 3 }}>
            <Typography>
              {props.formMethods.formState.errors.projects?.message ||
                props.formMethods.formState.errors.recipient?.message ||
                props.formMethods.formState.errors.cc?.message ||
                props.formMethods.formState.errors.ccn?.message ||
                props.formMethods.formState.errors.subject?.message ||
                props.formMethods.formState.errors.types?.message}
            </Typography>
          </Alert>
        )}
      </form>
    </FormProvider>
  );
};
