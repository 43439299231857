import UserForm from '../components/user-form';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useGetUser, useNewUser } from '../data-access';
import {
  AlertController,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
  useFormMethods,
} from '@docpack/frontend/core-components';
import { UpdateUserDto } from '@docpack/shared/dtos';
import { UserListContextInterface } from './users-list-page';

export const EditUserPage = () => {
  const routerContext = useOutletContext<UserListContextInterface>();
  const params = useParams();
  const { data, error, mutate } = useGetUser(params?.['user_uuid']);
  const formMethods = useFormMethods(UpdateUserDto, !!data, {
    user_uuid: data?.user_uuid,
    ...data?.details,
    email: data?.email,
    brands: data?.brands?.map((brand) => brand.brand_uuid),
    image_profile_asset_uuid: data?.details?.image_profile?.asset_uuid,
  });
  const handleClose = useHandleClose(formMethods);

  const { updateWorkspaceUser, error: updateUserError } = useNewUser();
  const showMessage = useMessagePopup();
  const navigate = useNavigate();

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <AlertController
        message={
          updateUserError ? { type: 'error', text: updateUserError } : undefined
        }
      />
      <Modal open={true} onClose={() => handleClose()}>
        <WhiteBoxModalContainer>
          <Typography
            variant="h3"
            id="parent-modal-title"
            mb={6}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            Aggiorna utente
          </Typography>
          {data ? (
            <UserForm
              handleClose={() => handleClose()}
              onSubmit={(values) =>
                updateWorkspaceUser(values as UpdateUserDto)
                  .then(() => {
                    mutate();
                    navigate('..');
                  })
                  .then(() =>
                    showMessage(
                      'Utente aggiornato con successo!',
                      'success',
                      () => handleClose(true)
                    )
                  )
                  .then(() => routerContext?.reloadUsers())
                  .catch((error) => console.error(error))
              }
              isEdit
              formMethods={formMethods}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </WhiteBoxModalContainer>
      </Modal>
    </>
  );
};

export default EditUserPage;
