import { useForm, FormProvider } from 'react-hook-form';
import { WhiteBox } from '../components';
import { useResetPassword } from '../data-access/reset-password.hook';
import { Link, useNavigate } from 'react-router-dom';
import { AlertController, InputText } from '@docpack/frontend/core-components';
import { InitResetPasswordDto } from '@docpack/shared/dtos';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const resolver = classValidatorResolver(InitResetPasswordDto);
  const form = useForm<InitResetPasswordDto>({
    resolver,
  });

  const { initResetPassword, error } = useResetPassword();

  function onSubmit(data: InitResetPasswordDto) {
    return initResetPassword(data)
      .then(() => navigate('../reset-success'))
      .catch((error) => console.error(error));
  }

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <WhiteBox>
        <Typography variant="h5" mb={2} sx={{ textAlign: 'center' }}>
          Recupero password
        </Typography>
        <Typography variant="body1" mb={6} sx={{ textAlign: 'center' }}>
          Inserisci la tua mail per ricevere il link per resettare la password
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <InputText name="email" label="Email" />

              <Grid container sx={{ mt: '48px !important' }}>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button variant="text" component={Link} to="../login">
                    <ArrowBackIcon sx={{ mr: '10px' }} /> Indietro
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button type="submit" variant="contained">
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </WhiteBox>
    </>
  );
};

export default ForgetPasswordPage;
