import { ReportProjectDto } from '@docpack/shared/dtos';

export function useGetReport() {
  async function getReport(data: ReportProjectDto) {
    const queryParams = new URLSearchParams();
    let selParams = '';

    queryParams.set('orderBy', data.orderBy);
    queryParams.set('format', data.format);

    if (data.photo) {
      queryParams.set('photo', data.photo);
    }

    if (!data.selected) {
      if (data.search) {
        queryParams.set('search', data.search);
      }
      if (data.search_brand) {
        queryParams.set('search_brand', data.search_brand);
      }
      if (data.search_category) {
        queryParams.set('search_category', data.search_category);
      }
      if (data.search_sub_category) {
        queryParams.set('search_sub_category', data.search_sub_category);
      }
      if (data.search_supplier) {
        queryParams.set('search_supplier', data.search_supplier);
      }
      if (data.search_date_from) {
        queryParams.set('search_date_from', data.search_date_from.toString());
      }
      if (data.search_date_to) {
        queryParams.set('search_date_to', data.search_date_to.toString());
      }
    } else {
      selParams = data.selected;
    }
    if (data.orderBy) {
      queryParams.set('orderBy', data.orderBy);
    }

    openWindowWithPost(`/api/project/report?${queryParams}`, {
      selParams: selParams,
    });
  }

  async function openWindowWithPost(url: string, data: { selParams: string }) {
    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    if (data.selParams) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'selParams';
      input.value = String(data.selParams);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  return {
    getReport,
  };
}
