import { Link } from 'react-router-dom';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export interface AddButtonProps {
  linkTo: string;
  label?: string;
  onClick?: Function;
  disabled?: boolean | undefined;
}

export const AddButton = (props: AddButtonProps) => {
  return (
    <Button
      component={Link}
      disabled={props.disabled}
      to={props?.linkTo}
      sx={{
        padding: { xs: '0', md: '6px 8px' },
        opacity: props.disabled ? 0.5 : 1,
      }}
      data-testid="add-button"
      onClick={() => props.onClick?.()}
      //disabled={props?.disabled}
    >
      <AddCircleRoundedIcon
        color="secondary"
        sx={{ fontSize: { xs: '52px', md: '72px' } }}
      />
      {props.label && (
        <Typography variant="button" color="secondary" ml={2}>
          {props.label}
        </Typography>
      )}
    </Button>
  );
};

export default AddButton;
