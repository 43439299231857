import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Download from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

interface DownloadAllProjectsBoxProps {
  selectAllProject: () => void;
}

export function DownloadAllProjectsBox(props: DownloadAllProjectsBoxProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        borderRadius: '24px',
        padding: '57px',
        height: '300px',
      }}
    >
      <Stack spacing="48px">
        <Button
          component={Link}
          to="download"
          onClick={() => props.selectAllProject()}
        >
          <Download sx={{ fontSize: '72px' }} color="white" />
        </Button>
        <Typography variant="button" color="white.main" textAlign="center">
          Scarica tutti
        </Typography>
      </Stack>
    </Box>
  );
}
