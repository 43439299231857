import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import { InfoTooltip } from '../components';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export interface SelectAutocompleteProps {
  name: string;
  label: string;
  required?: boolean;
  options: Array<{ label: string; value: string }>;
  tooltipText?: string;
  disabled?: boolean;
}

export function SelectAutocomplete(props: SelectAutocompleteProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Autocomplete
          disablePortal
          id={`select-autocomplete-${props.name}`}
          options={props.options}
          onChange={(e, item) => field.onChange(item?.value)}
          renderInput={(params) => (
            <TextField {...params} label={props.label} />
          )}
          sx={{ width: '100%' }}
          disabled={props.disabled}
          value={field.value || ''}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(selected) =>
            props.options.find((opt) => opt.value === selected)?.label || ''
          }
          filterOptions={(options, { inputValue }) =>
            options.filter((opt) =>
              opt.label.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
        />
        {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
      </Stack>
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
