import { Route, Routes } from 'react-router-dom';
import {
  CategoriesListPage,
  EditCategoryPage,
  NewCategoryPage,
  EditSubCategoryPage,
} from './pages';

export function CategoriesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CategoriesListPage />}>
        <Route path="new" element={<NewCategoryPage />} />
        <Route
          path="edit/category/:category_uuid"
          element={<EditCategoryPage />}
        />
        <Route
          path="edit/subcategory/:sub_category_uuid"
          element={<EditSubCategoryPage />}
        />
      </Route>
    </Routes>
  );
}
