import {
  Button,
  Stack,
  Alert,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { NewRootUserDto, NewUserDto } from '@docpack/shared/dtos';
import { InputText } from '@docpack/frontend/core-components';
import { useState } from 'react';

export interface RegisterForm {
  onSubmit: (data: any) => Promise<void>;
  defautlValues?: any;
  functionButtons?: boolean;
  onBack?: () => void;
  forwardLink?: string;
  error?: string;
  formMethods: UseFormReturn<NewRootUserDto, object>;
}

export function RegisterForm(props: RegisterForm) {
  const form = props.formMethods;
  const errors = form.formState.errors;
  const [showDiscountCode, setShowDiscountCode] = useState(false);
  return (
    <FormProvider {...form}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={3}>
          {/*form.formState.errors ? (
                <Alert severity="error">
                  <Typography>{form.formState.errors}</Typography>
                </Alert>
              ) : null*/}
          <InputText
            label="Nome"
            name="first_name"
            data-testid="registration-first_name"
          />
          <InputText
            label="Cognome"
            name="last_name"
            data-testid="registration-last_name"
          />
          <InputText
            label="Telefono"
            name="phone"
            data-testid="registration-phone"
          />
          <InputText
            label="Email"
            name="email"
            data-testid="registration-email"
          />
          <InputText
            type="password"
            label="Password"
            name="password"
            data-testid="registration-password"
          />

          {/*
              <Typography variant="faint">
                La password deve contenere:
                <br />
                · Almeno 8 caratteri
                <br />
                · Almeno 1 carattere Maiuscolo
                <br />
                · Almeno 1 numero
                <br />· Almeno 1 simbolo
              </Typography>
              <InfoTooltip sx={{ alignSelf: 'center', display: 'inline', float: 'right'}} text='La password deve contenere: · Almeno 8 caratteri · Almeno 1 carattere Maiuscolo · Almeno 1 numero' />
              <Tooltip title='La password deve contenere: · Almeno 8 caratteri · Almeno 1 carattere Maiuscolo · Almeno 1 numero'>
              {errors.repeat_password?.message ? (
                <Alert
                  severity="error"
                  data-testid="regitration-repeat-passowrd-error"
                >
                  <Typography>{errors.repeat_password?.message}</Typography>
                </Alert>
              ) : null}*/}

          <InputText
            type="password"
            label="Conferma Password"
            name="repeat_password"
            data-testid="registration-repeat-password"
          />

          {!showDiscountCode && (
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowDiscountCode(true)}
            >
              Hai un codice sconto?
            </Typography>
          )}
          {showDiscountCode && (
            <InputText
              type="text"
              label="Codice sconto"
              name="discount_code"
              data-testid="registration-discount_code"
            />
          )}
          <Grid container sx={{ mt: '48px !important' }}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" onClick={props.onBack}>
                <ArrowBack sx={{ mr: '10px' }} /> Indietro
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={form.formState.isSubmitting}
                data-testid="regitration-submit"
              >
                Prosegui
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
}
