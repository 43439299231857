import {
  useFormMethods,
  useHandleClose,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { ReportProjectDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useOutletContext } from 'react-router-dom';
import ReportForm from '../components/report-form/report-form';
import { useGetReport } from '../data-access';
import { ProjectsListPageOutletContext } from './projects-list-page';

export const ReportProjectsPage = () => {
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();
  const { getReport } = useGetReport();
  const formMethods = useFormMethods(ReportProjectDto);
  const handleClose = useHandleClose(formMethods);

  const selected =
    outletContext?.selectedProjects
      ?.map((proj) => proj.project_uuid)
      ?.join(',') || '';

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      data-testid="report-project-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={600}>
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Report
        </Typography>
        <ReportForm
          handleClose={() => handleClose()}
          onSubmit={(values) =>
            getReport({
              orderBy: values.orderBy,
              photo: values.photo,
              format: values.format,
              search: outletContext.searchedFields?.search_text,
              search_brand: outletContext.searchedFields?.brand,
              search_category: outletContext.searchedFields?.search_category,
              search_sub_category:
                outletContext.searchedFields?.search_sub_category,
              search_supplier: outletContext.searchedFields?.search_supplier,
              search_date_from: outletContext.searchedFields?.date_start,
              search_date_to: outletContext.searchedFields?.date_end,
              selected: selected,
            })
              .then(() => handleClose(true))
              .catch((error) => console.error(error))
          }
          formMethods={formMethods}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default ReportProjectsPage;
