import { useController } from 'react-hook-form';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { InfoTooltip } from '../components';

export interface CheckboxGroupCustomProps {
  name: string;
  label?: string;
  required?: boolean;
  options: Array<{ label: string; value: string; disabled?: boolean }>;
  disabled?: boolean;
  tooltipText?: string;
}

export function CheckboxGroupCustom(props: CheckboxGroupCustomProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  const fieldValue = field.value || [];

  return (
    <>
      <FormControl>
        <Stack direction="row" justifyContent="space-between">
          {props.label && <FormLabel id={props.name}>{props.label}</FormLabel>}
          {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
        </Stack>
        <FormGroup row aria-labelledby={props.name}>
          {props.options.map((option) => {
            const isChecked = fieldValue.includes(option.value);
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Checkbox
                    color="warning"
                    checked={isChecked}
                    disabled={props.disabled || option.disabled}
                    onChange={() => {
                      if (isChecked) {
                        field.onChange(
                          fieldValue.filter(
                            (item: unknown) => item !== option.value
                          )
                        );
                      } else {
                        field.onChange([...fieldValue, option.value]);
                      }
                    }}
                  />
                }
                label={option.label}
              />
            );
          })}
        </FormGroup>
      </FormControl>

      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
