import { useCallback, useEffect, useState } from 'react';

function getInitialState(key?: string) {
  if (!key) {
    return [];
  }
  const localitem = localStorage.getItem(key);
  if (localitem) {
    const list = JSON.parse(localitem);
    return list;
  }
  return [];
}

export function useGetItemsSelected(key?: string) {
  const [itemsSelected, setItemsSelected] = useState<Array<string>>(
    getInitialState(key)
  );

  const toggleItemsSelected = useCallback((id: string) => {
    setItemsSelected((oldItems) => {
      if (oldItems.find((item: string) => item === id)) {
        return oldItems.filter((item: string) => item !== id);
      } else {
        return [...oldItems, id];
      }
    });
  }, []);

  const isSelected = useCallback(
    (findFunc: (id: string) => boolean) => {
      return itemsSelected.find(findFunc) ? true : false;
    },
    [itemsSelected]
  );

  const removeItemsSelected = useCallback((id: string) => {
    setItemsSelected((oldItems) =>
      oldItems.filter((item: string) => item !== id)
    );
  }, []);

  const selectAll = useCallback((ids: Array<string>) => {
    setItemsSelected(ids);
  }, []);

  const deselectAll = useCallback(() => {
    setItemsSelected([]);
  }, []);

  useEffect(() => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(itemsSelected));
    }
  }, [itemsSelected, key]);

  return {
    toggleItemsSelected,
    itemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  };
}
