import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UserDto } from '@docpack/shared/dtos';

export function useAcceptTerms() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function acceptTerms(type: string) {
    const result = await fetcher(`/api/user/accept-terms/${type}`, {
      method: 'POST',
      body: {},
    });

    return handle<UserDto>(result);
  }

  return {
    error,
    acceptTerms,
  };
}
