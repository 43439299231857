import { InputText } from '@docpack/frontend/core-components';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from 'react-hook-form';
import { UpdateUserSettingsDto, UserDto } from '@docpack/shared/dtos';

export interface UpdateUserFormProps {
  onSubmit: (
    data: UpdateUserSettingsDto
  ) => Promise<UserDto | undefined | void>;
  defaultValues?: UpdateUserSettingsDto;
  resetPassword: () => Promise<boolean | undefined | void>;
}

export const UpdateUserForm = (props: UpdateUserFormProps) => {
  const resolver = classValidatorResolver(UpdateUserSettingsDto);
  const formMethods = useForm<UpdateUserSettingsDto>({
    resolver,
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={2}>
          <Typography variant="body3">Dati utente</Typography>
          <InputText name="first_name" label="Nome" />
          <InputText name="last_name" label="Cognome" />
          <InputText name="email" label="Email" />
          <InputText name="phone" label="Phone" />
          <InputText name="username" label="Username" />
          <Typography variant="body3">Password</Typography>
          <Button
            variant="contained"
            size="small"
            data-testid="settings-reset-password"
            onClick={() => props.resetPassword()}
            sx={{ alignSelf: 'flex-start', backgroundColor: 'gray.main' }}
          >
            Reset password
          </Button>
        </Stack>
        <Button type="submit" variant="contained" sx={{ mt: 4 }}>
          Salva
        </Button>
      </form>
    </FormProvider>
  );
};

export default UpdateUserForm;
