import { FormProvider, useForm } from 'react-hook-form';
import { WhiteBox } from '../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ResetPasswordDto } from '@docpack/shared/dtos';
import { AlertController, InputText } from '@docpack/frontend/core-components';
import { useResetPassword } from '../data-access/reset-password.hook';

import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ token?: string }>();

  const resolver = classValidatorResolver(ResetPasswordDto);
  const form = useForm<ResetPasswordDto>({
    resolver,
    defaultValues: {
      token: params.token,
    },
  });

  const { resetPassword, error } = useResetPassword();

  function onSubmit(data: ResetPasswordDto) {
    return resetPassword(data)
      .then(() => navigate('../reset-success'))
      .catch(console.error);
  }

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <WhiteBox>
        <Typography variant="h5" mb={2} sx={{ textAlign: 'center' }}>
          Scegli una password
        </Typography>
        <Typography variant="body1" mb={6} sx={{ textAlign: 'center' }}>
          Inserisci la password due volte per completare la procedura
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <InputText type="password" name="password" label="Password" />
              <Typography variant="faint">
                La password deve contenere:
                <br />
                · Almeno 8 caratteri
                <br />
                · Almeno 1 carattere Maiuscolo
                <br />
                · Almeno 1 numero
                <br />· Almeno 1 simbolo
              </Typography>
              <InputText
                type="password"
                name="repeat_password"
                label="Conferma Password"
              />
              <Grid container sx={{ mt: '48px !important' }}>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button variant="text" component={Link} to="/">
                    <ArrowBackIcon sx={{ mr: '10px' }} /> Indietro
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button type="submit" variant="contained">
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </WhiteBox>
    </>
  );
};

export default ResetPasswordPage;
