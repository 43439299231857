import { InputText } from '@docpack/frontend/core-components';
import { UpdateCategoryDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export interface EditCategoryFormProps {
  handleClose: () => void;
  onSubmit: (val: UpdateCategoryDto) => Promise<unknown>;
  defaultValues?: Partial<UpdateCategoryDto>;
  formMethods: UseFormReturn<UpdateCategoryDto, object>;
}

export const EditCategoryForm = (props: EditCategoryFormProps) => {
  return (
    <FormProvider {...props.formMethods}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={2}>
          <InputText name="name" label="Nome categoria" required />
        </Stack>
        <Grid container sx={{ mt: '32px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="add-project-submit-button"
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditCategoryForm;
