import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FormProvider, useController, UseFormReturn } from 'react-hook-form';
import { AssetsDownloadTypes } from '@docpack/shared/dtos';
import AssetTypeButton from '../asset-type-button/asset-type-button';
import { IsArray } from 'class-validator';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function ImageIcon(props: { small?: boolean }) {
  return (
    <svg
      width="64"
      height="56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props.small ? 'scale(0.5)' : 'none' }}
    >
      <path d="M24 18a6 6 0 1 1-12 0 6 6 0 0 1 12 0z" fill="#242628" />
      <path
        d="M8 0a8 8 0 0 0-8 8v40a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8zm48 4a4 4 0 0 1 4 4v26l-15.108-7.788a2 2 0 0 0-2.308.372l-14.84 14.84-10.64-7.088a2 2 0 0 0-2.52.248L4 44V8a4 4 0 0 1 4-4h48z"
        fill="#242628"
      />
    </svg>
  );
}

export function LayoutIcon(props: { small?: boolean }) {
  return (
    <svg
      width="49"
      height="64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props.small ? 'scale(0.5)' : 'none' }}
    >
      <path
        d="M48.5 56V19.657a4 4 0 0 0-1.172-2.829L31.672 1.172A4 4 0 0 0 28.843 0H8.5a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8zm-18-44a6 6 0 0 0 6 6h6a2 2 0 0 1 2 2v36a4 4 0 0 1-4 4h-32a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a2 2 0 0 1 2 2v6z"
        fill="#242628"
      />
      <path
        d="M10.912 56.349a3.24 3.24 0 0 1-1.752-1.68c-.78-1.552-.52-3.104.32-4.408.792-1.229 2.104-2.273 3.588-3.149a30.723 30.723 0 0 1 5.928-2.58 78.775 78.775 0 0 0 4.248-8.907 29.079 29.079 0 0 1-1.72-5.18c-.344-1.6-.476-3.184-.184-4.544.3-1.416 1.096-2.689 2.6-3.293.768-.308 1.6-.48 2.408-.308a2.8 2.8 0 0 1 1.908 1.46c.352.656.48 1.424.508 2.152.028.753-.048 1.585-.188 2.457-.336 2.04-1.08 4.535-2.08 7.175a43.816 43.816 0 0 0 3.92 6.745c1.78-.141 3.571-.074 5.336.2 1.456.264 2.936.78 3.84 1.86.48.576.772 1.28.8 2.072.028.767-.188 1.528-.552 2.252a4.16 4.16 0 0 1-1.416 1.663 3.424 3.424 0 0 1-2.04.553c-1.324-.056-2.616-.785-3.732-1.669a22.851 22.851 0 0 1-3.644-3.8c-2.705.307-5.378.85-7.988 1.624a45.23 45.23 0 0 1-4.08 6.04c-1.168 1.4-2.436 2.624-3.708 3.148-.73.331-1.56.373-2.32.117zm5.516-7.604a20.07 20.07 0 0 0-1.836.952c-1.312.775-2.164 1.531-2.588 2.188-.376.58-.384 1-.16 1.444a.91.91 0 0 0 .104.175c.048-.012.095-.028.14-.047.548-.224 1.42-.94 2.54-2.288a32.718 32.718 0 0 0 1.8-2.424zm6.56-5.32a50.84 50.84 0 0 1 4.04-.772 47.006 47.006 0 0 1-2.04-3.432 83.186 83.186 0 0 1-2 4.2v.004zm9.784 1.8c.6.651 1.184 1.2 1.74 1.64.96.76 1.628 1.011 1.992 1.023a.429.429 0 0 0 .28-.06c.166-.13.296-.303.376-.5.142-.243.223-.518.236-.8a.38.38 0 0 0-.104-.252c-.208-.248-.8-.608-2.072-.836a15.507 15.507 0 0 0-2.448-.212v-.004zM24.812 31.2c.337-1.086.604-2.193.8-3.313.124-.752.172-1.372.152-1.86a2.451 2.451 0 0 0-.128-.791 2.07 2.07 0 0 0-.58.16c-.348.14-.632.424-.784 1.132-.16.768-.12 1.875.184 3.288.096.444.216.907.36 1.384h-.004z"
        fill="#242628"
      />
    </svg>
  );
}

export function ExecutiveIcon(props: { small?: boolean }) {
  return (
    <svg
      width="48"
      height="64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props.small ? 'scale(0.5)' : 'none' }}
    >
      <path
        d="M18 30a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3.752l1.6 6.396a4 4 0 0 1-1.664 4.296l-3.72 2.48a4 4 0 0 1-4.436 0l-3.72-2.48a4 4 0 0 1-1.66-4.296l1.6-6.396V30zm8 0h-4v3.752c0 .328-.04.654-.12.972l-1.6 6.392 3.72 2.48 3.72-2.48-1.6-6.392a4.007 4.007 0 0 1-.12-.972V30z"
        fill="#242628"
      />
      <path
        d="M0 8a8 8 0 0 1 8-8h32a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8zm22-4H8a4 4 0 0 0-4 4v48a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4H28v4h-4v4h4v4h-4v4h4v4h-6v-4h-4v-4h4v-4h-4V8h4V4z"
        fill="#242628"
      />
    </svg>
  );
}

export interface AssetTypeButtonInputProps {
  type: AssetsDownloadTypes;
  name: string;
  label: string;
  isDisabled?: boolean;
  small?: boolean;
  cy?: string;
}

export function AssetTypeButtonInput(
  props: React.PropsWithChildren<AssetTypeButtonInputProps>
) {
  const { field } = useController({ name: props.name });

  const isSelected = field.value === props.type;

  return (
    <AssetTypeButton
      name={props.label}
      isSelected={isSelected}
      isDisabled={props.isDisabled}
      onClick={() => {
        isSelected ? field.onChange(undefined) : field.onChange(props.type);
      }}
      small={props.small}
      cy={props.cy}
    >
      {props.children}
    </AssetTypeButton>
  );
}

export class SelectAssetsToDownloadForm {
  @IsArray()
  assetsTypes: Array<AssetsDownloadTypes>;
}

export interface ProjectAssetsDownloadProps {
  onClose: () => void;
  onSubmit: (data: SelectAssetsToDownloadForm) => void;
  disabledAssets?: Array<AssetsDownloadTypes>;
  formMethods: UseFormReturn<SelectAssetsToDownloadForm, object>;
}

export function ProjectAssetsDownload(props: ProjectAssetsDownloadProps) {
  const theme = useTheme();
  const mediaQuerySmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FormProvider {...props.formMethods}>
      <form
        onSubmit={props.formMethods.handleSubmit((val) =>
          props.onSubmit({
            assetsTypes: val.assetsTypes?.filter(
              (item: AssetsDownloadTypes) => !!item
            ),
          })
        )}
      >
        <Stack>
          <Typography variant="body3" mb={3}>
            Scegli i file da scaricare
          </Typography>
          <Grid container justifyContent="space-between">
            <AssetTypeButtonInput
              label="Image"
              name="assetsTypes[0]"
              type={AssetsDownloadTypes.Picture}
              isDisabled={
                !!props.disabledAssets?.find(
                  (asset) => asset === AssetsDownloadTypes.Picture
                )
              }
              cy="project-image-download-selection-button"
            >
              <ImageIcon small={mediaQuerySmDown ? true : false} />
            </AssetTypeButtonInput>

            <AssetTypeButtonInput
              label="Layout"
              name="assetsTypes[1]"
              type={AssetsDownloadTypes.Layout}
              isDisabled={
                !!props.disabledAssets?.find(
                  (asset) => asset === AssetsDownloadTypes.Layout
                )
              }
            >
              <LayoutIcon small={mediaQuerySmDown ? true : false} />
            </AssetTypeButtonInput>

            <AssetTypeButtonInput
              label="Esecutivo"
              name="assetsTypes[2]"
              type={AssetsDownloadTypes.Executive}
              isDisabled={
                !!props.disabledAssets?.find(
                  (asset) => asset === AssetsDownloadTypes.Executive
                )
              }
            >
              <ExecutiveIcon small={mediaQuerySmDown ? true : false} />
            </AssetTypeButtonInput>
          </Grid>
          <Grid container sx={{ mt: '48px !important' }}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" onClick={props.onClose}>
                Annulla
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                disabled={props.formMethods.formState?.isSubmitting}
                data-testid="project-assets-download-button"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
}
