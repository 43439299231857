import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  ProjectAssetsDownload,
  SelectAssetsToDownloadForm,
} from '../components/project-assets-download/project-assets-download';
import { ProjectsListPageOutletContext } from './projects-list-page';
import { saveAs } from 'file-saver';
import {
  AssetsDownloadTypes,
  ProjectDto,
  UserPermission,
  UserRole,
} from '@docpack/shared/dtos';
import {
  useAccessControl,
  useFormMethods,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';

export function extractDisabledAssets(
  projects: Array<ProjectDto> = [],
  assetsAccess: { photo: boolean; layout: boolean; exe: boolean }
) {
  const disabled: Array<AssetsDownloadTypes> = [];
  for (const project of projects) {
    if (!project.picture && !disabled.includes(AssetsDownloadTypes.Picture)) {
      disabled.push(AssetsDownloadTypes.Picture);
    }
    if (!project.layout && !disabled.includes(AssetsDownloadTypes.Layout)) {
      disabled.push(AssetsDownloadTypes.Layout);
    }
    if (
      !project.executive &&
      !disabled.includes(AssetsDownloadTypes.Executive)
    ) {
      disabled.push(AssetsDownloadTypes.Executive);
    }
  }

  if (!assetsAccess.photo && disabled.includes(AssetsDownloadTypes.Picture)) {
    disabled.splice(disabled.indexOf(AssetsDownloadTypes.Picture), 1);
  }
  if (!assetsAccess.layout && disabled.includes(AssetsDownloadTypes.Layout)) {
    disabled.splice(disabled.indexOf(AssetsDownloadTypes.Layout), 1);
  }
  if (!assetsAccess.exe && disabled.includes(AssetsDownloadTypes.Executive)) {
    disabled.splice(disabled.indexOf(AssetsDownloadTypes.Executive), 1);
  }

  return disabled;
}

export function ProjectAssetsDownloadPage() {
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();

  const selectedProjects = outletContext.selectedProjects || [];

  const enablePhotoDownload = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
    permissions: [UserPermission.Scaricare],
  });
  const enableLayoutDownload = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
    permissions: [UserPermission.Scaricare],
  });
  const enableExeDownload = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
    permissions: [UserPermission.Scaricare],
  });

  const disabledAssets = extractDisabledAssets(outletContext.selectedProjects, {
    photo: enablePhotoDownload,
    layout: enableLayoutDownload,
    exe: enableExeDownload,
  });

  const formMethods = useFormMethods(SelectAssetsToDownloadForm);
  const navigate = useNavigate();

  return (
    <Modal
      open={true}
      onClose={() => navigate('..')}
      data-testid="download-assets-project-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={573}>
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Download
        </Typography>
        <ProjectAssetsDownload
          onClose={() => navigate('..')}
          onSubmit={(data) => {
            saveAs(
              `/api/project/download?projects=${selectedProjects
                .map((proj) => proj.project_uuid)
                .join(',')}&types=${data.assetsTypes.join(',')}`
            );

            navigate('..');
          }}
          disabledAssets={disabledAssets}
          formMethods={formMethods}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default ProjectAssetsDownloadPage;
