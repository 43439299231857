import { SearchBar, usePagination } from '@docpack/frontend/core-components';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import ActivityTable from '../components/activity-table';
import { useGetActivities } from '../data-access/get-activities.hook';

export const AcitivityPage = () => {
  const { page, nextPage } = usePagination();

  const formMethods = useForm({ mode: 'onChange' });
  const watchAllFields = formMethods.watch();
  const { data, offset } = useGetActivities({
    search: watchAllFields['search_text'] || '',
    search_user_type: watchAllFields['user_type'] || '',
    search_date_from: watchAllFields['date_start'] || '',
    search_date_to: watchAllFields['date_end'] || '',
    page: page.toString(),
  });

  return (
    <>
      <SearchBar selectOptions="user-type" formMethods={formMethods} />
      <Typography variant="h5" color="primary" mt={3}>
        Log eventi
      </Typography>
      <ActivityTable
        data={data?.data || []}
        handleChange={nextPage}
        page={page + 1}
        count={data?.count}
        offset={offset}
      />
    </>
  );
};

export default AcitivityPage;
