import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { UpdateSubCategoryDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetSingleSubCategory, useUpdateSubCategory } from '../data-access';
import { CategoriesListPageOutletContext } from './categories-list-page';
import EditSubcategoryForm from '../components/edit-subcategory-form/edit-subcategory-form';

export const EditSubCategoryPage = () => {
  const outletContext = useOutletContext<CategoriesListPageOutletContext>();
  const params = useParams();
  const sub_category_uuid = params['sub_category_uuid'] || '';
  const { data: subCategory, mutate: subCategoryMutate } =
    useGetSingleSubCategory(sub_category_uuid);
  const { updateSubCategory, error: errorUpdateSubcategory } =
    useUpdateSubCategory();
  const formMethodsSubcat = useFormMethods(UpdateSubCategoryDto, true, {
    sub_category_uuid: sub_category_uuid,
    name: subCategory?.name,
    category_uuid: subCategory?.category_uuid,
  });
  const handleClose = useHandleClose(formMethodsSubcat);
  const showMessage = useMessagePopup();

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={500}>
        <AlertController
          message={
            errorUpdateSubcategory
              ? { type: 'error', text: errorUpdateSubcategory }
              : undefined
          }
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Modifica sottocategoria
        </Typography>
        <EditSubcategoryForm
          handleClose={() => handleClose()}
          onSubmit={(data) =>
            updateSubCategory(data)
              .then(() => outletContext?.reloadCategories())
              .then(() => {
                showMessage(
                  'Sottocategoria creata con successo!',
                  'success',
                  () => {
                    handleClose(true);
                  }
                );
                subCategoryMutate();
              })
              .catch((error) => console.error(error))
          }
          formMethods={formMethodsSubcat}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default EditSubCategoryPage;
