import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { SupplierDto, UpdateSupplierDto } from '@docpack/shared/dtos';

export function useUpdateSupplier() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function updateSupplier(data: UpdateSupplierDto) {
    const result = await fetcher(`/api/suppliers/update`, {
      method: 'PUT',
      body: data,
    });

    return handle<SupplierDto>(result);
  }

  async function deleteSupplier(supplier_uuid: string) {
    const result = await fetcher(`/api/suppliers/${supplier_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<SupplierDto>(result);
  }

  return {
    error,
    updateSupplier,
    deleteSupplier,
  };
}
