import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { ProjectDto } from '@docpack/shared/dtos';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Divider from '@mui/material/Divider';

export interface ProjectBoxProps {
  data: ProjectDto;
  logo?: string;
  project_code: string;
  name: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  itemsSelectedCount?: number;
  disableImage?: boolean;
  showDetails?: boolean;
}

export const ImageProject = styled.img`
  aspect-ratio: auto;
  background-color: #ffffff;
  object-fit: contain;
  height: 180px;
  cursor: pointer;
`;

const CheckSelection = styled.i`
  display: flex;
  position: absolute;
  top: 8px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
`;

const CheckSelectionBg = styled.span`
  position: absolute;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  z-index: 1;
  top: 2px;
  left: 2px;
`;

export function ProjectBox(props: PropsWithChildren<ProjectBoxProps>) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: 'white.main',
        borderRadius: '24px',
        padding: '16px',
        border: 'solid 2px',
        height: '100%',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        position: 'relative',
      }}
      data-testid="project-item"
    >
      {props.selected ? (
        <CheckSelection
          onClick={props.onClick}
          data-testid="project-item-deselect-button"
        >
          <CheckCircleIcon
            color="success"
            fontSize="medium"
            sx={{ zIndex: 2 }}
          />
          <CheckSelectionBg />
        </CheckSelection>
      ) : (
        <CheckSelection
          onClick={props.onClick}
          data-testid="project-item-select-button"
        >
          <RadioButtonUncheckedIcon fontSize="medium" sx={{ zIndex: 2 }} />
          <CheckSelectionBg />
        </CheckSelection>
      )}
      <Stack>
        <ImageProject
          src={props.data.thumbnail}
          onClick={(e) => {
            e.stopPropagation();
            if (!props.disableImage) {
              navigate(`detail/${props.data.project_uuid}`);
            }
          }}
        />
        <Typography
          paddingTop="0.5rem"
          textAlign="center"
          color="secondary.dark"
        >
          {props.project_code}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {props.name}
        </Typography>
        {props.showDetails && (
          <>
            <Divider variant="middle" sx={{ margin: '1rem 0' }} />
            <Stack direction="column" alignItems="center">
              {props.data?.brands && props.data?.brands?.length > 0 && (
                <Typography variant="overline" sx={{ textAlign: 'center' }}>
                  Marchio:{' '}
                  {props.data.brands?.map((brand, index) => {
                    if (
                      props.data?.brands &&
                      props.data?.brands?.length > index + 1
                    ) {
                      return `${brand.name}, `;
                    } else {
                      return brand.name;
                    }
                  })}
                </Typography>
              )}
              {props.data.internal_code && (
                <Typography variant="overline">
                  Cod Int: {props.data.internal_code}
                </Typography>
              )}
              {props.data.suppliers && (
                <Typography variant="overline">
                  For.: {props.data.suppliers.name}
                </Typography>
              )}
              {props.data.ean && (
                <Typography variant="overline">
                  EAN: {props.data.ean}
                </Typography>
              )}
              {props.data.category && (
                <Typography variant="overline">
                  Categoria: {props.data.category.name}
                </Typography>
              )}
              {props.data.subCategory && (
                <Typography variant="overline">
                  Sottocat.: {props.data.subCategory.name}
                </Typography>
              )}
              {props.data.weight && (
                <Typography variant="overline">
                  Peso / quantità: {props.data.weight}
                </Typography>
              )}
              <Typography variant="overline">
                Data di inserimento:{' '}
                {new Date(props.data.created_at).toLocaleDateString()}
              </Typography>
              <Typography variant="overline">
                Data modifica:{' '}
                {new Date(props.data.updated_at).toLocaleDateString()}
              </Typography>
            </Stack>
          </>
        )}
        {props.selected &&
        props.itemsSelectedCount &&
        props.itemsSelectedCount === 1
          ? props.children
          : null}
      </Stack>
    </Box>
  );
}

export function ProjectBoxSkeleton() {
  return (
    <Box
      sx={{
        backgroundColor: 'white.main',
        borderRadius: '24px',
        padding: '24px',
        border: 'solid 2px',
        height: '300px',
        width: 210,
        borderWidth: '2px',
        borderColor: 'gray.light',
        boxShadow: 'none',
      }}
    >
      <Skeleton height={200} />
      <Skeleton />
      <Skeleton />
    </Box>
  );
}
