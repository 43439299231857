import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  SelectUserRoleInput,
  SelectUserTypeInput,
  WhiteBox,
} from '@docpack/frontend/core-components';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { useGetPlatformRegistrations } from './hook/get-platform-registrations.hook';
import {
  alertTitleClasses,
  Chip,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { UserPermission } from '@docpack/shared/dtos';
import { RequiredAuth } from '@docpack/frontend/client-authorization';
import { useParams, Navigate, useSearchParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteUser } from './hook/delete-user';
import React, { useEffect } from 'react';

//TODO move in its own module under core-components
const ChipsCell = (props: any) => {
  return (
    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }} justifyContent="start">
      {props.items.map((label: string) => {
        return (
          <Chip
            key={label}
            sx={{ marginTop: '8px !important', marginLeft: '4px' }}
            label={label}
            variant="outlined"
          />
        );
      })}
    </Stack>
  );
};
const PermissionCell = (props: any) => {
  const permissions =
    props.details.user_role !== 'Amministratore'
      ? props.details?.user_permission
      : Object.values(UserPermission);
  return (
    <TableCell>
      <ChipsCell items={permissions} />
    </TableCell>
  );
};
const FormatCell = (props: any) => {
  const formats = ['Image', 'Layout', 'Esecutivo'];
  return (
    <TableCell>
      <ChipsCell items={formats} />
    </TableCell>
  );
};
export function PlatformRegistrations() {
  const formMethods = useForm({ mode: 'onChange' });
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('api_key') || '';
  const { deleteUser, error: deleteUserError } = useDeleteUser(key);
  const { data, error, mutate } = useGetPlatformRegistrations({
    api_key: key,
    user_role: formMethods.watch('user_role'),
    user_type: formMethods.watch('user_type'),
  });

  //if (loading) return  (<Box sx={{padding: '18px'}}><Skeleton sx={{ height: 150 }}/></Box>)

  return (
    <Box sx={{ backgroundColor: 'white', height: '100vh' }}>
      <AppBar color="secondary" elevation={0} position="sticky">
        <Box sx={{ padding: '18px 0', margin: '0 auto' }}>
          <svg
            width="45.375"
            height="44"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              display: 'block',
            }}
          >
            <path
              d="M17.432 11.668a11.177 11.177 0 0 0-1.92-3.828c-.451-.606-1.062-.873-1.648-1.223a2.482 2.482 0 0 1-.972-1.014 2.406 2.406 0 0 1-.254-1.366c.125-1.234 1.324-2.066 2.49-1.662a4.11 4.11 0 0 1 1.227.755c.439.367.909.495 1.386.214 1.813-1.065 3.62-2.138 5.44-3.2.225-.133.48-.222.736-.344.12.53-.141.734-.363.921a26.222 26.222 0 0 1-2.096 1.692c-.78.53-1.368 1.22-1.933 2.002-.46.634-1.088 1.137-1.698 1.61-.336.261-.32.449-.16.781.715 1.518 1.74 2.79 2.696 4.115.76 1.053 1.464 2.148 1.847 3.444a8.291 8.291 0 0 1 .32 3.561c-.076.519-.036.575.435.662 1.28.238 2.56.49 3.84.727.302.056.611.08.917.107.585.053 1.02-.214 1.342-.752.63-1.056 1.28-2.104 1.91-3.154.07-.113.149-.22.236-.321.232-.266.524-.252.692 0a.896.896 0 0 1 .063.73c-1.12 2.004-2.279 3.986-3.429 5.971a2.23 2.23 0 0 1-.32.469c-.205.2-.48.293-.674.04a.92.92 0 0 1-.16-.674c.24-.806.16-.97-.592-1.094a196.323 196.323 0 0 1-4.219-.78c-.293-.058-.526-.046-.683.27-.8 1.635-2.08 2.677-3.58 3.415-1.255.625-2.56 1.06-3.96 1.11a9.112 9.112 0 0 1-1.255-.037.653.653 0 0 0-.438.062.63.63 0 0 0-.292.324c-.721 1.337-1.472 2.655-2.197 3.99-.32.585-.195.82.412.824h3.737c.554 0 .832.468.576.937a.487.487 0 0 1-.345.237c-2.282.014-4.564 0-6.845.016a.379.379 0 0 1-.303-.11.36.36 0 0 1-.099-.301c.02-.244.144-.662.269-.684 1.11-.186 1.374-1.273 1.864-2.091.554-.923 1.016-1.914 1.542-2.858.2-.36.114-.513-.214-.644-.813-.328-1.654-.598-2.418-1.032-2.328-1.325-4.224-3.149-5.33-5.85C2.41 16.16 1.559 14.895.27 14.041a1.37 1.37 0 0 1-.238-.276c.1-.1.184-.257.3-.29.478-.158.97-.277 1.468-.357a4.716 4.716 0 0 1 1.382 0c.592.103 1.048-.127 1.522-.469 1.73-1.218 3.554-2.231 5.565-2.726a6.992 6.992 0 0 1 3.457.062 9.286 9.286 0 0 1 3.344 1.535c.047.111.168.102.362.147z"
              fill="#242628"
            />
          </svg>
        </Box>
      </AppBar>
      <WhiteBox>
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Registrazioni alla piattaforma
        </Typography>
        {error ? (
          'Non sei autorizzato ad accedere a questa sezione'
        ) : (
          <React.Fragment>
            <Paper
              sx={{
                padding: { xs: '16px', md: '16px 32px' },
              }}
            >
              <FormProvider {...formMethods}>
                <form>
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <SelectUserTypeInput
                        name="user_type"
                        label="Tipo Utente"
                        placeholderItem="Tutti"
                        sx={{
                          width: '100%',
                        }}
                        small
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <SelectUserRoleInput
                        name="user_role"
                        label="Ruolo Utente"
                        placeholderItem="Tutti"
                        sx={{
                          width: '100%',
                        }}
                        small
                      />
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ mt: 4, boxShadow: 'none' }}
              style={{ border: '1px gray solid' }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#ecedef' }}>
                  <TableRow>
                    <TableCell>Azienda</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Città</TableCell>
                    <TableCell>Ruolo</TableCell>
                    <TableCell sx={{ minWidth: 200 }}>Download</TableCell>
                    <TableCell sx={{ minWidth: 264 }}>Permessi</TableCell>
                    <TableCell>Data Creazione</TableCell>
                    <TableCell>Azioni</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow
                      key={`${row.email}-${index}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.workspace?.workspace_payment?.[0]?.business_name}
                      </TableCell>
                      <TableCell>
                        {row.details?.first_name} {row.details?.last_name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.workspace?.workspace_payment?.[0]?.city}
                      </TableCell>
                      <TableCell>{row.details?.user_role}</TableCell>
                      <FormatCell />
                      <PermissionCell details={row.details} />
                      <TableCell align="left">
                        {new Date(row.created_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Sei sicuro di voler eliminare ${row.email}?`
                              )
                            ) {
                              deleteUser(row.user_uuid)
                                .then(() => {
                                  alert('Utente cancellato con successo');
                                  mutate();
                                })
                                .catch((error) => {
                                  console.error(error);
                                  alert(
                                    "Errore durante la cancellazione dell'utente"
                                  );
                                });
                            }
                          }}
                        >
                          <DeleteIcon fontSize={'medium'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </WhiteBox>
    </Box>
  );
}
