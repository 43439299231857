import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { NewCategoryDto, NewSubCategoryDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CategoryForm, SubcategoryForm } from '../components';
import { useNewCategory } from '../data-access';
import { useNewSubcategory } from '../data-access/new-subcategory.hook';
import { CategoriesListPageOutletContext } from './categories-list-page';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { InfoTooltip } from '@docpack/frontend/core-components';
import Stack from '@mui/material/Stack';

export type FormType = 'category' | 'subcategory';

export const NewCategoryPage = () => {
  const outletContext = useOutletContext<CategoriesListPageOutletContext>();
  const { newCategory, error: errorNewCategory } = useNewCategory();
  const { newSubcategory, error: errorNewSubcategory } = useNewSubcategory();
  const formMethodsCat = useFormMethods(NewCategoryDto, true);
  const formMethodsSubcat = useFormMethods(NewSubCategoryDto, true);
  const handleClose = useHandleClose(formMethodsCat && formMethodsSubcat);
  const showMessage = useMessagePopup();
  const [formType, setFormType] = useState<FormType>('category');

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={450}>
        <AlertController
          message={
            errorNewCategory
              ? { type: 'error', text: errorNewCategory }
              : undefined
          }
        />
        <AlertController
          message={
            errorNewSubcategory
              ? { type: 'error', text: errorNewSubcategory }
              : undefined
          }
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Nuova categoria
        </Typography>
        <FormControl sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <FormLabel id="form-type">Tipo</FormLabel>
            <InfoTooltip text="Scegli se inserire una categoria o una sottocategoria" />
          </Stack>
          <RadioGroup
            row
            aria-labelledby="form-type"
            name="form-type"
            onChange={(e, value) => setFormType(value as FormType)}
            value={formType}
          >
            <FormControlLabel
              value="category"
              control={<Radio color="warning" />}
              label="Categoria"
            />
            <FormControlLabel
              value="subcategory"
              control={<Radio color="warning" />}
              label="Sottocategoria"
            />
          </RadioGroup>
        </FormControl>
        {formType === 'category' ? (
          <CategoryForm
            handleClose={() => handleClose()}
            onSubmit={(data) =>
              newCategory(data)
                .then(() => outletContext?.reloadCategories())
                .then(() =>
                  showMessage(
                    'Categoria creata con successo!',
                    'success',
                    () => {
                      handleClose(true);
                    }
                  )
                )
                .catch((error) => console.error(error))
            }
            formMethods={formMethodsCat}
          />
        ) : (
          <SubcategoryForm
            handleClose={() => handleClose()}
            onSubmit={(data) =>
              newSubcategory(data)
                .then(() => outletContext?.reloadCategories())
                .then(() =>
                  showMessage(
                    'Sottocategoria creata con successo!',
                    'success',
                    () => {
                      handleClose(true);
                    }
                  )
                )
                .catch((error) => console.error(error))
            }
            formMethods={formMethodsSubcat}
          />
        )}
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default NewCategoryPage;
