import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { NewRootUserDto, UserDto } from '@docpack/shared/dtos';

export function useRegisterUser() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function registerNewUser(data: NewRootUserDto, plan: string) {
    const result = await fetcher(`/api/root-user/new`, {
      method: 'POST',
      body: { data, plan: plan },
    });

    return handle<UserDto>(result);
  }

  return {
    error,
    registerNewUser,
  };
}
