import Stack from '@mui/material/Stack';
import {
  DateInput,
  InputTextMultipleItem,
  InputText,
  SelectBrandsInput,
  UploadFile,
  useAccessControl,
  SelectAutocomplete,
  useOptionsSuppliers,
  useWorkspaceSettings,
} from '@docpack/frontend/core-components';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  UpdateProjectDto,
  UserPermission,
  UserRole,
} from '@docpack/shared/dtos';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ConnectProjectInput } from '../connect-project-input/connect-project-input';
import {
  AutocompleteCategory,
  AutocompleteSubCategory,
} from '../../form-inputs';

export interface EditProjectFormProps {
  project_uuid: string;
  cancelBtnOnClick: () => void;
  onSubmit: (data: UpdateProjectDto) => Promise<void>;
  formMethods: UseFormReturn<UpdateProjectDto, object>;
}

export function EditProjectForm(props: EditProjectFormProps) {
  const isAdminOrMaster = useAccessControl({
    roles: [UserRole.Amministratore, UserRole.Master],
  });

  const { settings } = useWorkspaceSettings();

  const { data: suppliers } = useOptionsSuppliers();

  return (
    <FormProvider {...props.formMethods}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <SelectBrandsInput
                name="brand_uuids"
                label="Marchi"
                hasSelectAll
                disabled={!isAdminOrMaster}
                required
                tooltipText="Crea o definisci dentro quale marchio inserire il progetto. Dal menù a tendina puoi selezionare anche più marchi. La definizione del marchio ti aiuta nella categorizzazione dei progetti, per una ricerca agevolata"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText
                name="name"
                label="Nome progetto"
                disabled={!isAdminOrMaster}
                required
                tooltipText="Definisci il nome del progetto. (Max 36 caratteri)"
                cy="edit-project-name-input"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputText
                name="weight"
                label="Peso / quantità"
                tooltipText="Definisci un peso / quantità"
              />
            </Grid>
          </Grid>
          {/*<ConnectProjectInput //aggiungi un progetto
            disabled={!isAdminOrMaster}
            job_disabled={!settings?.job_editable_flag}
          />*/}
          <Grid container>
            <Grid item xs={12} md={4}>
              <InputText name="ean" label="Ean" disabled={!isAdminOrMaster} />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ padding: { xs: '16px 0', md: '0 16px' } }}
            >
              <InputText
                name="internal_code"
                label="Codice interno"
                disabled={!isAdminOrMaster}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateInput
                name="created_at"
                label="Data d'inserimento"
                disabled
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container>
            {settings?.categories_and_subs_flag && (
              <>
                <Grid item xs={12} md={4}>
                  <AutocompleteCategory
                    name="category_uuid"
                    subCategoryFieldName="sub_category_uuid"
                    label="Categoria"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ padding: { xs: '16px 0', md: '0 16px' } }}
                >
                  <AutocompleteSubCategory
                    categoryFieldName="category_uuid"
                    name="sub_category_uuid"
                    label="Sottocategoria"
                  />
                </Grid>
              </>
            )}
            {settings?.suppliers_flag && (
              <Grid item xs={12} md={4}>
                <SelectAutocomplete
                  name="supplier_uuid"
                  label="Fornitori"
                  options={suppliers || []}
                />
              </Grid>
            )}
          </Grid>
          <InputTextMultipleItem
            name="keywords"
            label="Parole chiave"
            placeholder="Aggettivi, fornitore, marchio, sinonimi o altro separati dalla virgola"
            disabled={!isAdminOrMaster}
            tooltipText="Inserisci almeno una parola che descriva il progetto. Più parole si inseriscono e più efficace sarà la ricerca"
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="center"
          >
            <Box sx={{ width: { xs: '100%', md: '33%' } }}>
              <UploadFile
                name="picture_asset_uuid"
                label="Fotografia"
                accept="image/*"
                project_uuid={props.project_uuid}
                disabled={
                  !useAccessControl({
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [UserPermission.Caricare],
                  })
                }
                tooltipText="Immagine ad alta risoluzione del progetto. Il sistema la userà come preview nell’interfaccia di ricerca. Formati supportati: .jpg, .png. Dimensioni consigliate 30x30 cm a 300dpi."
              />
            </Box>
            <Box sx={{ width: { xs: '100%', md: '33%' } }}>
              <UploadFile
                name="layout_asset_uuid"
                label="Layout"
                accept=".pdf"
                project_uuid={props.project_uuid}
                disabled={
                  !useAccessControl({
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [UserPermission.Caricare],
                  })
                }
                tooltipText="Anteprima del progetto complessivo in formato pre-stampa. Formati supportati: .pdf."
              />
            </Box>
            <Box sx={{ width: { xs: '100%', md: '33%' } }}>
              <UploadFile
                name="executive_asset_uuid"
                label="Esecutivo"
                accept=".zip,.rar,.gz"
                project_uuid={props.project_uuid}
                disabled={
                  !useAccessControl({
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [UserPermission.Caricare],
                  })
                }
                tooltipText="Cartella compressa contenente i file di lavorazione, utili alla stampa e realizzazione del progetto. Formati supportati: .zip, .rar, .7z, .cab, .ace."
              />
            </Box>
          </Stack>
        </Stack>
        <Grid container sx={{ mt: '32px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.cancelBtnOnClick}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="edit-project-submit-button"
            >
              Salva modifiche
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
