import { IsOptional, IsString, IsUUID, MinLength } from 'class-validator';

export class UpdateSubCategoryDto {
  @IsUUID()
  sub_category_uuid: string;

  @IsString()
  @MinLength(4)
  @IsOptional()
  name?: string;

  @IsUUID()
  category_uuid: string;
}
