import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InfoTooltip } from '../components';
import Stack from '@mui/material/Stack';

export interface SingleSelectProps {
  name: string;
  label?: string;
  required?: boolean;
  options: Array<{ label: string; value: string }>;
  tooltipText?: string;
  selectCy?: string;
  optionCy?: string;
  disabled?: boolean;
  small?: boolean;
  placeholderItem?: string;
}

export function SingleSelect(props: SingleSelectProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  return (
    <>
      {props.label && (
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <InputLabel id={`select-${props.name}`} sx={{ fontWeight: 500 }}>
            {props.label}
          </InputLabel>
          {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
        </Stack>
      )}
      <Select
        labelId={`select-${props.name}`}
        value={field.value || ''}
        label={props.label}
        onChange={field.onChange}
        sx={{ maxWidth: '100%', width: '100%' }}
        data-testid={props.selectCy}
        displayEmpty
        disabled={props.disabled}
        size={props.small ? 'small' : 'medium'}
      >
        {props.placeholderItem && (
          <MenuItem value="">{props.placeholderItem}</MenuItem>
        )}
        {props.options.map((index) => (
          <MenuItem
            key={index.value}
            value={index.value}
            data-testid={props.optionCy}
          >
            {index.label}
          </MenuItem>
        ))}
      </Select>
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
