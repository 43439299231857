import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { ContactDto, SearchContactsQueryDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetContacts(filters?: SearchContactsQueryDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const params = new URLSearchParams();

  if (filters?.orderBy) {
    params.append('orderBy', filters?.orderBy);
  }

  if (filters?.query) {
    params.append('query', filters?.query);
  }

  const result = useSwr<Array<ContactDto>>(`/api/contact?${params}`, fetcher);

  const data = handle(result.data);

  return {
    ...result,
    data,
    error,
  };
}
