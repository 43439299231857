import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { UpdateContactDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ContactForm from '../components/contact-form';
import { useGetSingleContact } from '../data-access/get-single-contact.hook';
import { useUpdateContact } from '../data-access/update-contact.hook';
import { ContactListContextInterface } from './contacts-page';

export const EditContactPage = () => {
  const routerContext = useOutletContext<ContactListContextInterface>();
  const params = useParams();
  const {
    data,
    error: singleContactError,
    mutate,
  } = useGetSingleContact(params?.['contact_uuid'] || '');
  const { updateContact, error: updateContactError } = useUpdateContact();
  const formMethods = useFormMethods(UpdateContactDto, !!data, {
    contact_uuid: data?.contact_uuid,
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    phone: data?.phone,
    description: data?.description,
    image_contact_asset_uuid: data?.image_contact?.asset_uuid,
  });
  const handleClose = useHandleClose(formMethods);

  const showMessage = useMessagePopup();
  const navigate = useNavigate();

  return (
    <>
      <AlertController
        message={
          singleContactError
            ? { type: 'error', text: singleContactError }
            : undefined
        }
      />
      <AlertController
        message={
          updateContactError
            ? { type: 'error', text: updateContactError }
            : undefined
        }
      />
      <Modal open={true} onClose={() => handleClose()}>
        <WhiteBoxModalContainer>
          <Typography
            variant="h3"
            id="parent-modal-title"
            mb={3}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            Modifica contatto
          </Typography>
          {data ? (
            <ContactForm
              isEdit
              handleClose={() => handleClose()}
              onSubmit={(values: UpdateContactDto) =>
                updateContact(values)
                  .then(() => {
                    mutate();
                    navigate('..');
                  })
                  .then(() => routerContext?.reloadContacts())
                  .then(() =>
                    showMessage(
                      'Contatto modificato con successo',
                      'success',
                      () => handleClose(true)
                    )
                  )
                  .catch((error) => console.error(error))
              }
              formMethods={formMethods}
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </WhiteBoxModalContainer>
      </Modal>
    </>
  );
};

export default EditContactPage;
