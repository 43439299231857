import { IsBoolean, IsOptional } from 'class-validator';

export class UpdateWorkspaceSettingsDto {
  @IsOptional()
  @IsBoolean()
  categories_and_subs_flag?: boolean;

  @IsOptional()
  @IsBoolean()
  suppliers_flag?: boolean;

  @IsOptional()
  @IsBoolean()
  job_editable_flag?: boolean;
}
