import { useForm } from 'react-hook-form';
import { useLogin } from '@docpack/frontend/client-authorization';
import { WhiteBox } from '../components';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

export type Credentials = {
  username: string;
  password: string;
};

export const LoginPage = () => {
  const { login, error } = useLogin();
  const { register, handleSubmit } = useForm<Credentials>();

  return (
    <WhiteBox>
      <Typography variant="h5" mb={6} sx={{ textAlign: 'center' }}>
        Login
      </Typography>
      <form
        onSubmit={handleSubmit((values: Credentials) => {
          login(values);
        })}
      >
        <Stack spacing={2}>
          <TextField
            variant="outlined"
            label="Email"
            size="small"
            data-testid="login-username"
            {...register('username')}
          />
          <TextField
            variant="outlined"
            type="password"
            label="Password"
            size="small"
            data-testid="login-password"
            {...register('password')}
          />

          <Button variant="text" component={Link} to="/forgot-password">
            <Typography variant="caption" mb={0} sx={{ textAlign: 'center' }}>
              <u>Hai dimenticato la password?</u>
            </Typography>
          </Button>

          <Grid container sx={{ mt: '48px !important' }}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" component={Link} to="/">
                <ArrowBackIcon sx={{ mr: '10px' }} /> Indietro
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                data-testid="login-button"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ bottom: '-64px !important', left: '16px', right: '16px' }}
        open={!!error}
        autoHideDuration={6000}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
          data-testid="login-error"
        >
          <Typography>{error ? error?.message : ''}</Typography>
        </Alert>
      </Snackbar>
    </WhiteBox>
  );
};

export default LoginPage;
