import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { NewUserDto, UpdateUserDto, UserDto } from '@docpack/shared/dtos';

export function useNewUser() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newWorkspaceUser(data: NewUserDto) {
    const result = await fetcher(`/api/user/new`, {
      method: 'POST',
      body: data,
    });

    return handle<UserDto>(result);
  }

  async function updateWorkspaceUser(data: UpdateUserDto) {
    const result = await fetcher(`/api/user/update`, {
      method: 'PUT',
      body: data,
    });

    return handle<UserDto>(result);
  }

  return {
    error,
    newWorkspaceUser,
    updateWorkspaceUser,
  };
}
