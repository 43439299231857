import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { SupplierDto } from '@docpack/shared/dtos';

export interface SupplierItemProps {
  data: SupplierDto;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export function SupplierItem(props: SupplierItemProps) {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        padding: { xs: '16px', md: '16px 32px' },
        borderStyle: 'solid',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        borderRadius: '8px',
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      <Grid item xs={6} container alignItems="center">
        <Typography variant="body3">{props.data.name}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="nowrap"
      >
        {props.data.code && (
          <Typography
            variant="body1"
            sx={{
              maxWidth: '100%',
              textAlign: 'center',
              width: '100px',
              flex: '0 0 100px',
              marginRight: '1rem',
            }}
          >
            {props.data.code}
          </Typography>
        )}
        <Typography
          variant="body1"
          sx={{
            maxWidth: '100%',
            textAlign: 'center',
            width: '100px',
            flex: '0 0 100px',
          }}
        >
          {props.data.projects?.length === 1
            ? `1 progetto`
            : `${props.data.projects?.length} progetti`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SupplierItem;
