import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  StateRedirectAuth,
  useLogin,
} from '@docpack/frontend/client-authorization';
import Box from '@mui/system/Box';
import Logo from './logo.png';
import Duck from './duck.svg';

export const Header = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '12px 28px',
        width: '100%',
      }}
    >
      <img width="182px" src={Logo} alt="Logo Docpack" />
    </Box>
  );
};

export function RedirectToAppShell() {
  const { loggedIn, loading } = useLogin();
  const location = useLocation();
  //let from;
  //if (user?.workspace?.firstlogin) from = '/setup';
  //else
  const from =
    (location.state as StateRedirectAuth)?.from?.pathname || '/app/projects';

  if (!loading && loggedIn) {
    return <Navigate to={from} replace />;
  }

  return <Outlet />;
}

export function NotLoggedInShell({ blank }: { blank?: boolean }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        backgroundColor: blank ? 'white.main' : 'secondary.main',
        backgroundImage: blank ? '' : `url(${Duck})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 130%',
      }}
    >
      <Box>
        <Header />
      </Box>
      <Outlet />
    </Box>
  );
}

export default NotLoggedInShell;
