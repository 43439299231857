import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

export interface PlaceholderTextProps {
  text: string;
}

export function PlaceholderText(props: PropsWithChildren<unknown>) {
  return (
    <Typography
      variant="body1"
      sx={{ color: 'gray.main' }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: '50px',
      }}
    >
      {props.children}
    </Typography>
  );
}
