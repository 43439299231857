import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useNewBrand } from '../data-access/new-brand.hook';
import { NewBrandDto } from '@docpack/shared/dtos';
import { BrandsListPageOutletContext } from './brands-list-page';
import {
  UploadFile,
  InputText,
  WhiteBoxModalContainer,
  useMessagePopup,
  useHandleClose,
  useFormMethods,
} from '@docpack/frontend/core-components';

export function AddBrandPage() {
  const navigate = useNavigate();
  const { newBrand } = useNewBrand();
  const outletContext = useOutletContext<BrandsListPageOutletContext>();
  const formMethods = useFormMethods(NewBrandDto);
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      data-testid="add-brand-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={500}>
        <Typography
          variant="h3"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Nuovo marchio
        </Typography>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit((values: NewBrandDto) =>
              newBrand(values)
                .then(() => {
                  if (outletContext?.reloadBrands) outletContext.reloadBrands();
                })
                .then(() => outletContext?.addBrandCallback())
                .then(() => {
                  navigate(`..`);
                })
                .then(() => {
                  showMessage('Marchio creato con successo!', 'success');
                })
                .catch((error) => {
                  console.error('Error fetching data: ', error);
                })
            )}
          >
            <Stack spacing={2}>
              <InputText
                label="Nome"
                name="name"
                required
                tooltipText="Inserendo il nome di un marchio ed eventualmente la sua immagine potrai decidere di abbinare i progetti al marchio di riferimento. Questa soluzione ti permette di agevolare la ricerca di un progetto"
                cy="brand-name-input"
              />
              {formMethods.formState.errors.name?.message ? (
                <Alert severity="error">
                  <Typography>
                    {formMethods.formState.errors.name?.message}
                  </Typography>
                </Alert>
              ) : null}
              <UploadFile
                label="Logo"
                name="asset_uuid"
                accept="image/*"
                required
                tooltipText="Se lo desideri puoi caricare l’immagine del marchio. Formato file supportati: .jpg, .png. Dimensioni massime 1Mb"
                cy="brand-logo-input"
              />
              {formMethods.formState.errors.asset_uuid?.message ? (
                <Alert severity="error">
                  <Typography>
                    {formMethods.formState.errors.asset_uuid?.message}
                  </Typography>
                </Alert>
              ) : null}
            </Stack>
            <Grid container sx={{ mt: '48px !important' }}>
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Button variant="text" onClick={() => handleClose()}>
                  Annulla
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  data-testid="add-brand-submit-button"
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default AddBrandPage;
