import { Route, Routes } from 'react-router-dom';
import { PlanSelectionPage } from './pages';

export function PlanSelectionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PlanSelectionPage />} />
    </Routes>
  );
}
