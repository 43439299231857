import { IsOptional, IsString } from 'class-validator';

export class NewSupplierDto {
  @IsString({ message: 'Fornire un titolo' })
  name: string;

  @IsString({ message: 'Fornire un codice' })
  @IsOptional()
  code: string;
}
