import {
  AddButton,
  AlertController,
  DotsMenu,
  PlaceholderText,
  RowDetails,
  SearchBar,
  SelectOrderContainer,
  Toolbar,
  ToolbarLeftContainer,
  useGetItemsSelected,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Link, Outlet } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ContactItem from '../components/contact-item';
import Grid from '@mui/material/Grid';
import {
  ContactDto,
  OrderByEnum,
  SearchContactsQueryDto,
} from '@docpack/shared/dtos';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { KeyedMutator } from 'swr';
import { useGetContacts } from '../data-access/get-contacts.hook';
import { useDeleteContact } from '../data-access/delete-contact.hook';
import { unstable_batchedUpdates } from 'react-dom';
import { useForm } from 'react-hook-form';
import { RowHeader } from '@docpack/frontend/core-components';
import { customColumnsSizes } from '../components/contact-item';
export interface ContactListContextInterface {
  reloadContacts: KeyedMutator<ContactDto[]>;
}

export const ContactsPage = () => {
  const formMethods = useForm();
  const searchText = formMethods.watch('search_text');
  const {
    itemsSelected,
    toggleItemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  } = useGetItemsSelected();
  const [order, setOrder] = useState<SearchContactsQueryDto['orderBy']>(
    OrderByEnum['a-z']
  );
  const { data, mutate, error } = useGetContacts({
    query: searchText || '',
    orderBy: order,
  });
  const { deleteContact, error: errorDelete } = useDeleteContact();

  const showMessage = useMessagePopup();

  const allContactsIds = useMemo(() => {
    return data?.map((contact) => contact.contact_uuid);
  }, [data]);

  return (
    <>
      <SearchBar formMethods={formMethods} hideDatesSearch removeSelect />
      <AlertController
        message={
          error || errorDelete
            ? { type: 'error', text: (error || errorDelete) as string }
            : undefined
        }
      />
      <Toolbar itemsSelectedCount={itemsSelected.length}>
        <ToolbarLeftContainer>
          <AddButton linkTo={'new'} label={'Inserisci nuovo contatto'} />
        </ToolbarLeftContainer>
        <SelectOrderContainer>
          <Select
            value={order}
            onChange={(e) =>
              setOrder(e.target.value as SearchContactsQueryDto['orderBy'])
            }
          >
            <MenuItem value={'a-z'}>A-Z</MenuItem>
            <MenuItem value={'z-a'}>Z-A</MenuItem>
          </Select>
        </SelectOrderContainer>
        <Stack direction="row" spacing={3}>
          {itemsSelected.length === 1 && (
            <IconButton
              color="primary"
              component={Link}
              to={`edit/${itemsSelected[0]}`}
            >
              <EditIcon fontSize={'medium'} />
            </IconButton>
          )}
          {itemsSelected.length >= 1 && (
            <IconButton
              color="primary"
              onClick={() => {
                if (
                  window.confirm(
                    `Sei sicuro di voler eliminare ${itemsSelected.length} utenti?`
                  )
                ) {
                  Promise.all(
                    itemsSelected.map((contact_uuid) =>
                      deleteContact(contact_uuid).then(() => contact_uuid)
                    )
                  ).then((ids) => {
                    unstable_batchedUpdates(() => {
                      ids.forEach((id) => removeItemsSelected(id));
                    });
                    mutate();
                    showMessage('Contatti eliminati con successo', 'success');
                  });
                }
              }}
            >
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(allContactsIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Stack>
        {data?.length && (
          <RowHeader
            headerListing={['Email', 'Nome Azienda', 'Cellulare']}
            customColumnsSizes={customColumnsSizes}
          />
        )}
        {data && data?.length > 0 ? (
          <Grid container columnSpacing={2} rowSpacing={1}>
            {data.map((contact) => (
              <Grid item xs={12} md={12} lg={12} key={contact.contact_uuid}>
                <ContactItem
                  contact={contact}
                  selected={isSelected((item) => item === contact.contact_uuid)}
                  onClick={() => toggleItemsSelected(contact.contact_uuid)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <PlaceholderText>
            Clicca sul bottone
            <br />
            per aggiungere il tuo primo contatto
          </PlaceholderText>
        )}
      </Stack>
      <Outlet
        context={{ reloadContacts: mutate } as ContactListContextInterface}
      />
    </>
  );
};

export default ContactsPage;
