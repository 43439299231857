import {
  AlertController,
  useMessagePopup,
  useShowMessage,
  WhiteBox,
} from '@docpack/frontend/core-components';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PaymentInfoFormColumns as PaymentInfoForm } from '../components';
import { usePaymentInfoHook, useStartPaymentProcess } from '../data-access';

import { useNavigate } from 'react-router-dom';
import PlanStatus from '../components/plan-status';
import PlanPayments from '../components/plan-payments';

export function PaymentSettingsPage() {
  const { updatePaymentInfo, paymentInfoResult, planData, cardData } =
    usePaymentInfoHook();
  const messageController = useShowMessage();

  const { resultCanPay, openClientPortal } = useStartPaymentProcess();

  const showMessage = useMessagePopup();

  const navigate = useNavigate();

  return (
    <>
      <AlertController
        message={messageController.message}
        hasClosed={messageController.clearMessage}
      />
      <Container>
        <Grid
          container
          sx={{ marginTop: { md: 4 } }}
          justifyContent={'space-between'}
        >
          <Grid
            item
            xs={7}
            sx={{
              borderWidth: '1px',
              paddingLeft: '0 !important',
              paddingTop: '0 !important',
              borderStyle: 'solid',
              borderColor: 'primary.light',
              alignContent: 'center',
              borderRadius: '20px',
            }}
          >
            <Stack padding={4}>
              <Typography variant="h3" mb={5}>
                Dati società
              </Typography>
              {paymentInfoResult.data && (
                <PaymentInfoForm
                  onSubmit={(data) =>
                    updatePaymentInfo(data)
                      .then((success) => {
                        resultCanPay.mutate();
                        success
                          ? showMessage(
                              'Dati società aggiornati con successo',
                              'success'
                            )
                          : messageController.showMessage({
                              type: 'error',
                              text: "Errore durante l'aggiornamento dei dati società",
                            });
                      })
                      .catch((error) =>
                        messageController.showMessage({
                          type: 'error',
                          text: error.message,
                        })
                      )
                  }
                  defautlValues={paymentInfoResult.data}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={4}>
              <PlanStatus
                planData={planData}
                changePlan={() => {
                  navigate('/app/plan-selection');
                }}
                unsubscribePlan={() => {
                  openClientPortal('subscription_cancel') //TODO: fallo dinamico
                    .then((result) =>
                      result?.paymentStartFlowUri
                        ? (window.location.href = result.paymentStartFlowUri)
                        : messageController.showMessage({
                            type: 'error',
                            text: 'Impossibile recuperare il link di pagamento, contattare il supporto',
                          })
                    )
                    .catch((error) => {
                      messageController.showMessage({
                        type: 'error',
                        text: error.message,
                      });
                    });
                }}
              />
              <PlanPayments
                cardData={cardData}
                handlePayment={() => {
                  //getUrlPaymentProcess()
                  openClientPortal() //TODO: fallo dinamico
                    .then((result) =>
                      result?.paymentStartFlowUri
                        ? (window.location.href = result.paymentStartFlowUri)
                        : messageController.showMessage({
                            type: 'error',
                            text: 'Impossibile recuperare il link di pagamento, contattare il supporto',
                          })
                    )
                    .catch((error) => {
                      messageController.showMessage({
                        type: 'error',
                        text: error.message,
                      });
                    });
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
