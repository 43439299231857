import {
  InfoTooltip,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGetNotes } from '../data-access/get-notes.hook';
import { useUpdateNote } from '../data-access/update-note.hook';
import NoteItem from './note-item';
import { useState } from 'react';
import Stack from '@mui/material/Stack';

export const CustomizedNotes = () => {
  const { updateNote, newNote } = useUpdateNote();
  const showMessage = useMessagePopup();
  const [openTab, setOpenTab] = useState(-1);
  const { data, mutate } = useGetNotes();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        mb={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body3"
            sx={{
              letterSpacing: '0.48px',
              mr: 1,
            }}
          >
            Messaggi precompilati
          </Typography>
          <InfoTooltip text="Crea o modifica i messaggi precompilati che verrano visualizzati e selezionabili nella finestra per inviare il progetto" />
        </Stack>
        <Button
          disabled={openTab > -1 ? true : false}
          data-testid="settings-new-note"
          onClick={() => {
            newNote({ title: '', text: '' })
              .then((result) => {
                mutate();
              })
              .then((result) => {
                setOpenTab(0);
              })
              .catch((error) => {
                console.error(error);
              });
          }}
          sx={{
            padding: { xs: '4px 15px' },
            maxWidth: ['250px', '100%'],
            marginTop: ['20px', '0'],
            backgroundColor: 'secondary.main',
            borderRadius: '27px',
            transition: '0.3s',
            ':hover': {
              opacity: '0.5',
              backgroundColor: 'secondary.main',
            },
            ':disabled': {
              backgroundColor: 'gray.main',
            },
          }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.964 7.995a.912.912 0 0 1-.613.286l-4.09.003v3.925a1.019 1.019 0 0 1-.32.66 1.379 1.379 0 0 1-1.006.39c-.51.002-.82-.194-.985-.357a.907.907 0 0 1-.301-.626l-.001-3.993-3.889.002C1.406 8.284.71 7.882.711 6.959c0-.521.202-.831.372-1.002.212-.212.454-.287.612-.284l3.953-.003V1.742c.022-.252.136-.487.322-.66A1.376 1.376 0 0 1 6.977.691c.877.001 1.288.588 1.287.983v3.997h4.021c.157 0 .424.085.66.321a1.366 1.366 0 0 1 .39 1.004c0 .52-.201.83-.371 1z"
              fill="#fff"
            />
          </svg>
          <Typography
            sx={{
              paddingLeft: '11px',
              color: '#fff',
              lineHeight: '20px',
              letterSpacing: '0.36px',
            }}
            variant="caption"
          >
            Crea nuovo
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        {data?.length
          ? data?.map((item, index) => {
              return (
                <NoteItem
                  key={item.user_notes_uuid}
                  data={item}
                  index={index}
                  openTab={openTab}
                  afterDelete={() => mutate()}
                  onClick={() => setOpenTab(index)}
                  onClose={() => setOpenTab(-1)}
                  onSubmit={(values) =>
                    updateNote(values)
                      .then((result) => {
                        showMessage('Nota aggiornata con successo!', 'success');
                        mutate();
                        return result;
                      })
                      .then(() => setOpenTab(-1))
                      .catch((error) => {
                        console.error(error);
                      })
                  }
                />
              );
            })
          : false}
      </Grid>
    </Grid>
  );
};

export default CustomizedNotes;
