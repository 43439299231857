import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import { useOptionsBrands } from '../data-access';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Theme, SxProps } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { ReactNode, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import { InfoTooltip } from '../components';

export interface SelectBrandsInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  placeholderItem?: string;
  sx?: SxProps<Theme> | undefined;
  small?: boolean;
  multyselect?: boolean;
  hasSelectAll?: boolean;
  tooltipText?: string;
  cy?: string;
}

export function SelectBrandsInput(props: SelectBrandsInputProps) {
  const isMultyselect =
    typeof props.multyselect !== 'boolean' ? true : props.multyselect;

  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
    defaultValue: props.defaultValue || (isMultyselect ? [] : ''),
  });
  const { data, error } = useOptionsBrands();
  const loading = !data || !!error;

  const renderValue = isMultyselect
    ? (selected: string | Array<string>): ReactNode => {
        if (Array.isArray(selected)) {
          return data
            ?.filter((brand) => selected.find((id) => id === brand.value))
            .map((brand) => brand.label)
            .join(', ');
        } else {
          return (
            data?.find((brand) => brand.value === selected)?.value || selected
          );
        }
      }
    : undefined;

  const hasAllSelectedAlready = field.value?.length === data?.length;

  // Override the field onChange to support the "select all" functionality
  const overrideOnchange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selected = event.target.value;
      if (
        isMultyselect &&
        props.hasSelectAll &&
        data &&
        // The user has clicked the select all checkbox
        selected.includes('all')
      ) {
        if (hasAllSelectedAlready) {
          field.onChange([]);
        } else {
          field.onChange(data.map((option) => option.value));
        }
      } else {
        field.onChange(event);
      }
    },
    [data, field, isMultyselect, props.hasSelectAll, hasAllSelectedAlready]
  );

  // Create the options depending on the aviable brands
  let optionsComponents =
    data?.map((options) =>
      // Use the checkbox if is multyselect
      isMultyselect ? (
        <MenuItem
          key={options.value}
          value={options.value}
          data-testid="select-brands-option-multiple"
        >
          <Checkbox
            checked={field.value?.indexOf(options.value) > -1}
            color="warning"
          />
          <ListItemText primary={options.label} />
        </MenuItem>
      ) : (
        // Use the default text if not
        <MenuItem
          key={options.value}
          value={options.value}
          data-testid="select-brands-option-single"
        >
          {options.label}
        </MenuItem>
      )
    ) || [];

  if (isMultyselect && props.hasSelectAll && data) {
    // Add the select all options if is needed
    optionsComponents = [
      <MenuItem
        key={'select-deselect-all-unique-key'}
        value="all"
        data-testid="select-brands-option-multiple"
      >
        <Checkbox checked={hasAllSelectedAlready} color="warning" />
        <ListItemText primary="Select All" />
      </MenuItem>,
      ...optionsComponents,
    ];
  }

  return (
    <Stack>
      {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
      <FormControl sx={{ width: '100%' }}>
        {props.label && (
          <InputLabel id={`select-${props.name}`}>{props.label}</InputLabel>
        )}
        <Select
          labelId={`select-${props.name}`}
          value={field.value}
          label={props.label}
          displayEmpty
          error={!!fieldState.error}
          disabled={props.disabled}
          multiple={isMultyselect}
          defaultValue={props.defaultValue}
          onChange={overrideOnchange}
          sx={props.sx}
          size={props.small ? 'small' : 'medium'}
          renderValue={renderValue}
          data-testid={props.cy}
        >
          {props.placeholderItem && (
            <MenuItem value="" data-testid="select-brands-option-single">
              {props.placeholderItem}
            </MenuItem>
          )}
          {loading ? (
            <Stack spacing={1} padding={2}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          ) : (
            optionsComponents
          )}
        </Select>
        {fieldState.error?.message && (
          <Alert severity="error">
            <Typography>{fieldState.error?.message}</Typography>
          </Alert>
        )}
      </FormControl>
    </Stack>
  );
}
