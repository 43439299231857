import useSWRInfinite from 'swr/infinite';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { SupplierDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { useInfiniteScrollUtils } from '@docpack/frontend/core-components';

export function useGetSuppliers() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);
  const params = new URLSearchParams();

  const result = useSWRInfinite<Array<SupplierDto>>((index) => {
    params.set('page', index.toString());
    return `/api/suppliers?${params}`;
  }, fetcher);

  const resultUtils = useInfiniteScrollUtils(result, handle);

  return {
    ...result,
    ...resultUtils,
    error,
  };
}
