import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { BrandDto, UpdateBrandDto, UpgradePlanDto } from '@docpack/shared/dtos';
import useSwr from 'swr';

export function useCheckUser() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<UpgradePlanDto>(`/api/user/can-create`, fetcher);

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
export function useCheckBrand() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<UpgradePlanDto>(`/api/brand/can-create`, fetcher);

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
