export enum UserRole {
  'Master' = 'Master',
  'Amministratore' = 'Amministratore',
  'Basic' = 'Basic',
}
export enum UserType {
  'Interno' = 'Interno',
  'Esterno' = 'Esterno',
}

export enum UserPermission {
  'Caricare' = 'Caricare',
  'Scaricare' = 'Scaricare',
  'NuovoProgetto' = 'NuovoProgetto',
  'Report' = 'Report',
  'Fatturazione' = 'Fatturazione',
}
