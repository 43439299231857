import { WhiteBoxModalContainer } from '../white-box-modal-container/white-box-modal-container';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

export function PlanErrorModal(props: {
  requiredPlan: string;
  onClose: Function;
  isOpen: boolean;
}) {
  const outletContext = useOutletContext<any>();
  const navigate = useNavigate();

  return (
    <Modal open={props.isOpen} data-testid="plan-error-modal">
      <WhiteBoxModalContainer whiteBoxWith={400}>
        <Typography
          variant="h3"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'center', color: 'red' } }}
        >
          {props?.requiredPlan != 'Unavailable'
            ? 'Upgrade Richiesto'
            : 'Feature non disponibile'}
        </Typography>
        {props?.requiredPlan != 'Unavailable' ? (
          <>
            <Typography sx={{ textAlign: 'center' }}>
              Per proseguire con questa funzione devi aggiornare il tuo piano a:
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', textAlign: 'center' }}
              variant="h6"
            >
              {props?.requiredPlan}
            </Typography>
          </>
        ) : (
          <Typography>Hai raggiunto il limite di questa feature!</Typography>
        )}
        <Grid container sx={{ mt: '48px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="outlined" onClick={() => props.onClose()}>
              Indietro
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={() => navigate(`/app/payments`)}
            >
              Cambia Piano
            </Button>
          </Grid>
        </Grid>
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default PlanErrorModal;
