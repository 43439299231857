import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { InfoTooltip } from '../components';

export interface RadioGroupCustomProps {
  name: string;
  label: string;
  required?: boolean;
  options: Array<{ label: string; value: string; disabled?: boolean }>;
  disabled?: boolean;
  tooltipText?: string;
  cy?: string;
}

export function RadioGroupCustom(props: RadioGroupCustomProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  return (
    <>
      <FormControl>
        <Stack direction="row" justifyContent="space-between">
          <FormLabel id={props.name}>{props.label}</FormLabel>
          {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
        </Stack>
        <RadioGroup
          row
          aria-labelledby={props.name}
          name={props.name}
          onChange={field.onChange}
          value={field.value ?? ''}
        >
          {props.options.map((index) => (
            <FormControlLabel
              key={index.value}
              value={index.value}
              control={
                <Radio
                  color="warning"
                  disabled={index.disabled || props.disabled}
                  data-testid={props.cy}
                />
              }
              disabled={index.disabled || props.disabled}
              label={index.label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
