import { Route, Routes } from 'react-router-dom';
import { PaymentSettingsPage } from './pages';

export function PaymentsFeatureRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PaymentSettingsPage />} />
    </Routes>
  );
}
