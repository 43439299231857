import { Typography, useThemeProps } from '@mui/material';
import { PlanDataDto } from '@docpack/shared/dtos';
import { Configuration } from '@docpack/frontend/registration-feature';

export function OldPlanData(planData: PlanDataDto) {
  const statusMap = {
    active: 'Attivo',
    trialing: 'In prova',
    canceled: 'Cancellato',
  };

  return (
    <>
      <Typography>
        Piano attuale: {planData?.name} - Stato:{' '}
        {statusMap[planData.status as keyof typeof statusMap]}
      </Typography>
      {planData?.status != 'active' && planData?.status != 'trialing' ? (
        <Typography>
          Scaduto: {new Date(planData?.canceled_at || '').toLocaleDateString()}
        </Typography>
      ) : (
        <Typography>
          Scadenza:{' '}
          {new Date(planData?.current_period_end).toLocaleDateString()}
        </Typography>
      )}
    </>
  );
}

export function PlanData(planData: PlanDataDto) {
  //TODO: non funziona :'(
  console.log(planData);
  const statusMap = {
    active: 'Attivo',
    trialing: 'In prova',
    canceled: 'Cancellato',
  };

  const one: Configuration = {
    title: 'ONE',
    price: 180.0,
    description:
      'Il piano ideale per un singolo utente.\nTi permette di archiviare fino a 100 progetti.',
    features: [
      '1 Account',
      '100 Gb di archiviazione',
      '100 progetti',
      'Marchi',
      'Categorie e sottocategorie',
      'Fornitori',
      'Report attività',
    ],
  };
  const team: Configuration = {
    title: 'TEAM',
    price: 240.0,
    description:
      'Tutte le funzionalità del piano One con la possibilità di aggiungere fino a 5 collaboratori alla piattaforma.',
    features: [
      '5 Account',
      '500 Gb di archiviazione',
      '300 progetti',
      'Tutte le funzionalità del piano Basic',
    ],
  };
  const business: Configuration = {
    title: 'BUSINESS',
    price: 500.0,
    description:
      'Ideale per una gestione del progetto a un team esteso anche a società esterne.',
    features: [
      '40 Account',
      '2 Tb di spazio di archiviazione',
      '500 progetti',
      'Possibilità di associare fino a 5 marchi a società esterne',
      'Tutte le funzionalità del piano Basic',
    ],
  };

  const unlimited: Configuration = {
    title: 'UNLIMITED',
    price: 720.0,
    description:
      'Ideale per una gestione del progetto per società che hanno bisogno di un uso illimitato di risorse',
    features: [
      'Account illimitati',
      '10 Tb di spazio di archiviazione',
      'Numero progetti illimitati',
      'Numero marchi illimitati',
      'Tutte le funzionalità del piano Basic',
    ],
  };

  const configurations: any = {
    One: one,
    Team: team,
    Business: business,
    Unlimited: unlimited,
  };

  return (
    <>
      <Typography variant={'h5'}>Dettagli Piano</Typography>
      <Typography variant={'h5'}>
        Piano {configurations[planData.name]?.title} -{' '}
        {statusMap[planData.status as keyof typeof statusMap]}
      </Typography>
      {configurations[planData.name]?.features.map((val: string) => {
        return <Typography>{val}</Typography>;
      })}
    </>
  );
}
