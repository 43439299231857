import { SelectBrandsInput } from '@docpack/frontend/core-components';
import { ProjectMigrationDto } from '@docpack/shared/dtos';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { FormProvider, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
export interface MoveProjectFormProps {
  handleClose: () => void;
  onSubmit: (val: ProjectMigrationDto) => Promise<unknown>;
  defaultValues?: Partial<ProjectMigrationDto>;
}

export function MoveProjectForm(props: MoveProjectFormProps) {
  const resolver = classValidatorResolver(ProjectMigrationDto);
  const formMethods = useForm<ProjectMigrationDto>({
    resolver,
    defaultValues: props.defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={3}>
          <SelectBrandsInput
            name="brands"
            label="Seleziona il marchio"
            hasSelectAll
          />
        </Stack>

        <Grid container sx={{ mt: '48px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              disabled={formMethods.formState.isSubmitting}
            >
              Conferma
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default MoveProjectForm;
