// This is here because of this https://github.com/tinacms/tinacms/issues/277
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'react-dom/node_modules/@types/react';

import { useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@mui/material/Grid';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import {
  DateInput,
  InputText,
  SelectBrandsInput,
  SelectUserTypeInput,
  SingleSelect,
} from '../../form-inputs';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import { useOptionsSubcategories } from '../../data-access/options-subcategories.hook';
import { useOptionsCategories } from '../../data-access/options-categories.hook';
import { useOptionsSuppliers } from '../../data-access/options-suppliers.hook';
import { useWorkspaceSettings } from '../../data-access';

/* eslint-disable-next-line */
export interface SearchBarProps {
  selectOptions?: 'brands' | 'user-type';
  formMethods: UseFormReturn<FieldValues, object>;
  removeSelect?: boolean;
  hideDatesSearch?: boolean;
  projectsCount?: number;
  categoriesSelect?: boolean;
  suppliersSelect?: boolean;
  container?: string;
}

export function SearchBar(props: SearchBarProps) {
  const [showSearch, setShowSearch] = useState(false);
  const container = document.querySelector('[data-portal="search-bar"]');
  const theme = useTheme();
  const mediaQueryMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const watchCategoryUuid = props.formMethods.watch('search_category');
  const { data: optionsSuppliers } = useOptionsSuppliers();
  const { data: optionsCategories } = useOptionsCategories();
  const { data: optionsSubCategories } = useOptionsSubcategories(
    watchCategoryUuid || ''
  );
  const { settings } = useWorkspaceSettings();

  if (!container) return null;

  return ReactDOM.createPortal(
    <FormProvider {...props.formMethods}>
      <form style={{ width: '100%' }}>
        <Grid container alignItems="center" flexWrap="nowrap">
          {!mediaQueryMdDown && !props.removeSelect && (
            <Grid
              container
              item
              alignItems="center"
              sx={{
                maxWidth: { xs: showSearch ? '350px' : 'none', lg: 'none' },
                width: 'auto',
              }}
              flexWrap={{ xs: showSearch ? 'wrap' : 'nowrap', lg: 'nowrap' }}
            >
              {props.categoriesSelect && settings?.categories_and_subs_flag && (
                <Grid
                  item
                  sx={{
                    width: { xs: '100%', sm: '100%', md: 'auto' },
                    paddingTop: 1,
                    paddingBottom: 1,
                    marginRight: '1rem',
                    flex: { md: '1 1 calc(50% - 1rem)', lg: '1 1 auto' },
                    maxWidth: {
                      md: showSearch ? 'calc(50% - 1rem)' : '160px',
                      lg: '160px',
                    },
                  }}
                >
                  <SingleSelect
                    name="search_category"
                    placeholderItem="Categorie"
                    options={optionsCategories || []}
                    disabled={
                      !optionsCategories || optionsCategories.length === 0
                    }
                    small
                  />
                </Grid>
              )}
              {props.categoriesSelect && settings?.categories_and_subs_flag && (
                <Grid
                  item
                  sx={{
                    width: { xs: '100%', sm: '100%', md: 'auto' },
                    paddingTop: 1,
                    paddingBottom: 1,
                    marginRight: '1rem',
                    flex: { md: '1 1 calc(50% - 1rem)', lg: '1 1 auto' },
                    maxWidth: {
                      md: showSearch ? 'calc(50% - 1rem)' : '160px',
                      lg: '160px',
                    },
                  }}
                >
                  <SingleSelect
                    name="search_sub_category"
                    placeholderItem="Sottocategorie"
                    options={optionsSubCategories || []}
                    disabled={
                      !optionsSubCategories || optionsSubCategories.length === 0
                    }
                    small
                  />
                </Grid>
              )}

              {props.suppliersSelect && settings?.suppliers_flag && (
                <Grid
                  item
                  sx={{
                    width: { xs: '100%', sm: '100%', md: 'auto' },
                    paddingTop: 1,
                    paddingBottom: 1,
                    marginRight: '1rem',
                    flex: { md: '1 1 calc(50% - 1rem)', lg: '1 1 auto' },
                    maxWidth: {
                      md: showSearch ? 'calc(50% - 1rem)' : '160px',
                      lg: '160px',
                    },
                  }}
                >
                  <SingleSelect
                    name="search_supplier"
                    placeholderItem="Fornitori"
                    options={optionsSuppliers || []}
                    disabled={
                      !optionsSuppliers || optionsSuppliers.length === 0
                    }
                    small
                  />
                </Grid>
              )}

              <Grid
                item
                sx={{
                  width: { xs: '100%', sm: '100%', md: 'auto' },
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginRight: { xs: '1rem', lg: '0' },
                  flex: { md: '1 1 calc(50% - 1rem)', lg: '1 1 auto' },
                  maxWidth: {
                    md: showSearch ? 'calc(50% - 1rem)' : '160px',
                    lg: '160px',
                  },
                }}
              >
                {props.selectOptions === 'brands' ? (
                  <SelectBrandsInput
                    name="brand"
                    placeholderItem="Marchi"
                    multyselect={false}
                    sx={{
                      width: '100%',
                    }}
                    small
                    cy="search-select-brands-input"
                  />
                ) : (
                  <SelectUserTypeInput
                    name="user_type"
                    placeholderItem="Tutti"
                    sx={{
                      width: '100%',
                    }}
                    small
                  />
                )}
              </Grid>
            </Grid>
          )}
          {!showSearch && (
            <SearchIcon
              fontSize="medium"
              onClick={() => {
                setShowSearch(!showSearch);
              }}
              sx={{ cursor: 'pointer', ml: { xs: 2, md: 3 } }}
              data-testid="searchbar-search-icon"
            />
          )}
          <Grid
            container
            alignItems="center"
            item
            sx={{ maxWidth: { xs: '320px', lg: 'none' } }}
          >
            {showSearch && (
              <InputText
                name="search_text"
                search
                small
                sx={{
                  position: { xs: 'absolute', md: 'static' },
                  right: { xs: '55px', md: 'unset' },
                  width: {
                    xs: 'calc(100vw - 200px)',
                    sm: 'calc(100vw - 210px)',
                    md: '150px',
                  },
                  flex: { xs: '1 1 100%', lg: '2 1' },
                  marginLeft: { xs: '8px !important', lg: '32px !important' },
                }}
                searchSelectExists={!!props.selectOptions}
                rightIcons={
                  <ClearIcon
                    fontSize="small"
                    onClick={() => {
                      setShowSearch(!showSearch);
                      // If is closing
                      if (showSearch) {
                        // Reset search text and dates filter
                        props.formMethods.resetField('search_text');
                        props.formMethods.resetField('date_start');
                        props.formMethods.resetField('date_end');
                      }
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                }
                cy="searchbar-search-input"
              />
            )}
            {!props.hideDatesSearch && showSearch && !mediaQueryMdDown && (
              <Stack
                direction="row"
                justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
                sx={{
                  paddingTop: { xs: 2, lg: 1 },
                  paddingBottom: { xs: 0, lg: 1 },
                  width: '100%',
                  flex: { md: '1 1 100%', lg: '1 0 220px' },
                }}
              >
                <>
                  <DateInput
                    name="date_start"
                    label="Dal"
                    sx={{
                      marginLeft: { xs: 0, sm: 0, md: 1 },
                      maxWidth: {
                        xs: 'auto',
                        sm: 'auto',
                        lg: 'auto',
                      },
                      width: {
                        xs: 'auto',
                        sm: 'auto',
                        lg: '205px',
                      },
                      backgroundColor: '#fff',
                      borderRadius: '4px',
                    }}
                    defaultValue={null}
                    small
                  />
                  <DateInput
                    name="date_end"
                    label="Al"
                    sx={{
                      marginLeft: 1,
                      maxWidth: {
                        xs: 'auto',
                        sm: 'auto',
                        lg: '210px',
                      },
                      backgroundColor: '#fff',
                      borderRadius: '4px',
                    }}
                    defaultValue={null}
                    small
                  />
                </>
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>,
    container
  );
}

export default SearchBar;
