import {
  ArrayMinSize,
  IsArray,
  IsEmail,
  IsEnum,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { AssetsDownloadTypes } from './download-projects-query.dto';

export class ShareMultipleProjectsDto {
  @IsArray()
  @IsUUID('all', { each: true })
  @ArrayMinSize(1, {
    message: 'Seleziona almeno una progetto da condividere',
  })
  projects: Array<string>;

  @IsArray()
  @ArrayMinSize(1, {
    message: 'Seleziona almeno una tiplogia di file da condividere',
  })
  @IsEnum(AssetsDownloadTypes, { each: true })
  types: Array<AssetsDownloadTypes>;

  @IsArray()
  @IsEmail({}, { message: 'Una delle email fornite non è valida', each: true })
  @ArrayMinSize(1, {
    message: 'Inserisci almeno una email',
  })
  recipient: Array<string>;

  @IsArray()
  @IsEmail(
    {},
    {
      message: 'Una delle email fornite in CC non è valida',
      each: true,
    }
  )
  cc: Array<string>;

  @IsArray()
  @IsEmail(
    {},
    {
      message: 'Una delle email fornite in CCN non è valida',
      each: true,
    }
  )
  ccn: Array<string>;

  @IsString({ message: 'Inserire un oggetto' })
  subject: string;

  @IsOptional()
  @IsString()
  message?: string;
}
