import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UserDto } from '@docpack/shared/dtos';

export function useDeleteUser(api_key: string) {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);
  async function deleteUser(user_uuid: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('api_key', api_key);
    const result = await fetcher(
      `/api/platform-registration/${user_uuid}?${queryParams}`,
      {
        method: 'DELETE',
        body: {},
      }
    );

    return handle<UserDto>(result);
  }

  return {
    error,
    deleteUser,
  };
}
