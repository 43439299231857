import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { InitResetPasswordDto, ResetPasswordDto } from '@docpack/shared/dtos';

export function useResetPassword() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function initResetPassword(data: InitResetPasswordDto) {
    const result = await fetcher(`/api/user/start-reset-password`, {
      method: 'POST',
      body: data,
    });

    return handle(result);
  }

  async function resetPassword(data: ResetPasswordDto) {
    const result = await fetcher(`/api/user/reset-password`, {
      method: 'POST',
      body: data,
    });

    return handle(result);
  }

  return {
    error,
    initResetPassword,
    resetPassword,
  };
}
