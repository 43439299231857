import useSWRInfinite from 'swr/infinite';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import {
  PaginatedProjectsDto,
  ProjectDto,
  SearchProjectsDto,
} from '@docpack/shared/dtos';
import { useInfiniteScrollUtils } from '@docpack/frontend/core-components';

export function useGetProjects(params: SearchProjectsDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.set('search', params.search);
  }
  if (params.search_brand) {
    queryParams.set('search_brand', params.search_brand);
  }
  if (params.search_category) {
    queryParams.set('search_category', params.search_category);
  }
  if (params.search_sub_category) {
    queryParams.set('search_sub_category', params.search_sub_category);
  }
  if (params.search_date_from) {
    queryParams.set('search_date_from', params.search_date_from.toString());
  }
  if (params.search_date_to) {
    queryParams.set('search_date_to', params.search_date_to.toString());
  }
  if (params.orderBy) {
    queryParams.set('orderBy', params.orderBy);
  }
  if (params.search_supplier) {
    queryParams.set('search_supplier', params.search_supplier);
  }

  const result = useSWRInfinite((index) => {
    queryParams.set('page', index.toString());
    return `/api/project?${queryParams}`;
  }, fetcher);

  const resultUtils = useInfiniteScrollUtils<ProjectDto>(
    {
      mutate: result.mutate,
      size: result.size,
      data: result.data?.map((item) => item.projects),
      setSize: result.setSize,
      error: result.error,
      isValidating: result.isValidating,
    },
    handle
  );

  return {
    ...result,
    ...resultUtils,
    totalCount: (result.data as Array<PaginatedProjectsDto>)?.[0]?.count,
    error,
  };
}
