import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAcceptTerms } from './hook/accept-terms.hook';
import { AlertController } from '@docpack/frontend/core-components';
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';

export interface PrivacyAndTermsModalProps {
  title: string;
  type: 'privacy_policy' | 'terms_conditions';
  onAcceptTerms: () => void;
}

export const PrivacyAndTermsModal = (
  props: PropsWithChildren<PrivacyAndTermsModalProps>
) => {
  const { acceptTerms, error } = useAcceptTerms();

  return (
    <>
      <AlertController
        message={error ? { text: error, type: 'error' } : undefined}
      />
      <Modal open={true} aria-labelledby="parent-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white.main',
            maxWidth: 'calc(100% - 60px)',
            width: 550,
            p: '32px 64px 64px 64px',
            borderRadius: '24px',
            maxHeight: 'calc(100vh - 60px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h5"
            id="parent-modal-title"
            mb={6}
            textAlign="center"
            sx={{ marginRight: 'auto', marginLeft: 'auto', maxWidth: '220px' }}
          >
            {props.title}
          </Typography>
          <Stack
            sx={{ overflow: 'auto', maxHeight: '380px', marginBottom: '52px' }}
          >
            {props.children}
          </Stack>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon fontSize="medium" />}
            sx={{ display: 'flex', margin: 'auto auto 0 auto' }}
            onClick={() => {
              acceptTerms(props.type)
                .then(props.onAcceptTerms)
                .catch((error) => console.error(error));
            }}
          >
            Accetta e continua
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyAndTermsModal;
