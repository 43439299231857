import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { useCallback } from 'react';
import { SWRInfiniteResponse } from 'swr/infinite';

export function useInfiniteScrollUtils<Type, ErrorType = unknown>(
  result: SWRInfiniteResponse<Array<Type>, ErrorType>,
  handle: ReturnType<typeof useHandleRespError>['handle']
) {
  const data = result.data?.map(handle);

  const hasMore = data ? !!data[data.length - 1]?.length : false;

  const isLoadingInitialData = !result.data && !result.error;

  const isLoadingMore =
    isLoadingInitialData ||
    (result.data &&
      result.data.length > 0 &&
      typeof result.data[result.size - 1] === 'undefined');

  const flattenedData = data
    ? data.reduce((acc = [], items = []) => [...acc, ...items], [])
    : [];

  const nextPage = useCallback(
    () => (hasMore ? result.setSize((size) => size + 1) : null),
    [hasMore, result]
  );

  return {
    hasMore,
    isLoadingMore,
    data: flattenedData,
    nextPage,
  };
}
