import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useController, useForm } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import { DisableInteractivity, isAccetableFile } from './upload-file';
import { useHandleDrag } from '../hooks';
import Box from '@mui/material/Box';

export function ImageUploadAvatar(props: { color: 'white' | 'gray' | 'red' }) {
  const fill = useMemo(() => {
    if (props.color === 'gray') {
      return '#bcbfc2';
    }
    if (props.color === 'white') {
      return '#ffffff';
    }
    if (props.color === 'red') {
      return '#ce4525';
    }
    return 'black';
  }, [props.color]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill="none"
    >
      <path
        d="M4.457 13.214H7.41v9.643c0 1.06.836 1.929 1.857 1.929h7.429c1.021 0 1.857-.868 1.857-1.929v-9.643h2.953c1.653 0 2.488-2.083 1.318-3.298L14.3 1.064a1.847 1.847 0 0 0-.6-.417 1.79 1.79 0 0 0-1.418 0 1.847 1.847 0 0 0-.6.417L3.156 9.916c-1.17 1.215-.353 3.298 1.3 3.298zM0 30.571C0 31.632.836 32.5 1.857 32.5h22.286c1.021 0 1.857-.868 1.857-1.929 0-1.06-.836-1.928-1.857-1.928H1.857C.836 28.643 0 29.51 0 30.57z"
        fill={fill}
      />
    </svg>
  );
}

export function PreviewAvatar(props: { uri: string; alt: string }) {
  return (
    <img
      src={props.uri ? `${props.uri}?q=${Math.random()}` : ''}
      alt={props.alt}
      style={{
        width: '180px',
        height: '100px',
        marginBottom: '0px',
        objectFit: 'contain',
      }}
    />
  );
}

export interface UploadImageProps {
  name: string;
  required?: boolean;
  uploadFile: (file: File) => Promise<string | undefined>;
  error?: string;
  disabled?: boolean;
  label?: string;
  defaultValue?: string;
  deleteImage?: () => Promise<unknown>;
}

export function UploadImage(props: UploadImageProps) {
  const { setError } = useForm();
  const { fieldState, field } = useController({
    name: props.name,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const accept = 'image/*';

  const [loading, setLoading] = useState(false);
  const { handlerProps, dragging, draggedFileType } = useHandleDrag({
    onLeave: (files) => upload(files[0]),
  });

  const upload = (file?: File | null) => {
    if (file) {
      if (!isAccetableFile(file.type, accept)) {
        return;
      }
      setLoading(true);
      props
        .uploadFile(file)
        .then((res) => {
          setLoading(false);
          field.onChange(res);
        })
        .catch((error) => {
          setLoading(false);
          alert(`Unable to upload file: ${error.message}`);
        });
    }
  };

  useEffect(() => {
    if (props.error && fieldState.error?.message !== props.error) {
      setError(props.name, { message: props.error });
    }
  }, [props.error, fieldState.error?.message, props.name, setError]);

  const isAccettableFileTheOneOnDrag =
    dragging && isAccetableFile(draggedFileType, accept);

  return (
    <Stack sx={{ maxWidth: '100%', width: { sx: '100%', md: '200px' } }}>
      {props.label && <FormLabel id={props.name}>{props.label}</FormLabel>}
      {!loading ? (
        !field.value ? (
          <Stack
            sx={{
              borderRadius: '6px',
              border: '2px dashed',
              borderColor: dragging
                ? isAccettableFileTheOneOnDrag
                  ? 'gray.main'
                  : 'error.main'
                : 'gray.main',
              opacity: dragging ? 0.5 : 1,
              paddingTop: '15px',
              height: '100%',
              width: '100%',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            {...handlerProps}
            onClick={() =>
              !props.disabled ? inputRef.current?.click() : undefined
            }
          >
            <DisableInteractivity justifyContent="center" alignItems="center">
              {!props.disabled ? (
                <>
                  <ImageUploadAvatar
                    color={
                      dragging && !isAccettableFileTheOneOnDrag ? 'red' : 'gray'
                    }
                  />
                  {dragging ? (
                    <Typography
                      paragraph={true}
                      align="center"
                      color={
                        dragging
                          ? isAccettableFileTheOneOnDrag
                            ? undefined
                            : 'error.main'
                          : undefined
                      }
                    >
                      {isAccettableFileTheOneOnDrag
                        ? 'Rilascia per caricare'
                        : 'Formato non supportato'}
                    </Typography>
                  ) : (
                    <Typography paragraph={true} align="center">
                      Clicca o trascina <br />
                      l'imaggine qui <br />
                      1mB max
                    </Typography>
                  )}
                </>
              ) : (
                <Typography paragraph={true} align="center">
                  Upload disabilitato
                </Typography>
              )}
            </DisableInteractivity>
          </Stack>
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              position: 'relative',
              borderRadius: '6px',
              backgroundColor: 'secondary.main',
              padding: '32px',
              height: '100%',
              width: '100%',
              ':hover': {
                cursor: 'pointer',
              },
              ':hover > div': {
                opacity: 1,
                visibility: 'visible',
              },
            }}
            onClick={() =>
              !props.disabled ? inputRef.current?.click() : undefined
            }
          >
            {props.deleteImage && (
              <Box
                sx={{
                  opacity: 0,
                  visibility: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: '20px',
                  backgroundColor: 'rgba(247, 174, 110, 0.9);',
                  padding: '32px',
                  height: '100%',
                  width: '100%',
                  zIndex: 9,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm(`Sei sicuro di voler eliminare questo file?`)
                  ) {
                    props.deleteImage &&
                      props
                        .deleteImage()
                        .then(() => {
                          field.onChange(null);
                        })
                        .catch((error) => {
                          console.error('Error deleting asset: ', error);
                        });
                  }
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    padding: '6px 12px',
                    border: '1px solid #fff',
                    borderRadius: '8px',
                    color: '#fff',
                  }}
                >
                  Remove
                </Typography>
              </Box>
            )}
            <PreviewAvatar uri={field.value} alt={props.name} />
          </Stack>
        )
      ) : (
        <Stack sx={{ height: '200px' }}>
          <CircularProgress sx={{ margin: 'auto' }} />
        </Stack>
      )}
      <input
        type="file"
        ref={inputRef}
        accept={accept}
        onChange={() => upload(inputRef?.current?.files?.[0])}
        disabled={loading || props.disabled}
        style={{ visibility: 'hidden', position: 'absolute' }}
        required={props.required}
      />
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </Stack>
  );
}

export default UploadImage;
