import { IsOptional, IsString, Length } from 'class-validator';

export class NewBrandDto {
  @Length(3, 40, {
    message: 'Il nome di un brand deve essere compreso tra 3 e 40 caratteri',
  })
  name: string;

  @IsString()
  @IsOptional()
  asset_uuid?: string;
}
