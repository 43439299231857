import { Transform } from 'class-transformer';
import {
  IsArray,
  IsOptional,
  IsString,
  IsUUID,
  IsDateString,
  ArrayMinSize,
  IsNumberString,
  MaxLength,
} from 'class-validator';
import { formatNumberToBeThreeChar } from './new-project.dto';

export class UpdateProjectDto {
  @IsString()
  project_uuid: string;

  @MaxLength(120, {
    message: 'Il nome del progetto non deve superare i 120 caratteri',
  })
  @IsString({ message: 'Fornire un nome' })
  name: string;

  @IsNumberString({}, { message: 'Selezionare un anno' })
  project_code_year: string;

  @Transform((item) => formatNumberToBeThreeChar(item.value))
  @IsNumberString({}, { message: 'Il codice progetto deve essere numerico' })
  project_code_code: string;

  @IsString()
  project_code_sequential: string;

  @IsString()
  @IsOptional()
  ean?: string;

  @IsString()
  @IsOptional()
  internal_code?: string;

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  keywords?: Array<string>;

  @IsArray({ message: 'Seleziona un o piu marchi' })
  @ArrayMinSize(1, { message: 'Seleziona un o piu marchi' })
  @IsUUID(undefined, { message: 'Seleziona un o piu marchi', each: true })
  brand_uuids?: Array<string>;

  @IsUUID()
  @IsOptional()
  category_uuid?: string;

  @IsUUID()
  @IsOptional()
  sub_category_uuid?: string;

  @IsOptional()
  @IsUUID()
  supplier_uuid?: string;

  @IsOptional()
  @IsUUID()
  picture_asset_uuid?: string;

  @IsOptional()
  @IsUUID()
  layout_asset_uuid?: string;

  @IsOptional()
  @IsUUID()
  executive_asset_uuid?: string;

  @IsDateString({ message: 'Inserisci una data' })
  created_at: string;

  @IsString()
  @IsOptional()
  weight?: string;
}
