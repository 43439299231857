import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export interface InfoTooltipProps {
  text: string;
  alignVerticalTop?: boolean;
  alignVerticalCenter?: boolean;
  sx?: SxProps
}

export const InfoIcon = () => {
  return (
    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10z"
        stroke="#242628"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.545 4.5A1.5 1.5 0 0 1 7.46 5c0 1-1.5 1.5-1.5 1.5M6 8v.25"
        stroke="#242628"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement="bottom-end"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.gray.dark,
    boxShadow: '0 6px 8px 6px rgba(0, 0, 0, 0.25)',
    fontSize: 12,
    border: '1px solid #bcbfc2',
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: '#fff',
    lineHeight: 1.67,
    letterSpacing: '0.36px',
    fontWeight: 'normal',
  },
}));

export const InfoTooltip = (props: InfoTooltipProps) => {
  return (
    <CustomTooltip title={props.text}>
      <Button
        sx={{
          minWidth: 'unset',
          padding: 0,
          alignSelf: props.alignVerticalTop ? 'flex-start' : 'flex-end',
          marginBottom: '8px',
          ...props.sx,
        }}
      >
        <InfoIcon />
      </Button>
    </CustomTooltip>
  );
};

export default InfoTooltip;
