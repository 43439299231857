import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { SubCategoryDto, NewSubCategoryDto } from '@docpack/shared/dtos';

export function useNewSubcategory() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newSubcategory(data: NewSubCategoryDto) {
    const result = await fetcher(`/api/project/categories/new/sub-category`, {
      method: 'POST',
      body: data,
    });

    return handle<SubCategoryDto>(result);
  }

  return {
    error,
    newSubcategory,
  };
}
