import useSWRInfinite from 'swr/infinite';
import { useFetcher } from '@docpack/frontend/client-authorization';
import {
  CategoriesPaginatedDto,
  SearchCategoryAndSubCategoryDto,
  CategoryDto,
} from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { useInfiniteScrollUtils } from '@docpack/frontend/core-components';

export function useGetCategories(filter?: SearchCategoryAndSubCategoryDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const params = new URLSearchParams();

  if (filter?.fullTextSearch) {
    params.append('fullTextSearch', filter?.fullTextSearch);
  }

  const result = useSWRInfinite((index) => {
    params.set('page', index.toString());
    return `/api/project/categories?${params}`;
  }, fetcher);

  const resultUtils = useInfiniteScrollUtils<CategoryDto>(
    {
      mutate: result.mutate,
      size: result.size,
      data: result.data?.map((item) => item.data),
      setSize: result.setSize,
      error: result.error,
      isValidating: result.isValidating,
    },
    handle
  );

  return {
    ...result,
    ...resultUtils,
    totalCount: (result.data as Array<CategoriesPaginatedDto>)?.[0]?.total,
    error,
  };
}
