import { IsString, IsUUID, MinLength } from 'class-validator';

export class NewSubCategoryDto {
  @IsString()
  @MinLength(4)
  name: string;

  @IsUUID()
  category_uuid: string;
}
