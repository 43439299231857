import {
  IsDateString,
  IsIn,
  IsNumberString,
  IsOptional,
  IsString,
} from 'class-validator';

export class SearchProjectsDto {
  @IsString()
  @IsOptional()
  search?: string;

  @IsString()
  @IsOptional()
  search_brand?: string;

  @IsString()
  @IsOptional()
  search_supplier?: string;

  @IsString()
  @IsOptional()
  search_category?: string;

  @IsString()
  @IsOptional()
  search_sub_category?: string;

  @IsDateString()
  @IsOptional()
  search_date_from?: Date;

  @IsDateString()
  @IsOptional()
  search_date_to?: Date;

  @IsString()
  @IsIn([
    'date_asc',
    'date_desc',
    'date_edit_asc',
    'date_edit_desc',
    'job_asc',
    'job_desc',
    'name_asc',
    'name_desc',
  ])
  @IsOptional()
  orderBy?:
    | 'date_asc'
    | 'date_desc'
    | 'date_edit_asc'
    | 'date_edit_desc'
    | 'job_asc'
    | 'job_desc'
    | 'name_asc'
    | 'name_desc';

  @IsNumberString()
  @IsOptional()
  page?: string;

  @IsNumberString()
  @IsOptional()
  offset?: string;
}
