import { IsString } from 'class-validator';

export enum AssetsDownloadTypes {
  Picture = 'Picture',
  Layout = 'Layout',
  Executive = 'Executive',
}

export class DownloadProjectsQueryDto {
  @IsString()
  projects: string;

  @IsString()
  types: string;
}
