import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { PaginatedProjectsDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetTotalProjectsCount() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<PaginatedProjectsDto>(`/api/project`, fetcher);

  return {
    totalProjectsCount: handle(result.data?.count),
    error,
  };
}
