import { IsDateString, IsOptional, IsString } from 'class-validator';

export class SearchUsersQueryDto {
  @IsString()
  @IsOptional()
  orderBy?: 'a-z' | 'z-a';

  @IsString()
  @IsOptional()
  search?: string;

  @IsDateString()
  @IsOptional()
  search_date_from?: Date;

  @IsDateString()
  @IsOptional()
  search_date_to?: Date;
}
