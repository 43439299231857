import {
  PaymentInfoDto,
  UpdatePaymentInfoDto,
  PlanDataDto,
  CardDataDto,
} from '@docpack/shared/dtos';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import useSwr from 'swr';

export function usePlanDataHook() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  const planData = useSwr<PlanDataDto>(`/api/workspace/plan-data`, fetcher);

  return planData;
}
export function usePaymentInfoHook() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  const result = useSwr<PaymentInfoDto>(`/api/workspace/payment-info`, fetcher);

  const planData = useSwr<PlanDataDto>(`/api/workspace/plan-data`, fetcher);

  const cardData = useSwr<CardDataDto[]>(`/api/workspace/card-data`, fetcher);

  async function updatePaymentInfo(data: UpdatePaymentInfoDto) {
    const result = await fetcher(`/api/workspace/payment-info`, {
      method: 'PUT',
      body: data,
    });

    return handle<boolean>(result);
  }

  return {
    paymentInfoResult: result,
    planData: planData,
    updatePaymentInfo,
    updatePaymentInfoError: error,
    cardData: cardData,
  };
}
