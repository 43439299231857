import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UserDto } from '@docpack/shared/dtos';

export function useDeleteUser() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function deleteUser(user_uuid: string) {
    const result = await fetcher(`/api/user/${user_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<UserDto>(result);
  }

  return {
    error,
    deleteUser,
  };
}
