import { Route, Routes } from 'react-router-dom';
import { AddBrandPage, BrandsListPage, EditBrandPage } from './pages';

export function BrandsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BrandsListPage />}>
        <Route path="new" element={<AddBrandPage />} />
        <Route path="edit/:brand_uuid" element={<EditBrandPage />} />
      </Route>
    </Routes>
  );
}
