import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { NextCodesForYearDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { useRef } from 'react';

export function useGetNextCodesForYear(year: number) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);
  const random = useRef(Date.now());

  const result = useSwr<NextCodesForYearDto>(
    [`/api/project/next_codes_for/${year}`, random.current],
    fetcher
  );

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
