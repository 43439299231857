import { UserDto } from '@docpack/shared/dtos';
import { usePlainFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useUploadWorkspaceLogo() {
  const fetcher = usePlainFetcher();
  const { error, handle } = useHandleRespError();

  async function uploadWorkspaceLogo(file: File) {
    const body = new FormData();
    body.append('file', file);

    const result = await fetcher(`/api/user/upload-workspace-logo`, {
      method: 'POST',
      body: body,
    });

    return handle<UserDto>(await result.json())?.workspace?.workspace_logo;
  }

  return {
    error,
    uploadWorkspaceLogo,
  };
}
