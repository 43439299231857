import { UploadImage } from '@docpack/frontend/core-components';
import { UserDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { useUploadWorkspaceLogo } from '../data-access/upload-workspace-logo.hook';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface WorkspaceFormProps {
  onSubmit: (data: { workspace_logo: string }) => Promise<UserDto | undefined>;
  defaultValues?: { workspace_logo?: string };
  functionButtons?: Boolean;
  backLink?: string;
  forwardLink?: string;
}

export const WorkspaceForm = (props: WorkspaceFormProps) => {
  const formMethods = useForm<{ workspace_logo: string }>({
    defaultValues: props.defaultValues,
  });

  const { uploadWorkspaceLogo, error } = useUploadWorkspaceLogo();

  return (
    <FormProvider {...formMethods}>
      <form
        data-testid="settings-upload-logo"
        onSubmit={formMethods.handleSubmit(props.onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant="body3" mb={1}>
          Logo azienda
        </Typography>
        <UploadImage
          name="workspace_logo"
          uploadFile={uploadWorkspaceLogo}
          error={error}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 0 0 -1rem',
          }}
        >
          {props.functionButtons ? (
            <Button
              variant="outlined"
              sx={{ mt: 4, ml: 'auto', mr: '3rem' }}
              component={RouterLink}
              to={props.backLink || ''}
              startIcon={<ArrowBackIcon />}
            >
              Indietro
            </Button>
          ) : (
            <></>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 4, mr: 'auto' }}
            data-testid="settings-save-logo"
          >
            Salva
          </Button>
        </div>
        {props.functionButtons ? (
          <Button
            variant="text"
            sx={{ mt: 4, ml: 'auto', mr: 'auto' }}
            component={RouterLink}
            to={props.forwardLink || ''}
          >
            Salta per ora
          </Button>
        ) : (
          <></>
        )}
      </form>
    </FormProvider>
  );
};

export default WorkspaceForm;
