import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
/* TODO: JM fix types for defaultValues */
interface Newable<Type> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: Array<any>): Type;
}

export const useFormMethods = <Type = unknown>(
  dto: Newable<Type>,
  isReady?: boolean,
  defaultValues?: Partial<Type>
) => {
  const resolver = classValidatorResolver(dto);
  const formMethods = useForm<Type>({
    resolver,
  });

  useEffect(() => {
    if (isReady && !formMethods.formState.isDirty && defaultValues) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(defaultValues).forEach((key: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (defaultValues as any)[key];
        if (value) {
          formMethods.setValue(key, value, { shouldDirty: false });
        }
      });
    }
  }, [isReady, defaultValues, formMethods]);

  return formMethods;
};
