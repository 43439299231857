import { CanAccessOptions } from '@docpack/shared/dtos';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAccessControl } from '../../hooks';

export interface UserAccessControlProps {
  options: CanAccessOptions;
  redirect?: string;
}

export const UserAccessControl = (
  props: PropsWithChildren<UserAccessControlProps>
) => {
  const accessEnabled = useAccessControl(props.options);

  if (props.redirect && !accessEnabled) {
    return <Navigate to={props.redirect} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return accessEnabled ? <>{props.children}</> : null;
};

export default UserAccessControl;
