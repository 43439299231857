import Box, { BoxProps } from '@mui/material/Box';
import { Button, Checkbox, Typography, useThemeProps } from '@mui/material';
import PlansConfiguration from '../data-access/plans-configuration';
import { alpha, styled } from '@mui/material/styles';

interface PricePlan {
  value: string;
  plan: any;
  active?: boolean;
  yearly?: boolean;
  onChange: (value: string, plan: any) => void;
}

const PlanBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'value',
})<{ active?: boolean; value?: string }>(({ active, value }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '24px',
  maxWidth: '326px',
  height: 'auto',
  ...(!active && {
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.light',
  }),
  ...(active && {
    backgroundColor: '#F2CB8080',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#F07E14',
  }),
}));

export function Plan(props: PricePlan) {
  let { value, plan } = props;

  const configurations: any = {
    one: PlansConfiguration.one,
    team: PlansConfiguration.team,
    business: PlansConfiguration.business,
  };

  const backgroundColors: any = {
    one: '#FAFAFA',
    team: '#F2CB8080',
    business: '#C4C4C4',
  };

  return (
    <>
      <PlanBox active={props.active} value={value}>
        <Typography variant="button" mb={3}>
          {configurations[value].title}
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="span">
            €{plan[value].unit_amount / 100}
            <Typography component={'span'}>
              /{props.yearly ? 'anno' : 'mese'}
            </Typography>
            <Typography
              variant="caption"
              component={'span'}
              marginLeft={2}
              fontWeight={400}
            >
              (IVA esclusa)
            </Typography>
          </Typography>
          <Typography variant="body1">
            {configurations[value].description}
          </Typography>
          {configurations[value].features.map((val: string) => (
            <Box key={val}>
              <Checkbox color="warning" checked={true} /> {val}
            </Box>
          ))}
        </Box>
        <Button
          sx={{ marginTop: 3 }}
          variant={props.active ? 'outlined' : 'contained'}
          onClick={() => !props.active && props.onChange(value, plan)}
        >
          {props.active ? 'Il tuo piano attuale' : 'Seleziona'}
        </Button>
      </PlanBox>
    </>
  );
}

export default Plan;
