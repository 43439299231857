import { IsString } from 'class-validator';

export class UpdateSupplierDto {
  @IsString()
  supplier_uuid: string;

  @IsString({ message: 'Fornire un titolo' })
  name: string;

  @IsString({ message: 'Fornire un codice' })
  code: string;
}
