import {
  IsIn,
  IsDateString,
  IsNumberString,
  IsOptional,
  IsString,
} from 'class-validator';

export class SearchBrandsQueryDto {
  @IsString()
  @IsOptional()
  @IsIn(['a-z', 'z-a'])
  orderBy?: 'a-z' | 'z-a';

  @IsNumberString()
  @IsOptional()
  page?: string;

  @IsNumberString()
  @IsOptional()
  offset?: string;

  @IsString()
  @IsOptional()
  search?: string;

  @IsDateString()
  @IsOptional()
  search_date_from?: Date;

  @IsDateString()
  @IsOptional()
  search_date_to?: Date;
}
