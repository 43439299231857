import { useController } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface CheckboxInputProps {
  name: string;
  label?: string;
  required?: boolean;
  options: { label: string; value: string; disabled?: boolean };
  cy?: string;
}

export function CheckboxInput(props: CheckboxInputProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  return (
    <>
      <FormControlLabel
        value={props.options.value}
        control={
          <Checkbox
            color="warning"
            checked={field.value}
            disabled={props.options.disabled}
            onChange={() => {
              if (field.value !== props.options.value) {
                field.onChange(props.options.value);
              } else {
                field.onChange(undefined);
              }
            }}
            data-testid={props.cy}
          />
        }
        label={props.options.label}
      />
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}

export default CheckboxInput;
