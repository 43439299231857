import React from 'react';
import { Box, Button, Stack, Typography, Alert } from '@mui/material';
import { PlanData } from './plan-data';
import { SWRResponse } from 'swr';
import { useNavigate } from 'react-router-dom';
import { PlanDataDto, CardDataDto } from '@docpack/shared/dtos';
import { CardData } from './card-data';
interface PlanPaymentsProps {
  cardData: SWRResponse<CardDataDto[], any>;
  handlePayment: () => void;
}
export function PlanPayments(props: PlanPaymentsProps) {
  const planData = props.cardData;
  const navigate = useNavigate();
  console.log(planData);
  return (
    <>
      <Box
        padding={4}
        sx={{
          border: '1px solid black',
          alignContent: 'center',
          borderRadius: '20px',
        }}
      >
        <Typography marginBottom={3} variant="h5">
          Dati di Pagamento
        </Typography>
        {props.cardData?.data?.length ? (
          <CardData cards={props.cardData.data} />
        ) : (
          <>
            <Alert severity="warning">
              <Typography>
                Stai usufruendo del periodo di prova. Al termine dei 30 giorni
                gratuiti, se desideri continuare ad usare DOCpack dovrai
                inserire i tuoi dati di pagamento cliccando sul pulsante
                "Gestisci Pagamenti”.
              </Typography>
            </Alert>
          </>
        )}
        <Button
          type="submit"
          variant="outlined"
          disabled={!planData?.data}
          onClick={props.handlePayment}
          sx={{ marginTop: 3 }}
        >
          Gestisci Pagamenti
        </Button>
      </Box>
    </>
  );
}

export default PlanPayments;
