import * as React from 'react';
import Box from '@mui/material/Box';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';

import MuiStepLabel, { StepLabelProps } from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepIcon from './icon';
import DividerIcon from './divider';

interface StepperProps {
  steps: Array<string>;
  activeStep: number;
}
const StepLabel = styled(MuiStepLabel)<StepLabelProps>(({ theme }) => ({
  '& .MuiStepLabel-label': {
    ...theme.typography.body1,
    color: theme.palette.gray.main,
    fontWeight: 500,
    '&.Mui-active': {
      ...theme.typography.microtitle,
      color: theme.palette.secondary.main,
    },
  },
  '&.Mui-active': {},
}));
export const Stepper = (props: StepperProps) => {
  return (
    <MuiStepper
      sx={{ paddingBottom: '24px' }}
      activeStep={props.activeStep - 1}
      connector={
        <Box
          sx={{
            justifyContent: 'center',
            paddingLeft: '8px',
            paddingRight: '8px',
            verticalAlign: 'middle',
          }}
        >
          <DividerIcon />
        </Box>
      }
    >
      {props.steps.map((label, key) => (
        <Step key={label}>
          <StepLabel icon={<></>}>
            <Stack gap={2} alignItems="center">
              {props.activeStep - 1 === key && <StepIcon />}
              {label}
            </Stack>
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
