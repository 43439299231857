import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useResetPassword() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function resetPassword() {
    const result = await fetcher(`/api/user/reset-me`, {
      method: 'POST',
      body: {},
    });

    return handle<boolean>(result);
  }

  return {
    error,
    resetPassword,
  };
}
