import useSwr, { Middleware } from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { AssetDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

const makeNotRequestIfNoAssetUUID =
  (asset_uuid?: string): Middleware =>
  (useSWRNext) => {
    return (key, fetcher, config) => {
      if (asset_uuid) {
        return useSWRNext(key, fetcher, config);
      } else {
        return useSWRNext(key, null, config);
      }
    };
  };

export function useGetAssetEntity(asset_uuid: string) {
  const fetcher = useFetcher();

  const { handle, error } = useHandleRespError(false);

  const notRequestIfNoAssetUUID = makeNotRequestIfNoAssetUUID(asset_uuid);

  const result = useSwr<AssetDto>(`/api/assets/entity/${asset_uuid}`, fetcher, {
    use: [notRequestIfNoAssetUUID],
  });

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data,
  };
}
