import {
  AddButton,
  AlertController,
  DotsMenu,
  PlaceholderText,
  SearchBar,
  Toolbar,
  ToolbarLeftContainer,
  useGetItemsSelected,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { Link, Outlet } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { unstable_batchedUpdates } from 'react-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useMemo } from 'react';
import { useGetSuppliers, useUpdateSupplier } from '../data-access';
import SupplierItem from '../components/supplier-item/supplier-item';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
/* import { BrandItemSkeleton } from 'libs/frontend/brands/src/lib/components'; */

export interface SuppliersListPageOutletContext {
  reloadSuppliers: () => void;
}

export const SuppliersListPage = () => {
  const formMethods = useForm({ mode: 'onChange' });
  const { data, error, mutate, isLoadingMore } = useGetSuppliers();
  const { deleteSupplier, error: errorDeleteSupplier } = useUpdateSupplier();
  const {
    itemsSelected,
    toggleItemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  } = useGetItemsSelected();
  const showMessage = useMessagePopup();

  const allSuppliersIds = useMemo(() => {
    return data?.map((supplier) => supplier.supplier_uuid);
  }, [data]);

  return (
    <>
      <AlertController
        message={
          errorDeleteSupplier
            ? { type: 'error', text: errorDeleteSupplier }
            : undefined
        }
      />
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <SearchBar removeSelect formMethods={formMethods} />
      <Toolbar itemsSelectedCount={itemsSelected.length}>
        <ToolbarLeftContainer>
          <AddButton linkTo={'new'} label={'Inserisci fornitore'} />
        </ToolbarLeftContainer>
        <Stack direction="row" spacing={3}>
          {itemsSelected.length === 1 && (
            <IconButton
              color="primary"
              component={Link}
              to={`edit/${itemsSelected[0]}`}
            >
              <EditIcon fontSize={'medium'} />
            </IconButton>
          )}
          {itemsSelected.length > 0 && (
            <IconButton
              color="primary"
              onClick={() => {
                let error = false;
                if (
                  window.confirm(
                    `Sei sicuro di voler eliminare ${itemsSelected.length} fornitori?`
                  )
                ) {
                  Promise.all(
                    itemsSelected.map((supplier_uuid) =>
                      deleteSupplier(supplier_uuid).then((res) =>
                        res ? supplier_uuid : false
                      )
                    )
                  ).then((ids) => {
                    unstable_batchedUpdates(() => {
                      ids.forEach((id) => {
                        id ? removeItemsSelected(id) : (error = true);
                      });
                    });

                    if (error) {
                      showMessage('Impossibile eliminare fornitore', 'error');
                    } else {
                      showMessage(
                        'Fornitori eliminati con successo!',
                        'success'
                      );
                    }
                    mutate();
                  });
                }
              }}
              data-testid="delete-brand-button"
            >
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(allSuppliersIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Stack>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="nowrap"
          mb={2}
        >
          <Typography
            variant="caption"
            sx={{
              maxWidth: '100%',
              textAlign: 'center',
              width: '100px',
              flex: '0 0 100px',
              marginRight: '1rem',
            }}
          >
            Codice
          </Typography>
          <Typography
            variant="caption"
            sx={{
              maxWidth: '100%',
              textAlign: 'center',
              width: '100px',
              flex: '0 0 100px',
              marginRight: { xs: '1rem', md: '2rem' },
            }}
          >
            Progetti
          </Typography>
        </Grid>
        {data && data?.length > 0 ? (
          data?.map((supplier, key) => {
            return (
              <SupplierItem
                key={key}
                data={supplier}
                selected={isSelected((item) => item === supplier.supplier_uuid)}
                onClick={() => toggleItemsSelected(supplier.supplier_uuid)}
              />
            );
          })
        ) : (
          <PlaceholderText>
            Clicca sul bottone in alto a sinistra
            <br />
            per aggiungere la tua prima categoria
          </PlaceholderText>
        )}
        {/* {isLoadingMore && <BrandItemSkeleton />} */}
      </Stack>
      <Outlet context={{ reloadSuppliers: mutate }} />
    </>
  );
};

export default SuppliersListPage;
