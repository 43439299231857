export interface Configuration {
  title: string;
  description: string;
  features: string[];
}
//TODO: this needs to be refactored into metadata, features and product
const one: Configuration = {
  title: 'One',
  description:
    'Il piano ideale per un singolo utente.\nTi permette di archiviare fino a 100 progetti.',
  features: [
    '1 Account',
    '100 Gb di archiviazione',
    '100 progetti',
    'Marchi',
    'Categorie e sottocategorie',
    'Fornitori',
    'Report attività',
  ],
};
const team: Configuration = {
  title: 'Team',
  description:
    'Tutte le funzionalità del piano One con la possibilità di aggiungere fino a 5 collaboratori alla piattaforma.',
  features: [
    '5 Account',
    '500 Gb di archiviazione',
    '300 progetti',
    'Marchi',
    'Categorie e sottocategorie',
    'Fornitori',
    'Report attività',
  ],
};
const business: Configuration = {
  title: 'Business',
  description:
    'Ideale per una gestione del progetto a un team esteso anche a società esterne.',
  features: [
    '40 Account',
    '2 Tb di spazio di archiviazione',
    '500 progetti',
    'Fino a 5 marchi a società esterne',
    'Categorie e sottocategorie',
    'Fornitori',
    'Report attività',
  ],
};

export default {
  business,
  one,
  team,
};
