import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { BrandDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetSingleBrand(brand_uuid: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<BrandDto>(`/api/brand/${brand_uuid}`, fetcher);

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
