import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import {
  SearchPlatformRegistrationsQueryDto,
  UserDto,
} from '@docpack/shared/dtos';
import useSwr from 'swr';

export function useGetPlatformRegistrations(
  params: SearchPlatformRegistrationsQueryDto
) {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  const queryParams = new URLSearchParams();

  queryParams.set('api_key', params.api_key || '');

  if (params.user_role) {
    queryParams.set('user_role', params.user_role);
  }

  if (params.user_type) {
    queryParams.set('user_type', params.user_type);
  }

  const result = useSwr<Array<UserDto>>(
    `/api/platform-registration/all?${queryParams}`,
    fetcher
  );

  const data = handle(result.data);

  return {
    ...result,
    error,
    loading: !error && !data,
    data,
  };
}
