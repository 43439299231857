import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Duck from '../../../../registration-feature/src/lib/components/not-logged-in-shell/duck.svg';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import {
  PaymentInfoFormColumns as PaymentInfoForm,
  usePaymentInfoHook,
} from '@docpack/frontend/payments-feature';
import {
  AlertController,
  useShowMessage,
  Stepper,
} from '@docpack/frontend/core-components';
import { useNavigate } from 'react-router-dom';
import WizardBox from '../component/wizard-box';
import steps from '../data-access/steps';
/* eslint-disable-next-line */

export function FrontendWizardFeaturePhaseTwo() {
  const { data: user } = useGetCurrentUserInfo();
  const messageController = useShowMessage();
  const navigate = useNavigate();
  const { updatePaymentInfo, paymentInfoResult, planData } =
    usePaymentInfoHook();
  return (
    <WizardBox>
      <Stepper steps={steps} activeStep={1} />
      <AlertController
        message={messageController.message}
        hasClosed={messageController.clearMessage}
      />
      <Typography variant="h3" mb={5} textAlign={'center'}>
        Dati società
      </Typography>
      <Container>
        {paymentInfoResult.data && (
          <PaymentInfoForm
            functionButtons
            backLink="/"
            forwardLink="/setup/fase-tre"
            onSubmit={(data) =>
              updatePaymentInfo(data)
                .then((success) => {
                  success
                    ? navigate('/setup/fase-tre')
                    : messageController.showMessage({
                        type: 'error',
                        text: "Errore durante l'inserimento dei dati società",
                      });
                })
                .catch((error) =>
                  messageController.showMessage({
                    type: 'error',
                    text: error.message,
                  })
                )
            }
            defautlValues={paymentInfoResult.data}
          />
        )}
      </Container>
    </WizardBox>
  );
}

export default FrontendWizardFeaturePhaseTwo;
