import { Transform } from 'class-transformer';
import {
  ArrayMinSize,
  IsArray,
  IsDateString,
  IsNumberString,
  IsOptional,
  IsString,
  IsUUID,
  MaxLength,
} from 'class-validator';

export function formatNumberToBeThreeChar(
  item?: number | string
): string | undefined {
  if (typeof item !== 'number' && typeof item !== 'string') {
    return undefined;
  }

  if (Number.isNaN(item)) {
    return '000';
  }

  const str = String(item);
  let result = str;

  for (let index = 0; index < 3 - str.length; index++) {
    result = `0${result}`;
  }

  return result;
}

export class NewProjectDto {
  @IsString({ message: 'Fornire un nome' })
  @MaxLength(120, {
    message: 'Il nome del progetto non deve superare i 120 caratteri',
  })
  name: string;

  @IsString()
  @IsOptional()
  ean?: string;

  @IsNumberString({}, { message: 'Selezionare un anno' })
  project_code_year: string;

  @Transform((item) => formatNumberToBeThreeChar(item.value))
  @IsNumberString({}, { message: 'Il codice progetto deve essere numerico' })
  project_code_code: string;

  @IsString()
  project_code_sequential: string;

  @IsString()
  @IsOptional()
  internal_code?: string;

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  keywords: Array<string>;

  @IsArray({ message: 'Seleziona un o piu marchi' })
  @ArrayMinSize(1, { message: 'Seleziona un o piu marchi' })
  @IsUUID(undefined, { message: 'Seleziona un o piu marchi', each: true })
  brand_uuids: Array<string>;

  @IsUUID()
  @IsOptional()
  category_uuid?: string;

  @IsUUID()
  @IsOptional()
  sub_category_uuid?: string;

  @IsOptional()
  @IsUUID()
  picture_asset_uuid?: string;

  @IsOptional()
  @IsUUID()
  layout_asset_uuid?: string;

  @IsOptional()
  @IsUUID()
  executive_asset_uuid?: string;

  @IsOptional()
  @IsUUID()
  supplier_uuid?: string;

  @IsDateString()
  created_at: string;

  @IsString()
  @IsOptional()
  weight?: string;
}
