import { Route, Routes } from 'react-router-dom';
import FrontendWizardFeature from './pages/frontend-wizard-feature';
import FrontendWizardFeaturePhaseTwo from './pages/frontend-wizard-phase-two';
import FrontendWizardFeaturePhaseThree from './pages/frontend-wizard-phase-three';
import FrontendWizardFeaturePhaseFour from './pages/frontend-wizard-feature-phase-four';
import FrontendWizardFeaturePhaseFive from './pages/frontend-wizard-phase-five';
import FrontendWizardShell from './pages/frontend-wizard-feature-shell';
export function WizardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<FrontendWizardShell />}>
        <Route path="/welcome" element={<FrontendWizardFeature />}></Route>
        <Route
          path="/fase-due"
          element={<FrontendWizardFeaturePhaseTwo />}
        ></Route>
        <Route
          path="/fase-tre"
          element={<FrontendWizardFeaturePhaseThree />}
        ></Route>
        <Route
          path="/fase-quattro"
          element={<FrontendWizardFeaturePhaseFour />}
        ></Route>
        <Route
          path="/fase-cinque"
          element={<FrontendWizardFeaturePhaseFive />}
        ></Route>
      </Route>
    </Routes>
  );
}
