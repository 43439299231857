import 'reflect-metadata';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ClientAuthenticationProvider } from '@docpack/frontend/client-authorization';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Routes } from './app/routes';
import AppThemeSchell from './app/app-theme-shell';
import { PopupMessageContainer } from '@docpack/frontend/core-components';

if (process.env['NODE_ENV'] === 'production') {
  // TODO: Remove comments if u want to use sentry
  // Sentry.init({
  //   dsn: 'your_entry_dns',
  //   integrations: [new BrowserTracing()],
  //   release: process.env['VERSION'],
  //   tracesSampleRate: 1.0,
  // });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <ClientAuthenticationProvider transport="cookie" type="password">
      <AppThemeSchell>
        <PopupMessageContainer>
          <Routes />
        </PopupMessageContainer>
      </AppThemeSchell>
    </ClientAuthenticationProvider>
  </StrictMode>
);
