import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { SubCategoryDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetSingleSubCategory(sub_category_uuid: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<SubCategoryDto>(
    `/api/project/categories/sub-category/${sub_category_uuid}`,
    fetcher
  );

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
