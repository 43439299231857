import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  Children,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import UserRowDetails from './user-row-details';

const headerListing: Array<string> = [
  'Email',
  'Nome azienda',
  'Cellulare',
  'Ruolo',
  'Tipologia',
  'Scaricare',
  'Caricare',
  'Last active',
];

const customColumnsSizes: Array<string> = [
  /* '85px', */
  '160px',
  '82px',
  '82px',
  '82px',
  '73px',
  '57px',
  '57px',
  '66px',
];

export const useOrderbySelect = (): OrderBySelectProps => {
  const [order, setOrder] = useState<'a-z' | 'z-a'>('a-z');

  const handleChange = useCallback<OrderBySelectProps['handleChange']>(
    (val) => {
      setOrder(val);
    },
    []
  );

  return {
    order,
    handleChange,
  };
};

export interface OrderBySelectProps {
  order: 'a-z' | 'z-a';
  handleChange: (item: 'a-z' | 'z-a') => void;
}

export const OrderBySelect = (props: OrderBySelectProps) => {
  return (
    <Select
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={props.order}
      onChange={(event) =>
        props.handleChange(event.target.value as OrderBySelectProps['order'])
      }
      label="Data"
    >
      <MenuItem value={'a-z'}>A-Z</MenuItem>
      <MenuItem value={'z-a'}>Z-A</MenuItem>
    </Select>
  );
};

export const OrderByAndHeaderListing = (props: PropsWithChildren<unknown>) => {
  /* const selectOrderChildren = Children.toArray(props.children).find(
    (child) => (child as ReactElement).type === OrderBySelect
  ); */

  const children = Children.toArray(props.children).filter(
    (child) => (child as ReactElement).type !== OrderBySelect
  );

  return (
    <Grid
      container
      pb={2}
      sx={{
        display: { xs: 'none', md: 'flex' },
        position: 'sticky',
        top: '188px',
        backgroundColor: '#fff',
        zIndex: 10,
      }}
    >
      {/* <Grid item xs={12} lg={3} container alignItems="center">
        <Typography
          variant="body2"
          style={{
            paddingRight: '20px',
          }}
        >
          {`Visualizza per `}
        </Typography>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 80,
          }}
        >
          {selectOrderChildren}
        </FormControl>
      </Grid> */}
      <Grid item xs={12} container sx={{ display: { xs: 'none', md: 'flex' } }}>
        <UserRowDetails
          type="header"
          details={headerListing}
          customColumnsSizes={customColumnsSizes}
        />
        {children}
      </Grid>
    </Grid>
  );
};

export default OrderByAndHeaderListing;
