import { useCallback, useEffect, useState } from 'react';
import { AlertControllerProps } from '../components';

export function useShowMessage(timeout = 3000) {
  const [message, setMessage] = useState<AlertControllerProps['message']>();

  const showMessage = useCallback(
    (message: AlertControllerProps['message']) => {
      setMessage(message);
    },
    []
  );

  const clearMessage = useCallback(() => {
    setMessage(undefined);
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      setMessage(undefined);
    }, timeout);
    return () => {
      clearTimeout(id);
    };
  }, [timeout]);

  return {
    message,
    showMessage,
    clearMessage,
  };
}
