import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { SupplierDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useOptionsSuppliers() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<Array<SupplierDto>>(`/api/suppliers`, fetcher);

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data: data?.map((supplier) => ({
      label: supplier.name,
      value: supplier.supplier_uuid,
    })),
  };
}
