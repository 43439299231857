import { CanAccessOptions, safeUserCan } from '@docpack/shared/dtos';
import { useGetUserInfo } from '../data-access';

export function useAccessControl(options: CanAccessOptions) {
  const { data: userdata } = useGetUserInfo();

  // If the information is not there assume the user has access
  // otherwise this will alaways redirects the user to the homepage
  if (!userdata?.details) {
    return true;
  }

  return safeUserCan(options, userdata.details);
}
