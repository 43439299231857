import {
  InputText,
  SelectAutocomplete,
  useOptionsCategories,
} from '@docpack/frontend/core-components';
import { NewSubCategoryDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export interface SubcategoryFormProps {
  handleClose: () => void;
  onSubmit: (val: NewSubCategoryDto) => Promise<unknown>;
  defaultValues?: Partial<NewSubCategoryDto>;
  formMethods: UseFormReturn<NewSubCategoryDto, object>;
}

export const SubcategoryForm = (props: SubcategoryFormProps) => {
  const { data } = useOptionsCategories();

  return (
    <FormProvider {...props.formMethods}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={2}>
          <SelectAutocomplete
            name="category_uuid"
            label="Categoria genitore"
            options={data || []}
            required
          />
          <InputText name="name" label="Nome categoria" required />
        </Stack>
        <Grid container sx={{ mt: '32px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="add-project-submit-button"
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SubcategoryForm;
