import { IsNumber, IsOptional, IsString } from 'class-validator';

export enum OrderByEnum {
  'a-z' = 'a-z',
  'z-a' = 'z-a',
}

export class SearchContactsQueryDto {
  @IsString()
  @IsOptional()
  orderBy?: OrderByEnum;

  @IsString()
  @IsOptional()
  query?: string;

  @IsNumber()
  @IsOptional()
  skip?: number;
}
