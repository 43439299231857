import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Stack,
  Container,
} from '@mui/material';
import {
  useMessagePopup,
  AlertController,
  useShowMessage,
} from '@docpack/frontend/core-components';
import Plan from '../components/plan';
import { usePlanDataHook } from '@docpack/frontend/payments-feature';
import { useStripe } from '@docpack/frontend/react-hook-utils';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { PlanPriceDto } from '@docpack/shared/dtos';

export function PlanSelectionPage() {
  const { planPrices, changePlan } = useStripe();
  const messageController = useShowMessage();
  const showMessage = useMessagePopup();
  const planData = usePlanDataHook();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<string>('month');

  const radioSx = {
    display: 'flex',
  };

  const onPlanChange = (value: string, plan: any) => {
    if (window.confirm('Sei sicuro di voler cambiare piano?')) {
      //this capitalize the plan
      changePlan(value.charAt(0).toUpperCase() + value.slice(1), plan[value].id)
        .then((result) => {
          let msg = 'Non puoi selezionare il piano: \n';
          console.log(result);
          if (result.canCreateUser && result.canCreateBrand) {
            showMessage('Piano Aggiornato con successo!', 'success');
            return;
          }

          if (!result.canCreateUser)
            msg += ' Hai troppi utenti per il piano scelto';
          if (!result.canCreateBrand)
            if (!result.canCreateUser)
              msg += ' e hai troppi marchi per il piano scelto';
            else msg += ' Hai troppi marchi per il piano scelto\n';
          console.log(msg);
          showMessage(msg, 'error');
        })
        .then(() => navigate('/app/payments'))
        .catch((error) => {
          console.log(error);
          messageController.showMessage({
            type: 'error',
            text: error.message,
          });
        });
    }
  };

  return (
    <>
      <AlertController
        message={messageController.message}
        hasClosed={messageController.clearMessage}
      />
      <Container sx={{ padding: 4 }}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Conferma il tuo piano
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          o scegline uno più adatto alle tue esigenze
        </Typography>
        <Stack alignItems="center" spacing={4}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="month"
            name="radio-buttons-group"
            onChange={(e) => setSelected(e.target.value)}
          >
            <FormControlLabel
              sx={radioSx}
              value="month"
              control={<Radio />}
              label="Fatturazione mensile"
            />
            <FormControlLabel
              sx={radioSx}
              value="year"
              control={<Radio />}
              label="Fatturazione annuale"
            />
          </RadioGroup>

          {planPrices && (
            <Stack direction="row" spacing={4}>
              <Plan
                active={planData.data?.name === 'One'}
                onChange={onPlanChange}
                value="one"
                yearly={selected === 'year'}
                plan={planPrices[selected as keyof typeof planPrices]}
              />
              <Plan
                active={planData.data?.name === 'Team'}
                onChange={onPlanChange}
                value="team"
                yearly={selected === 'year'}
                plan={planPrices[selected as keyof typeof planPrices]}
              />
              <Plan
                active={planData.data?.name === 'Business'}
                onChange={onPlanChange}
                value="business"
                yearly={selected === 'year'}
                plan={planPrices[selected as keyof typeof planPrices]}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </>
  );
}

export default PlanSelectionPage;
