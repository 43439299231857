import { Route, Routes } from 'react-router-dom';
import {
  SharedProjectAssetsDownloadPage,
  ShareProjectsListPage,
} from './pages';

export function ShareProjectsFeatureRoutes() {
  return (
    <Routes>
      <Route path="/:share_token" element={<ShareProjectsListPage />}>
        <Route path="download" element={<SharedProjectAssetsDownloadPage />} />
      </Route>
    </Routes>
  );
}
