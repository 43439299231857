import { InputText, UploadFile } from '@docpack/frontend/core-components';
import { NewContactDto, UpdateContactDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export interface ContactFormPropsEdit {
  handleClose: () => void;
  onSubmit: (val: UpdateContactDto) => Promise<unknown>;
  defaultValues?: Partial<UpdateContactDto>;
  isEdit: true;
  isNew?: false;
  formMethods: UseFormReturn<UpdateContactDto, object>;
}

export interface ContactFormPropsNew {
  handleClose: () => void;
  onSubmit: (val: NewContactDto) => Promise<unknown>;
  defaultValues?: Partial<NewContactDto>;
  isNew: true;
  isEdit?: false;
  formMethods: UseFormReturn<NewContactDto, object>;
}

export const ContactForm = (
  props: ContactFormPropsEdit | ContactFormPropsNew
) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormProvider {...(props.formMethods as any)}>
      <form
        onSubmit={props.formMethods.handleSubmit((values) => {
          if (props.isNew) {
            return props.onSubmit(values);
          }
          return props.onSubmit(values as UpdateContactDto);
        })}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <InputText name="first_name" label="Nome" />
              <InputText name="last_name" label="Cognome" />
              <InputText name="email" label="Email" type={'email'} />
              <InputText name="phone" label="Cell." type={'number'} />
              <InputText name="company_name" label="Nome azienda" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <InputText
                name="description"
                label="Descrizione o note "
                textarea
              />
              <UploadFile
                name="image_contact_asset_uuid"
                label="Immagine contatto"
                accept="image/*"
                tooltipText="Immagine di profilo del contatto"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '48px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.handleClose}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              disabled={props.formMethods.formState.isSubmitting}
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ContactForm;
