import { WhiteBox } from '../components';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const ResetSuccessPage = () => {
  return (
    <WhiteBox>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Stack spacing={3}>
          <Box
            sx={{
              width: 88,
              height: 89,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <svg
              width="88"
              height="89"
              viewBox="0 0 88 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#96ljc52xva)">
                <circle cx="44" cy="37.25" r="32" fill="#51B943" />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.075 26.342a2.667 2.667 0 0 1 .277 3.762L43.24 48.77a2.666 2.666 0 0 1-3.871.176l-9.667-9.333a2.667 2.667 0 0 1 3.705-3.837l7.638 7.375 14.268-16.532a2.667 2.667 0 0 1 3.761-.277z"
                fill="#fff"
              />
              <defs>
                <filter
                  id="96ljc52xva"
                  x="0"
                  y=".25"
                  width="88"
                  height="88"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="7" />
                  <feGaussianBlur stdDeviation="6" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                  <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1127_39921"
                  />
                  <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1127_39921"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Box>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            Ben fatto
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            La procedura e stata completata con successo
          </Typography>
          <Button variant="contained" component={Link} to="../login">
            <ArrowBackIcon sx={{ mr: '10px' }} /> Torna alla login
          </Button>
        </Stack>
      </Grid>
    </WhiteBox>
  );
};

export default ResetSuccessPage;
