import { IsOptional, IsString } from 'class-validator';

export class UpdatePaymentInfoDto {
  @IsString()
  @IsOptional()
  business_name?: string;

  @IsString()
  @IsOptional()
  city?: string;

  @IsString()
  @IsOptional()
  address?: string;

  @IsString()
  @IsOptional()
  state?: string;

  @IsString()
  @IsOptional()
  postal_code?: string;

  @IsString()
  @IsOptional()
  c_f?: string;

  @IsString()
  @IsOptional()
  partita_iva?: string;

  @IsString()
  @IsOptional()
  sdi_code?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  fax?: string;

  @IsString()
  @IsOptional()
  business_email?: string;

  @IsString()
  @IsOptional()
  website?: string;
}
