import Grid from '@mui/material/Grid';
import { PropsWithChildren } from 'react';
import { RowDetails } from '@docpack/frontend/core-components';

export interface RowHeaderProps {
  headerListing: Array<string>;
  customColumnsSizes: Array<string>;
}
export const RowHeader = (props: PropsWithChildren<RowHeaderProps>) => {
  /* const selectOrderChildren = Children.toArray(props.children).find(
    (child) => (child as ReactElement).type === OrderBySelect
  ); */

  return (
    <Grid
      container
      pb={2}
      sx={{
        display: { xs: 'none', md: 'flex' },
        position: 'sticky',
        top: '188px',
        backgroundColor: '#fff',
        zIndex: 10,
      }}
    >
      {/* <Grid item xs={12} lg={3} container alignItems="center">
        <Typography
          variant="body2"
          style={{
            paddingRight: '20px',
          }}
        >
          {`Visualizza per `}
        </Typography>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 80,
          }}
        >
          {selectOrderChildren}
        </FormControl>
      </Grid> */}
      <Grid item xs={12} container sx={{ display: { xs: 'none', md: 'flex' } }}>
        <RowDetails
          type="header"
          details={props.headerListing}
          customColumnsSizes={props.customColumnsSizes}
        />
      </Grid>
    </Grid>
  );
};

export default RowHeader;
