import Alert from '@mui/material/Alert';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DatePicker from '@mui/lab/DatePicker';

import { useController } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Moment } from 'moment';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { useState } from 'react';
export interface DateInputProps {
  label?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  small?: boolean;
  sx?: SxProps<Theme> | undefined;
  defaultValue?: Date | string | null;
}

export function DateInput(props: DateInputProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  });

  return (
    <>
      <DatePicker
        disabled={props.disabled}
        label={props.label}
        inputFormat="DD/MM/yyyy"
        onChange={(dateMoment: Moment | null) => {
          field.onChange(dateMoment?.toISOString());
        }}
        value={field.value}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={props.sx}
            size={props.small ? 'small' : 'medium'}
          />
        )}
      />
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}

export default DateInput;
