import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { SxProps, Theme } from '@mui/material/styles';
import { InfoTooltip } from '../components';
import Stack from '@mui/material/Stack';

export interface InputTextProps {
  label?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  type?: string;
  textarea?: boolean;
  search?: boolean;
  small?: boolean;
  leftIcons?: JSX.Element;
  rightIcons?: JSX.Element;
  sx?: SxProps<Theme> | undefined;
  searchSelectExists?: boolean;
  tooltipText?: string;
  cy?: string;
  placeholder?: string;
}

const SearchInput = styled(TextField)<{ searchSelectExists: boolean }>`
  background-color: #fff;
  margin-left: 32px;
  border-radius: 4px;

  input {
    max-width: 100%;
  }
`;

export function InputText(props: InputTextProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  return (
    <>
      {props.search ? (
        <SearchInput
          autoFocus
          variant="outlined"
          label={props.label}
          ref={field.ref}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value || ''}
          required={props.required}
          type={props.type ? props.type : 'text'}
          multiline={props.textarea ? true : false}
          rows={props.textarea ? 5 : 1}
          size={props.small ? 'small' : 'medium'}
          disabled={props.disabled}
          sx={props.sx}
          InputProps={{
            startAdornment: props.leftIcons,
            endAdornment: props.rightIcons,
          }}
          searchSelectExists={props.searchSelectExists || false}
          data-testid={props.cy}
        />
      ) : (
        <Stack>
          {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
          <TextField
            variant="outlined"
            placeholder={props.placeholder}
            label={props.label}
            ref={field.ref}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value || ''}
            disabled={props.disabled}
            required={props.required}
            type={props.type ? props.type : 'text'}
            multiline={props.textarea ? true : false}
            rows={props.textarea ? 5 : 1}
            size={props.small ? 'small' : 'medium'}
            sx={props.sx}
            InputProps={{
              startAdornment: props.leftIcons,
              endAdornment: props.rightIcons,
            }}
            fullWidth={props.fullWidth ? true : false}
            data-testid={props.cy}
          />
        </Stack>
      )}
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}

export default InputText;
