import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { PropsWithChildren } from 'react';

export function WhiteBox(props: PropsWithChildren<unknown>) {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: 'absolute',
        top: 'calc(50% + 30px)',
        transform: 'translateY(-50%)',
      }}
    >
      <Grid item xs={12} md={6} xl={4}>
        <Box
          sx={{
            backgroundColor: theme.palette.white.main,
            ml: 'auto',
            mr: 'auto',
            maxWidth: {
              xs: 'calc(100% - 32px)',
              sm: 'calc(70% - 32px)',
              md: '516px',
            },
            p: {
              xs: '30px 40px',
              sm: '30px 40px',
              md: '32px 40px',
              lg: '32px 40px',
            },
            borderRadius: '24px',
          }}
        >
          {props.children}
        </Box>
      </Grid>
    </Grid>
  );
}

export default WhiteBox;
