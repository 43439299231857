import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import Duck from '../components/not-logged-in-shell/duck.svg';
import { Header } from '../components';

const ArchiveIcon = () => {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M16 12h16V8a2 2 0 0 0-2-2H18a2 2 0 0 0-2 2v4z"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m32 12 2.188 3.518A12 12 0 0 1 36 21.858V38a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4V21.858a12 12 0 0 1 1.812-6.34L16 12"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24"
        cy="32"
        r="4"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20h8"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SmartShareIcon = () => {
  return (
    <svg width="49" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="24.5"
        cy="10"
        r="4"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.23 15.518a1 1 0 1 0 1.432-1.396l-1.432 1.396zm-6.516-5.99a1 1 0 0 0-.496 1.937l.496-1.938zm-7.942 1.937a1 1 0 0 0-.496-1.938l.496 1.938zm-8.548 2.772a1 1 0 0 0 1.444 1.382l-1.444-1.382zm-2.083 7.41a1 1 0 0 0-1.914-.578l1.914.578zM8.5 25.998h1-1zm-.39 4.53a1 1 0 1 0 1.928-.531l-1.928.53zm5.604 5.896a1 1 0 1 0-1.44 1.388l1.44-1.388zm6.549 6.042a1 1 0 1 0 .498-1.936l-.498 1.936zm7.994-1.942a1 1 0 0 0 .502 1.936l-.502-1.936zm8.464-2.71a1 1 0 0 0-1.438-1.389l1.438 1.39zm2.24-7.82a1 1 0 0 0 1.93.531l-1.93-.53zm1.539-3.996h-1 1zm.347-4.68a1 1 0 1 0-1.922.549l1.922-.55zm-4.185-7.196a16.985 16.985 0 0 0-7.948-4.595l-.496 1.938a14.985 14.985 0 0 1 7.012 4.053l1.432-1.396zM20.276 9.527a16.986 16.986 0 0 0-8.053 4.71l1.445 1.382a14.985 14.985 0 0 1 7.104-4.154l-.496-1.938zM8.226 21.07A17 17 0 0 0 7.5 26l2-.002c-.001-1.473.215-2.94.641-4.35l-1.914-.578zM7.5 25.998c0 1.567.212 3.086.61 4.53l1.928-.531a15.04 15.04 0 0 1-.538-3.999h-2zm4.774 11.814a16.985 16.985 0 0 0 7.989 4.654l.498-1.936a14.988 14.988 0 0 1-7.047-4.106l-1.44 1.388zM28.76 42.46a16.987 16.987 0 0 0 7.962-4.645l-1.438-1.39a14.985 14.985 0 0 1-7.026 4.099l.502 1.936zm12.13-11.935a17.02 17.02 0 0 0 .61-4.528l-2 .002a15.03 15.03 0 0 1-.538 3.996l1.928.53zm.61-4.527a16.98 16.98 0 0 0-.653-4.68l-1.922.549c.374 1.311.575 2.697.575 4.131h2z"
        fill="#072125"
      />
      <circle
        cx="10.5"
        cy="34"
        r="4"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="38.5"
        cy="34"
        r="4"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowDownLiesIcon = () => {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M30 24h7.172a2 2 0 0 1 1.414 3.414L25.414 40.586a2 2 0 0 1-2.828 0L9.414 27.414A2 2 0 0 1 10.828 24H18v-6h12v6z"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 6H18m12 6H18"
        stroke="#072125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export function LandingPage() {
  return (
    <Box>
      <Box>
        <Header />
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: '821px',
          backgroundColor: 'secondary.main',
          backgroundImage: `url(${Duck})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 150%',
          display: 'flex',
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: '715px',
            margin: '0 auto',
            padding: '0 1rem',
          }}
        >
          <Typography variant="microtitle" mb={2} sx={{ textAlign: 'center' }}>
            DOCpack, cloud storage per il packaging design
          </Typography>
          <Typography
            variant="h1"
            mb={4}
            sx={{ textAlign: 'center', color: '#fff' }}
          >
            Un nuovo modo di archiviare i tuoi progetti di packaging
          </Typography>
          <Typography variant="subtitle" sx={{ textAlign: 'center', mb: 10 }}>
            Archiviare, condividere e collaborare non è mai stato così facile.
            24h su 24h. Senza vincoli di dispositivo o sistema operativo.
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                sx={{ mr: 4 }}
                component={Link}
                to="register"
                data-testid="visit-registration-button"
                size="large"
              >
                Registrati
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="white"
                component={Link}
                to="login"
                data-testid="visit-login-button"
                size="large"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Grid
        container
        sx={{
          maxWidth: '1440px',
          margin: '0 auto',
          py: 6,
          textAlign: 'center',
        }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={12}
          md={4}
          mb={{ xs: 5, md: 0 }}
        >
          <ArchiveIcon />
          <Typography variant="microtitle" my={2}>
            Archiviazione file <br />
            di pack design
          </Typography>
          <Typography variant="body1">Con un semplice drag and drop</Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={12}
          md={4}
          mb={{ xs: 5, md: 0 }}
        >
          <SmartShareIcon />
          <Typography variant="microtitle" my={2}>
            Condivisione smart
          </Typography>
          <Typography variant="body1">
            Con i tuoi responsabili marketing,
            <br />
            buyer, clienti e fornitori
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={12}
          md={4}
        >
          <ArrowDownLiesIcon />
          <Typography variant="microtitle" my={2}>
            Upload, download
            <br />e invio
          </Typography>
          <Typography variant="body1">
            Dei magnifici 3: <br />
            foto 3D, layout ed esecutivo
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LandingPage;
