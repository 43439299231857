import { UserPermission, UserRole } from './enums';
import { UserDetailsDto } from './user-details.dto';

export interface CanAccessOptions {
  roles?: Array<UserRole>;
  permissions?: Array<UserPermission>;
}

export function userCan(options: CanAccessOptions, detail: UserDetailsDto) {
  let roleError, permissionError;

  // if there are no options all is good
  if (!options.permissions && !options.roles) {
    return;
  }

  if (options.roles) {
    if (detail.user_role && !options.roles.includes(detail.user_role)) {
      roleError = new Error(
        `Devi essere un utente ${options.roles.join(
          ' o '
        )} per accedere a questa funzione`
      );
    }
  }

  if (options.permissions) {
    if (
      !options.permissions.find((checkPermission) =>
        detail.user_permission?.find(
          (userPermission) => userPermission === checkPermission
        )
      )
    ) {
      permissionError = new Error(
        `Non hai i permessi per accedere a questa funzione : ${options.permissions.join(
          ','
        )}`
      );
    }
  }

  const hasError = [
    !options.roles || roleError,
    !options.permissions || permissionError,
  ].reduce((acc, costraint) => acc && costraint, true);

  if (hasError) {
    throw roleError || permissionError;
  }
}

export function safeUserCan(options: CanAccessOptions, detail: UserDetailsDto) {
  try {
    userCan(options, detail);
    return true;
  } catch (error) {
    return false;
  }
}
