import { Grid, Typography } from '@mui/material';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import { useMessagePopup, Stepper } from '@docpack/frontend/core-components';
import { useNavigate } from 'react-router-dom';
import { WorkspaceForm } from '@docpack/frontend/settings';
import WizardBox from '../component/wizard-box';
import steps from '../data-access/steps';
/* eslint-disable-next-line */

export function FrontendWizardFeaturePhaseThree() {
  const { data: user } = useGetCurrentUserInfo();
  const showMessage = useMessagePopup();
  const navigate = useNavigate();
  return (
    <WizardBox>
      <Stepper steps={steps} activeStep={2} />
      <Typography variant="h3" mb={5} textAlign={'center'}>
        Personalizza
      </Typography>
      <Typography mb={5} sx={{ padding: '0% 20%' }} textAlign={'center'}>
        Se non hai un logo in questo momento, o non riesci a caricarlo, non
        preoccuparti! Potrai caricarlo in seguito nelle impostazioni del tuo
        profilo
      </Typography>
      <Grid item sx={{ margin: 'auto' }}>
        <Grid>
          <WorkspaceForm
            functionButtons
            backLink={'/setup/fase-due'}
            forwardLink={'/setup/fase-quattro'}
            onSubmit={() =>
              new Promise(() => {
                /*showMessage('Logo salvato con successo!', 'success', () =>
                                window.location.reload()
                            );*/
                navigate('/setup/fase-quattro');
              })
            }
            defaultValues={{
              workspace_logo: user?.workspace?.workspace_logo,
            }}
          />
        </Grid>
      </Grid>
    </WizardBox>
  );
}

export default FrontendWizardFeaturePhaseThree;
