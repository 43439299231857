import { ContactDto } from '@docpack/shared/dtos';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RowDetails } from '@docpack/frontend/core-components';
export const customColumnsSizes: Array<string> = ['160px', '82px', '82px'];
export interface ContactItemProps {
  contact: ContactDto;
  selected: boolean;
  onClick: () => void;
}

export const ContactItem = (props: ContactItemProps) => {
  const details = [
    props.contact.email || '',
    props.contact.company_name || '',
    props.contact.phone || '',
  ];
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        p: '12px 0 12px 16px',
        borderStyle: 'solid',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        borderRadius: '8px',
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      <Grid item xs={6} container alignItems="center" flexWrap="nowrap">
        <Avatar
          sx={{ width: '32px', height: '32px', mr: { xs: 1, md: 2 } }}
          variant="circular"
          src={props.contact.image_contact?.link}
          alt={props.contact.first_name}
        />
        <Typography variant="body3">
          {`${props.contact.first_name} ${props.contact.last_name}`}
        </Typography>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end">
        <RowDetails
          type="content"
          details={details}
          customColumnsSizes={customColumnsSizes}
        />
      </Grid>
    </Grid>
  );
};

export default ContactItem;
