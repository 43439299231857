import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { UpdateUserSettingsDto, UserDto } from '@docpack/shared/dtos';

export function useUpdateUserInfo() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function updateUserInfo(data: UpdateUserSettingsDto) {
    const result = await fetcher(`/api/user/update-settings`, {
      method: 'PUT',
      body: data,
    });

    return handle<UserDto>(result);
  }

  return {
    error,
    updateUserInfo,
  };
}
