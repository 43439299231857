import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { UpdateBrandDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { InputText, UploadFile } from '@docpack/frontend/core-components';

export interface EditBrandFormProps {
  brand_uuid: string;
  cancelBtnOnClick: () => void;
  onSubmit: (data: UpdateBrandDto) => Promise<void>;
  formMethods: UseFormReturn<UpdateBrandDto, object>;
}

export function EditBrandForm(props: EditBrandFormProps) {
  return (
    <FormProvider {...props.formMethods}>
      <form onSubmit={props.formMethods.handleSubmit(props.onSubmit)}>
        <Stack spacing={2}>
          <InputText label="Nome" name="name" cy="edit-brand-name-input" />
          {props.formMethods.formState.errors.name?.message ? (
            <Alert severity="error">
              <Typography>
                {props.formMethods.formState.errors.name?.message}
              </Typography>
            </Alert>
          ) : null}
          <UploadFile
            label="Logo"
            name="asset_uuid"
            accept="image/*"
            brand_uuid={props.brand_uuid}
          />
          {props.formMethods.formState.errors.asset_uuid?.message ? (
            <Alert severity="error">
              <Typography>
                {props.formMethods.formState.errors.asset_uuid?.message}
              </Typography>
            </Alert>
          ) : null}
        </Stack>
        <Grid container sx={{ mt: '48px !important' }}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="text" onClick={props.cancelBtnOnClick}>
              Annulla
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              data-testid="edit-brand-submit"
            >
              Salva modifiche
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default EditBrandForm;
