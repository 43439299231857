import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { UpdateSupplierDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetSingleSupplier, useUpdateSupplier } from '../data-access';
import SupplierForm from '../components/supplier-form/supplier-form';
import { SuppliersListPageOutletContext } from './suppliers-list-page';

export const EditSupplierPage = () => {
  const outletContext = useOutletContext<SuppliersListPageOutletContext>();
  const params = useParams();
  const supplier_uuid = params['supplier_uuid'] || '';
  const { data } = useGetSingleSupplier(supplier_uuid);
  const { updateSupplier, error: errorEditSupplier } = useUpdateSupplier();
  const formMethods = useFormMethods(UpdateSupplierDto, true, {
    supplier_uuid: supplier_uuid,
    name: data?.name,
    code: data?.code,
  });
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal open={true} onClose={() => handleClose()}>
      <WhiteBoxModalContainer whiteBoxWith={450}>
        <AlertController
          message={
            errorEditSupplier
              ? { type: 'error', text: errorEditSupplier }
              : undefined
          }
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Modifica fornitore
        </Typography>
        <SupplierForm
          handleClose={() => handleClose()}
          onSubmit={(data) =>
            updateSupplier(data)
              .then(() => outletContext?.reloadSuppliers())
              .then(() =>
                showMessage(
                  'Fornitore modificato con successo!',
                  'success',
                  () => {
                    handleClose(true);
                  }
                )
              )
              .catch((error) => console.error(error))
          }
          formMethods={formMethods}
          isEdit
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
};

export default EditSupplierPage;
