import { WhiteBox } from '@docpack/frontend/core-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export function LandingPage() {
  const [queryParams] = useSearchParams();

  const success = queryParams.get('success') === 'true';
  const message = queryParams.get('message');
  const redirect_to = queryParams.get('redirect_to');

  useEffect(() => {
    if (redirect_to) {
      setTimeout(() => {
        window.location.href = redirect_to;
      }, 3000);
    }
  }, [redirect_to]);

  return (
    <Box sx={{ backgroundColor: 'gray.light', height: '100vh' }}>
      <Box margin="auto" width="80%" paddingTop={10}>
        <WhiteBox>
          <Typography
            textAlign="center"
            variant="h1"
            color={success ? 'success.main' : 'error.main'}
          >
            {success
              ? 'Operazione avvenuta con successo'
              : 'Operazione fallita'}
          </Typography>
          <Typography variant="body2" textAlign="center" marginTop={2}>
            {message}
          </Typography>
          <Box width={'100%'} marginTop={10}>
            {redirect_to ? (
              <Typography variant="body3" textAlign="center">
                Verrai reindirizzato a breve
              </Typography>
            ) : (
              <Box marginLeft="auto">
                <Button component={Link} to="/app/projects">
                  Vai alla Home
                </Button>
              </Box>
            )}
          </Box>
        </WhiteBox>
      </Box>
    </Box>
  );
}
