import useSwr, { Middleware } from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { ConnectToProjectDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

const makeNotRequestIfNoProjectUUID =
  (project_uuid?: string): Middleware =>
  (useSWRNext) => {
    return (key, fetcher, config) => {
      if (project_uuid) {
        return useSWRNext(key, fetcher, config);
      } else {
        return useSWRNext(key, null, config);
      }
    };
  };

export function useConnectToProject(project_uuid?: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const notRequestIfNoProjectUUID = makeNotRequestIfNoProjectUUID(project_uuid);

  const result = useSwr<ConnectToProjectDto>(
    `/api/project/connect/${project_uuid}`,
    fetcher,
    {
      use: [notRequestIfNoProjectUUID],
    }
  );

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
