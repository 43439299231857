// This is here because of this https://github.com/tinacms/tinacms/issues/277
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'react-dom/node_modules/@types/react';

import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

/* eslint-disable-next-line */
export interface ProjectsCountProps {
  filterProjectsCount: number;
  totalProjectsCount: number;
}

export function ProjectsCount(props: ProjectsCountProps) {
  const container = document.querySelector('[data-portal="projects-count"]');
  const theme = useTheme();

  if (!container) return null;

  return ReactDOM.createPortal(
    <Typography color={theme.palette.white.main} variant="caption">
      Progetti visualizzati: {props.filterProjectsCount} su{' '}
      {props.totalProjectsCount}
    </Typography>,
    container
  );
}

export default ProjectsCount;
