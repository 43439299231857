import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { BrandDto, NewBrandDto } from '@docpack/shared/dtos';

export function useNewBrand() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newBrand(data: NewBrandDto) {
    const result = await fetcher(`/api/brand/new`, {
      method: 'POST',
      body: data,
    });

    return handle<BrandDto>(result);
  }

  return {
    error,
    newBrand,
  };
}
