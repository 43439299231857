import {
  UpdateWorkspaceSettingsDto,
  WorkspaceSettingsDto,
} from '@docpack/shared/dtos';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import useSwr from 'swr';

export function useWorkspaceSettings() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  async function updateSettings(body: UpdateWorkspaceSettingsDto) {
    const result = await fetcher(`/api/workspace/settings`, {
      method: 'PUT',
      body,
    });

    return handle<WorkspaceSettingsDto>(result);
  }

  const result = useSwr<WorkspaceSettingsDto>(
    `/api/workspace/settings`,
    fetcher
  );

  const data = handle(result?.data);

  return {
    updateSettings,
    settings: data,
    error,
  };
}
