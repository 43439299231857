import { BrandItem, BrandItemSkeleton } from '../components';
import { Link, Outlet } from 'react-router-dom';
import { useGetBrands } from '../data-access/get-brands.hook';
import {
  AddButton,
  AlertController,
  DotsMenu,
  PlaceholderText,
  SearchBar,
  Toolbar,
  SelectOrderContainer,
  ToolbarLeftContainer,
  useEffectOnScrollEnd,
  useGetItemsSelected,
  useMessagePopup,
  useCheckBrand,
  PlanErrorModal,
} from '@docpack/frontend/core-components';
import { useUpdateBrand } from '../data-access/update-brand.hook';
import { unstable_batchedUpdates } from 'react-dom';
import { useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { SearchBrandsQueryDto } from '@docpack/shared/dtos';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';

export interface BrandsListPageOutletContext {
  reloadBrands: () => void;
  addBrandCallback: Function;
  requiredPlan?: string;
}

export function BrandsListPage() {
  const [order, setOrder] = useState<SearchBrandsQueryDto['orderBy']>('a-z');
  const [canCreate, setCanCreate] = useState<boolean | undefined>();
  const formMethods = useForm({ mode: 'onChange' });
  const [reqPlan, setRequiredPlan] = useState<string | undefined>();
  const checkBrand = useCheckBrand();
  const [isOpen, changeState] = useState<boolean>(false);

  useEffect(() => {
    if (checkBrand.data) {
      setRequiredPlan(checkBrand.data.requiredPlan);
      setCanCreate(checkBrand.data.canCreate);
    }
  }, [checkBrand]);

  const { error, data, mutate, isLoadingMore, nextPage } = useGetBrands({
    orderBy: order,
    search: formMethods.watch('search_text'),
    search_date_from: formMethods.watch('date_start'),
    search_date_to: formMethods.watch('date_end'),
  });

  const {
    itemsSelected,
    toggleItemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  } = useGetItemsSelected();
  const { deleteBrand, error: errorDeleteBrand } = useUpdateBrand();

  const showMessage = useMessagePopup();

  const allBrandsIds = useMemo(() => {
    return data?.map((brand) => brand.brand_uuid);
  }, [data]);

  useEffectOnScrollEnd(nextPage);

  const closeModal = () => {
    changeState(false);
  };

  //Reset selection when filters changes
  useEffect(() => {
    deselectAll();
  }, [order, deselectAll]);

  return (
    <>
      <AlertController
        message={
          errorDeleteBrand
            ? { type: 'error', text: errorDeleteBrand }
            : undefined
        }
      />
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      {!canCreate && reqPlan && (
        <PlanErrorModal
          requiredPlan={reqPlan}
          isOpen={isOpen}
          onClose={closeModal}
        />
      )}
      <SearchBar categoriesSelect formMethods={formMethods} />
      <Toolbar itemsSelectedCount={itemsSelected.length}>
        <ToolbarLeftContainer>
          <AddButton
            disabled={checkBrand.isValidating}
            linkTo={canCreate ? 'new' : ''}
            label={'Inserisci un nuovo marchio'}
            onClick={() => {
              if (!canCreate) changeState(true);
              checkBrand.mutate();
            }}
          />
        </ToolbarLeftContainer>
        <SelectOrderContainer>
          <Select
            value={order}
            onChange={(e) =>
              setOrder(e.target.value as SearchBrandsQueryDto['orderBy'])
            }
            label="A-Z"
          >
            <MenuItem value={'a-z'}>A-Z</MenuItem>
            <MenuItem value={'z-a'}>Z-A</MenuItem>
          </Select>
        </SelectOrderContainer>
        <Stack direction="row" spacing={3}>
          {itemsSelected.length === 1 && (
            <IconButton
              color="primary"
              component={Link}
              to={`edit/${itemsSelected[0]}`}
              data-testid="visit-edit-brand"
            >
              <EditIcon fontSize={'medium'} />
            </IconButton>
          )}
          {itemsSelected.length > 0 && (
            <IconButton
              color="primary"
              onClick={() => {
                const errors: { id: string; message?: string }[] = [];
                if (
                  window.confirm(
                    `Sei sicuro di voler eliminare ${itemsSelected.length} marchi?`
                  )
                ) {
                  Promise.all(
                    itemsSelected.map((brand_id) =>
                      deleteBrand(brand_id)
                        .then((res) => {
                          return { id: res ? brand_id : '' };
                        })
                        .catch((error) => {
                          return { id: '', message: error.message };
                        })
                    )
                  )
                    .then((ids) => {
                      unstable_batchedUpdates(() => {
                        ids.forEach((elm) => {
                          if (elm.id) {
                            removeItemsSelected(elm.id);
                          } else {
                            errors.push(elm);
                          }
                        });
                      });

                      if (errors.length > 0) {
                        let msg = '';
                        if (errors.length === 1) {
                          // Imposto un errore generico nel caso non sia tornato dall'api
                          msg =
                            "Si è verificato un errore durante l'eliminazione del marchio selezionato";
                          // se l'errore è uno mostro il primo specifico
                          if (errors[0].message) {
                            msg = errors[0].message;
                          }
                        } else {
                          msg =
                            'Alcuni dei marchi selezionati non possono essere eliminati in quanto hanno progetti o utenti collegati';
                          if (errors.length === itemsSelected.length) {
                            msg =
                              'I marchi selezionati non possono essere eliminati in quanto hanno progetti o utenti collegati';
                          }
                        }
                        showMessage(msg, 'error');
                      } else {
                        showMessage(
                          'Marchi eliminati con successo!',
                          'success'
                        );
                      }
                      mutate();
                    })
                    .then(() => checkBrand.mutate());
                }
              }}
              data-testid="delete-brand-button"
            >
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(allBrandsIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Stack>
        {data?.length ? (
          data?.map((brand) => {
            return (
              <BrandItem
                key={brand.brand_uuid}
                data={brand}
                selected={isSelected((item) => item === brand.brand_uuid)}
                onClick={() => toggleItemsSelected(brand.brand_uuid)}
              />
            );
          })
        ) : (
          <PlaceholderText>
            Clicca sul bottone in alto a sinistra
            <br />
            per aggiungere il tuo primo marchio
          </PlaceholderText>
        )}
        {isLoadingMore && <BrandItemSkeleton />}
      </Stack>
      <Outlet
        context={
          {
            reloadBrands: mutate,
            addBrandCallback: checkBrand.mutate, //checkCanAddBrand,
            requiredPlan: reqPlan,
          } as BrandsListPageOutletContext
        }
      />
    </>
  );
}

export default BrandsListPage;
