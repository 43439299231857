import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { ActivityFilterDto, SearchActivityDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetActivities(params: SearchActivityDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const offset = 30;

  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.set('search', params.search);
  }
  if (params.search_user_type) {
    queryParams.set('search_user_type', params.search_user_type);
  }
  if (params.search_date_from) {
    queryParams.set('search_date_from', params.search_date_from.toString());
  }
  if (params.search_date_to) {
    queryParams.set('search_date_to', params.search_date_to.toString());
  }
  if (params.page) {
    queryParams.set('page', params.page.toString());
  }
  if (params.offset) {
    queryParams.set('offset', params.offset.toString());
  } else {
    queryParams.set('offset', offset.toString());
  }

  const result = useSwr<ActivityFilterDto>(
    `/api/activities?${queryParams}`,
    fetcher
  );

  const data = handle(result.data);

  return {
    ...result,
    data,
    error,
    offset,
  };
}
