import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { ContactDto, UpdateContactDto } from '@docpack/shared/dtos';

export function useUpdateContact() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function updateContact(data: UpdateContactDto) {
    const result = await fetcher(`/api/contact/update`, {
      method: 'PUT',
      body: data,
    });

    return handle<ContactDto>(result);
  }

  return {
    error,
    updateContact,
  };
}
