import { IsNumber, IsOptional, IsString } from 'class-validator';
import { UserRole, UserType } from '../user-dtors';

export class SearchPlatformRegistrationsQueryDto {
  @IsString()
  @IsOptional()
  user_role?: UserRole;

  @IsString()
  @IsOptional()
  user_type?: UserType;

  @IsString()
  api_key?: string;
}
