import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { ContactDto, NewContactDto } from '@docpack/shared/dtos';

export function useNewContact() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newContact(data: NewContactDto) {
    const result = await fetcher(`/api/contact/new`, {
      method: 'POST',
      body: data,
    });

    return handle<ContactDto>(result);
  }

  return {
    error,
    newContact,
  };
}
