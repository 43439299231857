import { ProjectDto } from '../project-dtos';
import { UserDto } from '../user-dtors';

export enum LogTypes {
  DownloadFromEmail = 'Download From Email',
  Download = 'Download',
  SendEmail = 'Send Email',
  Login = 'Login',
  CreateUser = 'Create User',
  DeleteUser = 'Delete User',
}

export class ActivityDto {
  ip_address: string;
  activity_uuid: string;
  event_type: LogTypes;
  event_email?: string;
  event_files_uuid?: Array<string>;
  user_uuid?: string;
  project_uuid?: string;
  event_user_uuid?: string;
  event_username?: string;
  event_email_cc?: string;
  event_email_ccn?: string;
  shared_to_email?: string;
  created_at: Date;
  updated_at: Date;
  user?: UserDto;
  project?: ProjectDto;
}
