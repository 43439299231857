import {
  InfoTooltip,
  useOptionsCategories,
  useOptionsSubcategories,
} from '@docpack/frontend/core-components';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export interface AutocompleteCategoryProps {
  name: string;
  subCategoryFieldName: string;
  label: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
}

export function AutocompleteCategory(props: AutocompleteCategoryProps) {
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  const subCategoryField = useController({
    name: props.subCategoryFieldName,
  });

  const { data: options } = useOptionsCategories();

  const safeOptions = options || [];

  // Reset the subcategory field when the category changes
  useEffect(() => {
    if (fieldState.isDirty) {
      subCategoryField.field.onChange(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Autocomplete
          disablePortal
          id={`select-autocomplete-${props.name}`}
          options={safeOptions}
          onChange={(e, item) => field.onChange(item?.value)}
          value={field.value || ''}
          renderInput={(params) => (
            <TextField {...params} label={props.label} />
          )}
          sx={{ width: '100%' }}
          disabled={props.disabled}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          isOptionEqualToValue={(option, value) => {
            return option.value === value;
          }}
          getOptionLabel={(selected) =>
            safeOptions.find((opt) => opt.value === selected)?.label || ''
          }
          filterOptions={(options, { inputValue }) =>
            options.filter((opt) =>
              opt.label.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
        />
        {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
      </Stack>
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}

export interface AutocompleteSubCategoryProps {
  name: string;
  categoryFieldName: string;
  label: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
}

export function AutocompleteSubCategory(props: AutocompleteSubCategoryProps) {
  const formMethods = useFormContext();
  const { field, fieldState } = useController({
    name: props.name,
    rules: { required: props.required },
  });

  const watchCategoryUuid = formMethods.watch(props.categoryFieldName);
  const { data: options } = useOptionsSubcategories(watchCategoryUuid);

  const safeOptions = options || [];

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Autocomplete
          disablePortal
          id={`select-autocomplete-${props.name}`}
          options={safeOptions}
          onChange={(e, item) => field.onChange(item?.value)}
          renderInput={(params) => (
            <TextField {...params} label={props.label} />
          )}
          sx={{ width: '100%' }}
          disabled={!watchCategoryUuid || props.disabled}
          value={field.value || ''}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(selected) =>
            safeOptions.find((opt) => opt.value === selected)?.label || ''
          }
          filterOptions={(options, { inputValue }) =>
            options.filter((opt) =>
              opt.label.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
        />
        {props.tooltipText && <InfoTooltip text={props.tooltipText} />}
      </Stack>
      {fieldState.error?.message && (
        <Alert severity="error">
          <Typography>{fieldState.error?.message}</Typography>
        </Alert>
      )}
    </>
  );
}
