import { useFetcher } from '@docpack/frontend/client-authorization';
import { NoteDto, WorkspaceDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useSetFirstLogin() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function firstLoginCompleted() {
    const result = await fetcher(`/api/workspace/settings/firstlogin`, {
      method: 'GET',
    });

    return handle<WorkspaceDto>(result);
  }

  return {
    firstLoginCompleted,
    error,
  };
}
