import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CategoryDto, SubCategoryDto } from '@docpack/shared/dtos';
import Skeleton from '@mui/material/Skeleton';

export interface CategoryItemProps {
  data: CategoryDto | SubCategoryDto;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  type: 'category' | 'subcategory';
}

const AngleDownIcon = () => {
  return (
    <svg width="11" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.211.206A.697.697 0 0 0 0 .7a.68.68 0 0 0 .211.495l4.783 4.642A.575.575 0 0 0 5.4 6a.588.588 0 0 0 .406-.163l4.782-4.642a.684.684 0 0 0 0-.989.735.735 0 0 0-1.018 0L5.397 4.25 1.224.2A.733.733 0 0 0 .211.206z"
        fill="#242628"
      />
    </svg>
  );
};

const NestedItemIcon = () => {
  return (
    <svg width="43" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke="#000" strokeWidth="1.2" d="M.6 0v15m42.4.6H0" />
    </svg>
  );
};

export function CategoryItem(props: CategoryItemProps) {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        padding: { xs: '16px', md: '16px 32px' },
        borderStyle: 'solid',
        borderWidth: `${props.selected ? '3px' : '2px'}`,
        borderColor: `${props.selected ? 'secondary.main' : 'gray.light'}`,
        boxShadow: `${
          props.selected ? '0 3px 10px 0 rgba(241, 144, 35, 0.5)' : 'none'
        }`,
        borderRadius: '8px',
        mb: 1,
        cursor: 'pointer',
      }}
      onClick={props.onClick}
      data-testid="brand-item"
    >
      <Grid item xs={8} lg={9} container alignItems="center">
        {props.type === 'category' ? <AngleDownIcon /> : <NestedItemIcon />}
        <Typography
          variant="button"
          data-testid="brand-item-name"
          sx={{ marginLeft: '1rem' }}
        >
          {props.data.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        lg={3}
        container
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Typography variant="body1">
          {props.data.projects_count === 1
            ? `1 progetto`
            : `${props.data.projects_count} progetti`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function ProjectItemSkeleton() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        p: '16px 32px',
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'gray.light',
        borderRadius: '8px',
        mb: 1,
      }}
    >
      <Skeleton variant="rectangular" width={50} height={50} />
      <Skeleton width="calc(100% - 90px)" style={{ marginLeft: 20 }} />
    </Grid>
  );
}

export default CategoryItem;
