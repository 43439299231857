import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import {
  AlertController,
  useShowMessage,
  Stepper,
} from '@docpack/frontend/core-components';
import Duck from '../../../../registration-feature/src/lib/components/not-logged-in-shell/duck.svg';

export function WizardBox(props: PropsWithChildren<any>) {
  const theme = useTheme();
  const activeStep = props.activeStep || 1;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        paddingBottom: '4%',
        backgroundColor: 'secondary.main',
        backgroundImage: `url(${Duck})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 130%',
        alignContent: 'center',
        pt: '4%',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          ml: 'auto',
          mr: 'auto',
          maxWidth: {
            xs: 'calc(100% - 32px)',
            sm: 'calc(70% - 32px)',
            md: 'calc(70% - 32px)',
          },
          p: {
            xs: '30px 40px',
            sm: '30px 40px',
            md: '32px 40px',
            lg: '32px 40px',
          },
          borderRadius: '24px',
        }}
      >
        {/*<Stepper steps={steps} activeStep={activeStep}/>*/}
        <Stack>{props.children}</Stack>
      </Box>
    </Box>
  );
}

export default WizardBox;
