import { useController, useFormContext } from 'react-hook-form';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import { useEffect, useState } from 'react';
import {
  useConnectToProject,
  useGetNextCodesForYear,
  useGetProjects,
} from '../../data-access';
import { ProjectDto } from '@docpack/shared/dtos';
import {
  AlertController,
  InfoTooltip,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';

export interface ProjectSelectInterface {
  onProjectChange: (project: ProjectDto | null) => void;
}

// Autocomplete component for the selection and search of a project
export function ProjectSelect(props: ProjectSelectInterface) {
  const { data, error } = useGetProjects({});

  return (
    <>
      <Autocomplete
        options={data ? data : []}
        autoComplete
        onChange={(_, value) => {
          props.onProjectChange(value);
        }}
        getOptionLabel={(option) => `${option.name} - ${option.project_code}`}
        renderInput={(params) => (
          <TextField {...params} label="Cerca progetto esistente" />
        )}
      />
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
    </>
  );
}

export interface ConnectProjectInputProps {
  isNew?: boolean;
  disabled?: boolean;
  job_disabled?: boolean;
}

export function ConnectProjectInput(props: ConnectProjectInputProps) {
  const form = useFormContext();
  const projectCodeYearController = useController({
    name: 'project_code_year',
  });

  const projectCodeCodeController = useController({
    name: 'project_code_code',
  });

  const projectCodeSequentialController = useController({
    name: 'project_code_sequential',
  });

  // Manage the state of the connect to project modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectDto | null>(
    null
  );

  // This will fetch the codes for the related project
  const { data: connectedProject, error } = useConnectToProject(
    selectedProject?.project_uuid
  );

  // Removed because related to the year selecton
  // Calculate the years options based on the value of the field
  // const yearsOptions = useMemo(() => {
  //   const currentYear =
  //     Number(projectCodeYearController.field.value) || new Date().getFullYear();

  //   return Array(5)
  //     .fill(null)
  //     .map((_, index) => `${currentYear + (-2 + index)}`);
  // }, [projectCodeYearController.field.value]);

  // This one getch new default codes only when the year changes
  const { data: defaultCodes, error: defaultCodesError } =
    useGetNextCodesForYear(Number(projectCodeYearController.field.value));

  // When there are new default codes use those one as a default value
  useEffect(() => {
    if (
      defaultCodes?.project_code_code &&
      defaultCodes?.project_code_sequential &&
      // Only preselect the field if is creating a new entity
      props.isNew
    ) {
      form.setValue('project_code_code', defaultCodes?.project_code_code, {
        shouldDirty: false,
      });
      form.setValue(
        'project_code_sequential',
        defaultCodes?.project_code_sequential,
        { shouldDirty: false }
      );
    }
    // This is here because we do not want to react when projectCodeCodeController and projectCodeSequentialController fields changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultCodes?.project_code_code,
    defaultCodes?.project_code_sequential,
    props.isNew,
    //form,
  ]);

  return (
    <>
      <AlertController
        message={error ? { text: error, type: 'error' } : undefined}
      />
      <AlertController
        message={
          defaultCodesError
            ? { text: defaultCodesError, type: 'error' }
            : undefined
        }
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        {/*
        Removed to avoid the user to enter the year with a select
        <Select
          required
          disabled
          style={{ width: 100 }}
          {...projectCodeYearController.field}
          error={!!projectCodeYearController.fieldState.error}
        >
          {yearsOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select> */}
        <TextField
          disabled={props.job_disabled}
          {...(projectCodeYearController.field || '')}
          error={!!projectCodeYearController.fieldState.error}
        />
        <TextField
          disabled={props.job_disabled}
          {...(projectCodeCodeController.field || '')}
          error={!!projectCodeCodeController.fieldState.error}
          data-testid="project-code-code-input"
        />
        <TextField
          disabled={props.job_disabled}
          {...(projectCodeSequentialController.field || '')}
          error={!!projectCodeSequentialController.fieldState.error}
          data-testid="project-code-sequential-input"
        />

        <InfoTooltip
          alignVerticalTop
          text="Codice generato automaticamente dal programma per catalogare i progetti, seguendo la logica: anno_numero progressivo progetto."
        />
        {/*<Button
          variant="text"
          style={{ width: '100%' }}
          onClick={() => setModalOpen(true)}
          disabled={props.disabled}
        >
          <Typography variant="body3" style={{ textDecoration: 'underline' }}>
            Aggiungi a progetto esistente
          </Typography>
        </Button>
        <InfoTooltip
          alignVerticalTop
          text="Se vuoi aggiungere un nuovo progetto e abbinarlo a un codice progetto esistente, seleziona il codice progetto da questa finestra e il sistema lo abbinerà in automatico. Questa soluzione ti permette di raggruppare più progetti sotto un unico codice progetto."
      />*/}
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <WhiteBoxModalContainer whiteBoxWith={500}>
            <Typography variant="body3" mb={2} sx={{ display: 'block' }}>
              Cerca progetto esistente
            </Typography>
            <ProjectSelect onProjectChange={setSelectedProject} />
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              marginTop={4}
            >
              <Button onClick={() => setModalOpen(false)}>Annulla</Button>
              <Button
                variant="contained"
                disabled={!connectedProject}
                onClick={() => {
                  if (connectedProject) {
                    projectCodeYearController.field.onChange(
                      connectedProject.project.project_code_year
                    );
                    projectCodeCodeController.field.onChange(
                      connectedProject.project.project_code_code
                    );
                    projectCodeSequentialController.field.onChange(
                      connectedProject.project_code_sequential
                    );
                    setModalOpen(false);
                  }
                }}
              >
                Collega
              </Button>
            </Stack>
          </WhiteBoxModalContainer>
        </Modal>
      </Stack>
      {projectCodeYearController.fieldState.error?.message && (
        <Alert severity="error">
          <Typography>
            {projectCodeYearController.fieldState.error?.message}
          </Typography>
        </Alert>
      )}
      {projectCodeCodeController.fieldState.error?.message && (
        <Alert severity="error">
          <Typography>
            {projectCodeCodeController.fieldState.error?.message}
          </Typography>
        </Alert>
      )}
      {projectCodeSequentialController.fieldState.error?.message && (
        <Alert severity="error">
          <Typography>
            {projectCodeSequentialController.fieldState.error?.message}
          </Typography>
        </Alert>
      )}
    </>
  );
}
