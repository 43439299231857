import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { CategoryDto, NewCategoryDto } from '@docpack/shared/dtos';

export function useNewCategory() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newCategory(data: NewCategoryDto) {
    const result = await fetcher(`/api/project/categories/new`, {
      method: 'POST',
      body: data,
    });

    return handle<CategoryDto>(result);
  }

  return {
    error,
    newCategory,
  };
}
