import { Route, Routes } from 'react-router-dom';
import {
  LandingPage,
  RegisterPage,
  LoginPage,
  CheckCodePage,
  ResetPasswordPage,
  ForgetPasswordPage,
  ResetSuccessPage,
} from './pages';
import { NotLoggedInShell, RedirectToAppShell } from './components';

export function RegistrationRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RedirectToAppShell />}>
        <Route index element={<LandingPage />} />
        <Route path="" element={<NotLoggedInShell />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="register/check-code/:user_uuid"
            element={<CheckCodePage />}
          />
          <Route path="forgot-password" element={<ForgetPasswordPage />} />
          <Route path="reset-success" element={<ResetSuccessPage />} />
        </Route>
        <Route path="" element={<NotLoggedInShell blank />}>
          <Route path="register" element={<RegisterPage />} />
        </Route>
        <Route path="/reset/:token" element={<ResetPasswordPage />} />
      </Route>
    </Routes>
  );
}
