import Popover from '@mui/material/Popover';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { ReactNode, useState } from 'react';

export interface DotsMenuProps {
  children(options: { close: () => void }): ReactNode;
  hideOnMobile?: boolean;
}

export const DotsMenu = (props: DotsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          display: { xs: props.hideOnMobile ? 'none' : 'flex', md: 'flex' },
        }}
      >
        <MoreVertRoundedIcon fontSize={'medium'} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': { borderRadius: '20px', padding: '20px' },
        }}
      >
        <Stack>{props.children({ close: handleClose })}</Stack>
      </Popover>
    </>
  );
};

export default DotsMenu;
