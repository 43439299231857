import { forwardRef, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

export interface WhiteBoxInterface {
  whiteBoxWith?: string | number;
  sx?: SxProps<Theme> | undefined;
  whiteBoxMd?: string;
  heightAuto?: boolean;
}

export const WhiteBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<WhiteBoxInterface>
>((props, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white.main',
        width: props.whiteBoxWith ? props.whiteBoxWith : 'auto',
        maxHeight: '100vh',
        overflow: 'auto',
        p: {
          xs: '40px 30px',
          md: props.whiteBoxMd ? props.whiteBoxMd : '70px 90px 40px 90px',
        },
        borderRadius: '24px',
        boxShadow: '0px 7px 12px rgba(0, 0, 0, 0.1)',
        height: props.heightAuto ? 'auto' : '100%',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
});
