import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { UserDto } from '@docpack/shared/dtos';

export function useGetCurrentUserInfo() {
  const fetcher = useFetcher();

  const result = useSwr<UserDto>(`/api/user/me`, fetcher);

  return result;
}
