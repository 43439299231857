import { useOutletContext, useParams } from 'react-router-dom';
import { useGetSingleProject } from '../data-access/get-single-project.hook';
import { EditProjectForm } from '../components';
import { ProjectsListPageOutletContext } from './projects-list-page';
import { useUpdateProject } from '../data-access';
import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { SkeletonProjectForm } from './new-project-page';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { UpdateProjectDto } from '@docpack/shared/dtos';

export function EditProjectPage() {
  const params = useParams();
  const project_uuid = params['project_uuid'] || '';
  const { data, mutate } = useGetSingleProject(project_uuid);

  const outletContext = useOutletContext<ProjectsListPageOutletContext>();
  const { updateProject, error } = useUpdateProject();
  const formMethods = useFormMethods(UpdateProjectDto, !!data, {
    project_uuid: data?.project_uuid,
    name: data?.name,
    ean: data?.ean,
    internal_code: data?.internal_code,
    keywords: data?.keywords,
    picture_asset_uuid: data?.picture?.asset_uuid,
    layout_asset_uuid: data?.layout?.asset_uuid,
    executive_asset_uuid: data?.executive?.asset_uuid,
    created_at: data?.created_at.toLocaleString(),
    brand_uuids: data?.brands?.map((brand) => brand.brand_uuid) || [],
    project_code_code: data?.project_code_code,
    project_code_sequential: data?.project_code_sequential,
    project_code_year: data?.project_code_year,
    weight: data?.weight,
    category_uuid: data?.category?.category_uuid,
    sub_category_uuid: data?.subCategory?.sub_category_uuid,
    supplier_uuid: data?.suppliers?.supplier_uuid,
  });
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      data-testid="edit-project-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={800}>
        <AlertController
          message={error ? { type: 'error', text: error } : undefined}
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Modifica progetto
        </Typography>
        {data ? (
          <EditProjectForm
            project_uuid={project_uuid}
            cancelBtnOnClick={() => handleClose()}
            onSubmit={(values) =>
              updateProject({
                project_uuid: project_uuid,
                keywords: values.keywords,
                brand_uuids: values.brand_uuids,
                name: values.name,
                created_at: values.created_at,
                ean: values.ean,
                internal_code: values.internal_code,
                layout_asset_uuid: values.layout_asset_uuid,
                executive_asset_uuid: values.executive_asset_uuid,
                picture_asset_uuid: values.picture_asset_uuid,
                project_code_code: values.project_code_code,
                project_code_sequential: values.project_code_sequential,
                project_code_year: values.project_code_year,
                weight: values.weight,
                category_uuid: values.category_uuid,
                sub_category_uuid: values.sub_category_uuid,
                supplier_uuid: values.supplier_uuid,
              })
                .then(() => {
                  mutate();
                  if (outletContext?.reloadProjects) {
                    outletContext.reloadProjects();
                  }
                })
                .then(() =>
                  showMessage(
                    'Progetto modificato correttamente',
                    'success',
                    () => handleClose(true)
                  )
                )
                .catch((error) => {
                  console.error('Error fetching data: ', error);
                })
            }
            formMethods={formMethods}
          />
        ) : (
          <SkeletonProjectForm />
        )}
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default EditProjectPage;
