import { Grid, Typography, Stack, Alert, Button } from '@mui/material';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import { useMessagePopup, Stepper } from '@docpack/frontend/core-components';
import { Navigate, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WizardBox from '../component/wizard-box';
import { FormProvider } from 'react-hook-form';
import { NewBrandDto } from '@docpack/shared/dtos';
import { Link as RouterLink } from 'react-router-dom';
import { useNewBrand } from '@docpack/frontend/brands';
import {
  UploadFile,
  InputText,
  useHandleClose,
  useFormMethods,
} from '@docpack/frontend/core-components';
import { useGetBrands } from 'libs/frontend/brands/src/lib/data-access/get-brands.hook';
import { useSetFirstLogin } from '@docpack/frontend/settings';
/* eslint-disable-next-line */
import steps from '../data-access/steps';

export function FrontendWizardFeaturePhaseFour() {
  const { data: user } = useGetCurrentUserInfo();
  const showMessage = useMessagePopup();
  const navigate = useNavigate();
  const { firstLoginCompleted } = useSetFirstLogin();
  const { newBrand } = useNewBrand();
  const formMethods = useFormMethods(NewBrandDto);
  const { data, isLoadingMore } = useGetBrands({});

  if (!isLoadingMore) {
    if (data && data.length == 1)
      return <Navigate to="/setup/fase-cinque" replace />;
  }
  return (
    <WizardBox>
      <Stepper steps={steps} activeStep={3} />
      <Typography variant="h3" mb={5} textAlign={'center'}>
        Marchio
      </Typography>
      <Typography mb={5} sx={{ padding: '0% 20%' }} textAlign={'center'}>
        Frase di spiegazione cos'è un marchio
      </Typography>
      <Grid item sx={{ margin: 'auto' }}>
        <Grid>
          {' '}
          {/**inizio grid */}
          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit((values: NewBrandDto) =>
                newBrand(values)
                  .then(() => {
                    navigate(`/setup/fase-cinque`);
                  })
                  /* .then(() => {
                        showMessage('Marchio creato con successo!', 'success');
                        })*/
                  .catch((error) => {
                    console.error('Error fetching data: ', error);
                  })
              )}
            >
              <Stack spacing={2}>
                <InputText
                  label="Nome"
                  name="name"
                  required
                  tooltipText="Inserendo il nome di un marchio ed eventualmente la sua immagine potrai decidere di abbinare i progetti al marchio di riferimento. Questa soluzione ti permette di agevolare la ricerca di un progetto"
                  cy="brand-name-input"
                />
                {formMethods.formState.errors.name?.message ? (
                  <Alert severity="error">
                    <Typography>
                      {formMethods.formState.errors.name?.message}
                    </Typography>
                  </Alert>
                ) : null}
                <UploadFile
                  label="Logo"
                  name="asset_uuid"
                  accept="image/*"
                  required
                  tooltipText="Se lo desideri puoi caricare l’immagine del marchio. Formato file supportati: .jpg, .png. Dimensioni massime 1Mb"
                  cy="brand-logo-input"
                  wizard={true}
                />
                {formMethods.formState.errors.asset_uuid?.message ? (
                  <Alert severity="error">
                    <Typography>
                      {formMethods.formState.errors.asset_uuid?.message}
                    </Typography>
                  </Alert>
                ) : null}
                <div>
                  <Button
                    variant="outlined"
                    sx={{ mt: 4, ml: 'auto', mr: '3rem' }}
                    component={RouterLink}
                    to={'/setup/fase-tre'}
                    startIcon={<ArrowBackIcon />}
                  >
                    Indietro
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 4, mr: 'auto' }}
                    data-testid="add-brand-submit-button"
                  >
                    Salva
                  </Button>
                </div>
                <Button
                  variant="text"
                  sx={{ mt: 4, ml: 'auto', mr: 'auto' }}
                  onClick={async () => {
                    await firstLoginCompleted();
                    navigate('/app/projects');
                  }}
                >
                  Salta per ora
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Grid>{' '}
        {/** fine grid */}
      </Grid>
    </WizardBox>
  );
}

export default FrontendWizardFeaturePhaseFour;
