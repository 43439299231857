import useSWRInfinite from 'swr/infinite';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { BrandDto, SearchBrandsQueryDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { useInfiniteScrollUtils } from '@docpack/frontend/core-components';

export function useGetBrands(filter?: SearchBrandsQueryDto) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const params = new URLSearchParams();

  if (filter?.orderBy) {
    params.append('orderBy', filter?.orderBy);
  }

  if (filter?.search) {
    params.append('search', filter?.search);
  }

  if (filter?.search_date_from) {
    params.append('search_date_from', filter?.search_date_from.toString());
  }

  if (filter?.search_date_to) {
    params.append('search_date_to', filter?.search_date_to.toString());
  }

  const result = useSWRInfinite<Array<BrandDto>>((index) => {
    params.set('page', index.toString());
    return `/api/brand?${params}`;
  }, fetcher);

  const resultUtils = useInfiniteScrollUtils(result, handle);

  return {
    ...result,
    ...resultUtils,
    error,
  };
}
