import { SubCategoryDto } from '@docpack/shared/dtos';
import { useCallback, useEffect, useState } from 'react';

export type CategoriesSelection = {
  id: string;
  type: 'category' | 'subcategory';
  subCategories?: Array<SubCategoryDto>;
};

function getInitialState(key?: string) {
  if (!key) {
    return [];
  }
  const localitem = localStorage.getItem(key);
  if (localitem) {
    const list = JSON.parse(localitem);
    return list;
  }
  return [];
}

export function useGetCategoriesSelected(key?: string) {
  const [categoriesSelected, setCategoriesSelected] = useState<
    Array<CategoriesSelection>
  >(getInitialState(key));

  const toggleCategoriesSelected = useCallback(
    (category: CategoriesSelection) => {
      setCategoriesSelected((oldItems) => {
        if (
          oldItems.find((item: CategoriesSelection) => item.id === category.id)
        ) {
          return oldItems.filter(
            (item: CategoriesSelection) => item.id !== category.id
          );
        } else {
          return [...oldItems, { id: category.id, type: category.type }];
        }
      });
    },
    []
  );

  const isCategorySelected = useCallback(
    (findFunc: (value: CategoriesSelection) => boolean) => {
      return categoriesSelected.find(findFunc) ? true : false;
    },
    [categoriesSelected]
  );

  const removeCategoriesSelected = useCallback((id: string) => {
    setCategoriesSelected((oldItems) =>
      oldItems.filter((item: CategoriesSelection) => item.id !== id)
    );
  }, []);

  const selectAll = useCallback((ids: Array<CategoriesSelection>) => {
    setCategoriesSelected(ids);
  }, []);

  const deselectAll = useCallback(() => {
    setCategoriesSelected([]);
  }, []);

  useEffect(() => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(categoriesSelected));
    }
  }, [categoriesSelected, key]);

  return {
    toggleCategoriesSelected,
    categoriesSelected,
    isCategorySelected,
    removeCategoriesSelected,
    selectAll,
    deselectAll,
  };
}
