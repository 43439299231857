import { Route, Routes } from 'react-router-dom';
import EditUserPage from './pages/edit-user-page';
import NewUserPage from './pages/new-user-page';
import UsersListPage from './pages/users-list-page';

export function UsersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<UsersListPage />}>
        <Route path="new" element={<NewUserPage />} />
        <Route path="edit/:user_uuid" element={<EditUserPage />} />
      </Route>
    </Routes>
  );
}
