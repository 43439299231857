import { createContext, useContext, useRef, useState } from 'react';
import { PopupMessage } from '../components';

export interface MessagePopupContext {
  showMessage: (
    message: string,
    type: 'success' | 'error',
    callBack?: () => void
  ) => void;
  message?: string | undefined;
}

const PopupMessageContext = createContext<MessagePopupContext | undefined>(
  undefined
);

export function PopupMessageContainer(props: { children: React.ReactNode }) {
  const [message, setMessage] = useState<string | undefined>();
  const [type, setType] = useState<'success' | 'error'>('success');

  const callbackRef = useRef<Array<() => void>>([]);

  return (
    <PopupMessageContext.Provider
      value={{
        message,
        showMessage: (message, type, callBack) => {
          setMessage(message);
          setType(type);
          if (callBack && !callbackRef.current.includes(callBack)) {
            callbackRef.current.push(callBack);
          }
        },
      }}
    >
      {message && (
        <PopupMessage
          message={message}
          type={type}
          close={() => {
            callbackRef.current.forEach((cb) => cb());
            callbackRef.current = [];
            setMessage(undefined);
          }}
        />
      )}
      {props.children}
    </PopupMessageContext.Provider>
  );
}

export function useMessagePopup() {
  const context = useContext(PopupMessageContext);

  const def = () => undefined;

  return context?.showMessage || def;
}
