import { RefObject, useEffect } from 'react';

const throttleTimeout = 100;
const minDistanceToTheEndOfTheElement = 100;

export function useEffectOnScrollEnd(
  onCallback: () => void,
  onItem?: RefObject<HTMLElement>
) {
  useEffect(() => {
    // By default target the document
    let target: Pick<HTMLElement, 'addEventListener' | 'removeEventListener'> =
      document;

    if (onItem?.current) {
      target = onItem?.current;
    }

    let oldTimeout: number | undefined;

    function handleScroll(ev: Event) {
      if (!onItem) {
        const documentHeight = document.body.scrollHeight;
        const currentScroll = window.scrollY + window.innerHeight;

        if (currentScroll + minDistanceToTheEndOfTheElement > documentHeight) {
          if (oldTimeout) {
            window.clearTimeout(oldTimeout);
          }
          oldTimeout = window.setTimeout(() => {
            onCallback();
          }, throttleTimeout);
        }
      } else {
        const { scrollTop, scrollHeight, clientHeight } =
          ev.target as HTMLElement;

        if (
          scrollTop + clientHeight >=
          scrollHeight - minDistanceToTheEndOfTheElement
        ) {
          if (oldTimeout) {
            window.clearTimeout(oldTimeout);
          }
          oldTimeout = window.setTimeout(() => {
            onCallback();
          }, throttleTimeout);
        }
      }
    }

    target.addEventListener('scroll', handleScroll);

    return () => {
      if (oldTimeout) {
        window.clearTimeout(oldTimeout);
      }
      target.removeEventListener('scroll', handleScroll);
    };
  }, [onItem, onCallback]);
}
