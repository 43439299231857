import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

export function NewProjectBox() {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        borderRadius: '24px',
        padding: '40px',
        height: '100%',
        maxHeight: '272px',
      }}
    >
      <Stack spacing="48px" justifyContent="center" sx={{ height: '100%' }}>
        <Button component={Link} to="new" data-testid="add-project-button">
          <AddIcon sx={{ fontSize: '72px' }} color="white" />
        </Button>
        <Typography variant="button" color="white.main" textAlign="center">
          Inserisci nuovo progetto
        </Typography>
      </Stack>
    </Box>
  );
}
