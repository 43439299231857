import useSwr from 'swr';

import { useFetcher } from '@docpack/frontend/client-authorization';
import { CategoryDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useOptionsSubcategories(category_uuid?: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<CategoryDto>(
    `/api/project/categories/${category_uuid}`,
    category_uuid ? fetcher : null
  );

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data: data?.subCategories?.map((subcategory) => ({
      label: subcategory.name,
      value: subcategory.sub_category_uuid,
    })),
  };
}
