import { InputText, useMessagePopup } from '@docpack/frontend/core-components';
import { NoteDto, UpdateNoteDto } from '@docpack/shared/dtos';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeleteNote } from '../data-access/delete-note.hook';

export interface NoteItemProps {
  onSubmit: (data: UpdateNoteDto) => Promise<NoteDto | undefined | void>;
  defaultValues?: UpdateNoteDto;
  data: NoteDto;
  key: string;
  index: number;
  openTab: number;
  selected?: boolean;
  onClick: () => void;
  onClose: () => void;
  afterDelete: () => void;
}

export function NoteItem(props: NoteItemProps) {
  const resolver = classValidatorResolver(UpdateNoteDto);
  const formMethods = useForm<UpdateNoteDto>({
    resolver,
    defaultValues: props.data,
  });
  const { deleteNote } = useDeleteNote(props.data.user_notes_uuid);
  const showMessage = useMessagePopup();

  return (
    <Grid
      container
      key={props.data.user_notes_uuid}
      alignItems="center"
      sx={{
        padding: { xs: '0 0 12px' },
        display: 'flex',
        justifyContent: 'space-between',
        ':hover': {
          '& .note_msg': {
            color: `${props.openTab === -1 && 'secondary.dark'}`,
            cursor: `${props.openTab === -1 && 'pointer'}`,
          },
          '& .note_icons': {
            visibility: [
              `${props.openTab === -1 ? 'visible' : 'hidden'}`,
              `${props.openTab !== props.index ? 'visible' : 'hidden'}`,
            ],
          },
        },
      }}
    >
      <Grid item xs={8} lg={9} alignItems="center">
        <Typography
          variant="overline"
          sx={{ color: `${props.openTab === props.index && 'secondary.dark'}` }}
          className="note_msg"
          onClick={props.onClick}
        >
          [+] {props.data.title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        lg={3}
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="nowrap"
        className="note_icons"
        data-testid="settings-icons-note"
        sx={{
          visibility: [
            `${props.openTab === -1 ? 'visible' : 'hidden'}`,
            'hidden',
          ],
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <EditIcon
          fontSize={'medium'}
          sx={{ cursor: 'pointer' }}
          onClick={props.onClick}
          data-testid="settings-edit-note"
        />
        <DeleteIcon
          fontSize={'medium'}
          sx={{ marginLeft: '16px', cursor: 'pointer' }}
          data-testid="settings-delete-note"
          onClick={(e) => {
            e.stopPropagation();
            if (
              window.confirm(
                `Sei sicuro di voler eliminare la nota "${props.data.title}"?`
              )
            ) {
              deleteNote(props.data.user_notes_uuid)
                .then(() => props.afterDelete)
                .then(() =>
                  showMessage('Nota eliminata con successo!', 'success')
                );
            }
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        alignItems="center"
        display={props.openTab === props.index ? 'block' : 'none'}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>
            <Stack mb={1}>
              <Typography
                variant="caption"
                mb={0.5}
                data-testid="settings-note-title"
              >
                Titolo
              </Typography>
              <InputText
                name="title"
                small={true}
                fullWidth={true}
                sx={{
                  borderRadius: '0.5rem',
                  border: '1px solid gray.main',
                  width: '100%',
                }}
              />
            </Stack>
            <Stack>
              <Typography
                variant="caption"
                mb={0.5}
                data-testid="settings-note-text"
              >
                Testo
              </Typography>
              <InputText
                name="text"
                small={true}
                textarea={true}
                fullWidth={true}
                sx={{
                  borderRadius: '0.5rem',
                  border: '1px solid gray.main',
                  width: '100%',
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="flex-end" mt={2} mb={1.5}>
              <Button
                onClick={props.onClose}
                variant="text"
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: '1.25rem',
                  letterSpacing: '0.36px',
                }}
              >
                Annulla
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: '1.25rem',
                  letterSpacing: '0.36px',
                  ml: '1rem',
                }}
              >
                Salva
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
}

export default NoteItem;
