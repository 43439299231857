import { useOutletContext } from 'react-router-dom';
import { ProjectForm } from '../components';
import { useNewProject } from '../data-access';
import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { ProjectsListPageOutletContext } from './projects-list-page';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { NewProjectDto } from '@docpack/shared/dtos';

export const SkeletonProjectForm = () => {
  return (
    <>
      <Skeleton height={50} width="100%" />
      <Skeleton height={50} width="100%" />
      <Skeleton height={50} width="100%" />
      <Skeleton variant="rectangular" height={300} width="100%" />
    </>
  );
};

const currentYear = new Date().getFullYear();

export function NewProjectPage() {
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();
  const { newProject, error } = useNewProject();
  const formMethods = useFormMethods(NewProjectDto, true, {
    created_at: new Date().toISOString(),
    keywords: [],
    brand_uuids: [],
    project_code_year: currentYear.toString(),
  });
  const handleClose = useHandleClose(formMethods);
  const showMessage = useMessagePopup();

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      data-testid="add-project-modal"
    >
      <WhiteBoxModalContainer whiteBoxWith={800}>
        <AlertController
          message={error ? { type: 'error', text: error } : undefined}
        />
        <Typography
          variant="h3"
          id="parent-modal-title"
          mb={3}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          Nuovo progetto
        </Typography>
        <ProjectForm
          handleClose={() => handleClose()}
          onSubmit={(data) =>
            newProject(data)
              .then(() => outletContext?.reloadProjects())
              .then(() =>
                showMessage('Progetto creato con successo!', 'success', () => {
                  handleClose(true);
                })
              )
              .catch((error) => console.error(error))
          }
          formMethods={formMethods}
        />
      </WhiteBoxModalContainer>
    </Modal>
  );
}

export default NewProjectPage;
