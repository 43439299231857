import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { CategoryDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetSingleCategory(category_uuid: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<CategoryDto>(
    `/api/project/categories/${category_uuid}`,
    fetcher
  );

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
