import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface AssetTypeButtonProps {
  name: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  small?: boolean;
  cy?: string;
}

export const AssetTypeButton = (
  props: React.PropsWithChildren<AssetTypeButtonProps>
) => {
  const theme = useTheme();
  const mediaQuerySmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      role="checkbox"
      aria-checked={props.isSelected}
      aria-disabled={props.isDisabled}
      sx={{
        padding:
          mediaQuerySmDown || props.small
            ? '0'
            : props.small
            ? '0 32px 8px 32px'
            : '24px',
        borderRadius: '8px',
        boxShadow: props.isSelected
          ? '0 0 16px 2px rgba(241, 144, 35, 0.6)'
          : '0 7px 12px 0 rgba(0, 0, 0, 0.1)',
        border: props.isSelected
          ? 'solid 3px #f7ae6e'
          : 'solid 3px transparent',
        alignItems: 'center',
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        opacity: props.isDisabled ? '0.5' : 1,
        pointerEvents: props.isDisabled ? 'none' : 'all',
        width:
          props.small && mediaQuerySmDown
            ? '33%'
            : props.small
            ? '134px'
            : mediaQuerySmDown
            ? 'calc(33% - 8px)'
            : 'auto',
        maxWidth: '100%',
      }}
      onClick={props.onClick}
      data-testid={props.cy}
    >
      {props.children}
      <Typography
        variant={props.small || mediaQuerySmDown ? 'caption' : 'body3'}
        mt={props.small || mediaQuerySmDown ? 0 : 2}
      >
        {props.name}
      </Typography>
    </Stack>
  );
};

export default AssetTypeButton;
