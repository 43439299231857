export function makeAssetAbsoluteUri(asset_uuid?: string) {
  return asset_uuid ? `/api/assets/${asset_uuid}` : undefined;
}

export function makeShareAssetAbsoluteUri(
  asset_uuid?: string,
  share_token?: string
) {
  return asset_uuid && share_token
    ? `/api/project/download/share/asset/${asset_uuid}?share_token=${share_token}`
    : undefined;
}
