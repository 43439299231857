import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { SubCategoryDto, UpdateSubCategoryDto } from '@docpack/shared/dtos';

export function useUpdateSubCategory() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function updateSubCategory(data: UpdateSubCategoryDto) {
    const result = await fetcher(`/api/project/categories/sub-category`, {
      method: 'PUT',
      body: data,
    });

    return handle<SubCategoryDto>(result);
  }

  async function deleteSubCategory(subcategory_uuid: string) {
    const result = await fetcher(
      `/api/project/categories/sub-category/${subcategory_uuid}`,
      {
        method: 'DELETE',
        body: {},
      }
    );

    return handle<SubCategoryDto>(result);
  }

  return {
    error,
    updateSubCategory,
    deleteSubCategory,
  };
}
