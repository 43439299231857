import { Button, Grid, Stack, Typography } from '@mui/material';
import { useGetCurrentUserInfo } from 'libs/frontend/settings/src/lib/data-access/get-current-user-info.hook';
import {
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  Stepper,
} from '@docpack/frontend/core-components';
import { useNavigate } from 'react-router-dom';
import { WorkspaceForm, useSetFirstLogin } from '@docpack/frontend/settings';
import WizardBox from '../component/wizard-box';
import { useNewProject, ProjectForm } from '@docpack/frontend/projects-feature';
import { NewProjectDto } from '@docpack/shared/dtos';
/* eslint-disable-next-line */
import steps from '../data-access/steps';

export function FrontendWizardFeaturePhaseFive() {
  const showMessage = useMessagePopup();
  const navigate = useNavigate();
  const { firstLoginCompleted } = useSetFirstLogin();
  const { newProject, error } = useNewProject();
  const currentYear = new Date().getFullYear();
  const formMethods = useFormMethods(NewProjectDto, true, {
    created_at: new Date().toISOString(),
    keywords: [],
    brand_uuids: [],
    project_code_year: currentYear.toString(),
  });
  const handleClose = useHandleClose(formMethods);

  return (
    <WizardBox>
      <Stepper steps={steps} activeStep={4} />
      <Typography variant="h3" mb={5} textAlign={'center'}>
        Progetti
      </Typography>
      <Typography mb={5} sx={{ padding: '0% 20%' }} textAlign={'center'}>
        Aggiungi i tuoi progetti
      </Typography>
      <Grid item sx={{ margin: 'auto' }}>
        <Grid>
          <ProjectForm
            handleClose={() => handleClose()}
            onSubmit={(data) =>
              newProject(data)
                .then(async () => {
                  await firstLoginCompleted();
                  navigate('/app/projects');
                })
                .catch((error) => console.error(error))
            }
            formMethods={formMethods}
          />
          <Stack>
            <Button
              variant="text"
              sx={{ mt: 4, ml: 'auto', mr: 'auto' }}
              onClick={async () => {
                await firstLoginCompleted();
                navigate('/app/projects');
              }}
            >
              Salta per ora
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </WizardBox>
  );
}

export default FrontendWizardFeaturePhaseFive;
