import {
  AlertController,
  InfoTooltip,
  useMessagePopup,
  useShowMessage,
  useWorkspaceSettings,
  WhiteBox,
} from '@docpack/frontend/core-components';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CustomizedNotes from '../components/customized-notes';
import UpdateUserForm from '../components/update-user-form';
import WorkspaceForm from '../components/workspace-form';
import { useGetCurrentUserInfo } from '../data-access/get-current-user-info.hook';
import { useResetPassword } from '../data-access/reset-password.hook';
import { useUpdateUserInfo } from '../data-access/update-user-info.hook';

export const SettingsPage = () => {
  const { data: user } = useGetCurrentUserInfo();
  const { updateUserInfo, error: updateUserInfoError } = useUpdateUserInfo();
  const { resetPassword, error: resetPasswordError } = useResetPassword();
  const resetPassewordMessage = useShowMessage();
  const showMessage = useMessagePopup();
  const { updateSettings, settings } = useWorkspaceSettings();

  return (
    <Grid container spacing={2}>
      <AlertController
        message={
          updateUserInfoError
            ? { type: 'error', text: updateUserInfoError }
            : undefined
        }
      />
      <AlertController
        message={
          resetPasswordError
            ? { type: 'error', text: resetPasswordError }
            : undefined
        }
      />
      <AlertController
        message={resetPassewordMessage.message}
        hasClosed={resetPassewordMessage.clearMessage}
      />
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="h3" mt={5} mb={2} ml={5}>
          Workspace
        </Typography>
        <WhiteBox whiteBoxMd="40px" heightAuto>
          {user && (
            <WorkspaceForm
              onSubmit={() =>
                new Promise(() =>
                  showMessage('Dati aggiornati con successo!', 'success', () =>
                    window.location.reload()
                  )
                )
              }
              defaultValues={{
                workspace_logo: user?.workspace?.workspace_logo,
              }}
            />
          )}
        </WhiteBox>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="h3" mt={5} mb={2} ml={5}>
          Dati account
        </Typography>
        <WhiteBox whiteBoxMd="40px" heightAuto>
          <Grid container>
            <Grid item md={12}>
              {user && (
                <UpdateUserForm
                  data-testid="settings-update-user"
                  onSubmit={(values) =>
                    updateUserInfo(values)
                      .then((result) => {
                        showMessage(
                          'Dati utente aggiornati con successo!',
                          'success'
                        );
                        return result;
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                  }
                  defaultValues={{
                    first_name: user?.details?.first_name,
                    last_name: user?.details?.last_name,
                    phone: user?.details?.phone,
                    email: user?.email,
                    username: user?.username,
                  }}
                  resetPassword={() =>
                    resetPassword()
                      .then((result) => {
                        showMessage(
                          'Email di reset password inviata, controlla la tua posta',
                          'success'
                        );
                        return result;
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                  }
                />
              )}
            </Grid>
          </Grid>
        </WhiteBox>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="h3" mt={5} mb={2} ml={5}>
          Personalizza
        </Typography>
        <WhiteBox whiteBoxMd="40px" heightAuto sx={{ mb: 3 }}>
          <Stack direction="row" alignItems="center" sx={{ height: '28px' }}>
            <Typography variant="body3" mr={1}>
              Campi aggiuntivi
            </Typography>
            {/* <InfoTooltip text="Puoi decidere di mantenere o disattivare nella piattaforma il campo CATEGORIE o FORNITORI selezionando o deselezionando i flag qui sotto." />*/}
          </Stack>
          {settings && (
            <>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  sx={{ marginRight: '4px' }}
                  control={
                    <Checkbox
                      color="warning"
                      checked={settings?.categories_and_subs_flag}
                      onChange={() =>
                        updateSettings({
                          categories_and_subs_flag:
                            !settings?.categories_and_subs_flag,
                        })
                          .then((res) =>
                            showMessage(
                              'Dati aggiornati con successo!',
                              'success',
                              () => window.location.reload()
                            )
                          )
                          .catch((error) => {
                            console.error(error);
                          })
                      }
                    />
                  }
                  label="Categorie"
                />
                {/*<InfoTooltip alignVerticalTop text="Mantenere o disattivare nella piattaforma il campo CATEGORIE" />*/}
                <InfoTooltip sx={{ alignSelf: 'center '}} text="Possibilità di creare e aggiungere categorie nelle impostazioni e nella creazione di nuovi progetti." />
              </Stack>

              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  sx={{ marginRight: '4px' }}
                  control={
                    <Checkbox
                      color="warning"
                      checked={settings?.suppliers_flag}
                      onChange={() =>
                        updateSettings({
                          suppliers_flag: !settings?.suppliers_flag,
                        })
                          .then((res) =>
                            showMessage(
                              'Dati aggiornati con successo!',
                              'success',
                              () => window.location.reload()
                            )
                          )
                          .catch((error) => {
                            console.error(error);
                          })
                      }
                    />
                  }
                  label="Fornitori"
                />
                <InfoTooltip sx={{ alignSelf: 'center '}} text="Possibilità di creare e aggiungere fornitori nelle impostazioni e nella creazione di nuovi progetti." />
              </Stack>
              <Stack direction="row" alignItems="center">
              <FormControlLabel
                sx={{ marginRight: '4px' }}
                control={
                  <Checkbox
                    color="warning"
                    checked={settings?.job_editable_flag}
                    onChange={() =>
                      updateSettings({
                        job_editable_flag: !settings?.job_editable_flag,
                      })
                        .then((res) =>
                          showMessage(
                            'Dati aggiornati con successo!',
                            'success',
                            () => window.location.reload()
                          )
                        )
                        .catch((error) => {
                          console.error(error);
                        })
                    }
                  />
                }
                label="Job modificabile"
              />
                <InfoTooltip sx={{ alignSelf: 'center '}} text="Possibilità di rendere il numero di progetto modificabile. Se disattivato il numero di progetto sarà creato automaticamente in modo sequenziale." />
              </Stack>
            </>
          )}
        </WhiteBox>
        <WhiteBox whiteBoxMd="40px" heightAuto>
          <CustomizedNotes />
        </WhiteBox>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
