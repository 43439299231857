import { ArrayNotEmpty, IsArray, IsString } from 'class-validator';

export class ProjectMigrationDto {
  @IsArray()
  @ArrayNotEmpty({ message: 'Selezionare uno o piu progetti' })
  projects: Array<string>;

  @IsArray({ message: 'Selezionare uno o piu marchi' })
  brands: Array<string>;

  @IsString()
  commonBrand: string;
}
