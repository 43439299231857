import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';

export interface AlertControllerProps {
  message?: {
    type: AlertColor;
    text: string;
  };
  timeout?: number;
  hasClosed?: () => void;
  cy?: string;
}

export function AlertController(props: AlertControllerProps) {
  const [message, setMessage] = useState<AlertControllerProps['message']>();

  function handleClose() {
    setMessage(undefined);
    if (props.hasClosed) {
      props.hasClosed();
    }
  }

  useEffect(() => {
    if (props.message?.type && props.message?.text) {
      setMessage({
        type: props.message?.type,
        text: props.message?.text,
      });
    }
  }, [props.message?.type, props.message?.text]);

  useEffect(() => {
    if (props.timeout) {
      const id = setTimeout(handleClose, props.timeout * 1000);
      return () => {
        clearTimeout(id);
      };
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeout]);

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={6000}
      onClose={() => handleClose()}
    >
      <Alert
        onClose={() => handleClose()}
        severity={message?.type}
        sx={{ width: '100%' }}
        data-testid={props.cy}
      >
        {message?.text}
      </Alert>
    </Snackbar>
  );
}

export default AlertController;
