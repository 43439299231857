import { Route, Routes } from 'react-router-dom';
import SettingsPage from './pages/settings-page';

export function SettingsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SettingsPage />}></Route>
    </Routes>
  );
}
