import {
  IsEmail,
  Matches,
  MaxLength,
  IsString,
  IsOptional,
} from 'class-validator';
import { Match } from './match.decorator';

export const passwordRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\.])[A-Za-z\d@$!%*#?&\.]{8,}$/;

export class NewRootUserDto {
  @IsEmail()
  email: string;

  @MaxLength(40, { message: 'Password deve essere piu corta di 40 caratteri' })
  @Matches(passwordRegex, {
    message:
      'Password deve contenere almeno un numero, un simbolo (@, $, !, %, *, #, ?, &, .) e una lettera maiuscola',
  })
  password: string;

  @Match('password', { message: 'Le password non corrispondono' })
  repeat_password: string;

  @IsString({ message: 'Campo richiesto' })
  first_name: string;

  @IsString({ message: 'Campo richiesto' })
  last_name: string;

  @IsOptional()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  discount_code: string;
}
