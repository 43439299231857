import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHandleClose = (formMethods: UseFormReturn<any, object>) => {
  const navigate = useNavigate();

  const isDirty = formMethods.formState.isDirty;
  const subCount = formMethods.formState.submitCount;

  const handleClose = (force?: boolean) => {
    if (isDirty && subCount === 0 && !force) {
      if (window.confirm(`Sei sicuro di voler chiudere la videata?`)) {
        navigate('..');
      }
    } else {
      navigate('..');
    }
  };

  return handleClose;
};
