import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { NewProjectDto, ProjectDto } from '@docpack/shared/dtos';

export function useNewProject() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function newProject(data: NewProjectDto) {
    const result = await fetcher(`/api/project/new`, {
      method: 'POST',
      body: data,
    });

    return handle<ProjectDto>(result);
  }

  async function deleteProject(project_uuid: string) {
    const result = await fetcher(`/api/project/${project_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<ProjectDto>(result);
  }

  return {
    error,
    newProject,
    deleteProject,
  };
}
