import { NoteDto } from '@docpack/shared/dtos';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useDeleteNote(user_notes_uuid: string) {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function deleteNote(user_notes_uuid: string) {
    const result = await fetcher(`/api/notes/${user_notes_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<NoteDto>(result);
  }

  return {
    error,
    deleteNote,
  };
}
