import { createTheme } from '@mui/material/styles';

export interface DefaultDocpackThemeInterface {
  darkMode: boolean;
}

const primaryMainColor = '#20484f';

// Documentation https://mui.com/customization/theming/
export const createDefualtDockpackTheme = (
  options: DefaultDocpackThemeInterface
) => {
  const theme = createTheme({
    palette: {
      mode: options.darkMode ? 'dark' : 'light',
      white: {
        main: '#fff',
      },
      primary: {
        light: '#3d3f43',
        main: primaryMainColor,
      },
      secondary: {
        main: '#ffb800',
        dark: '#f3a50c',
        light: 'rgba(222,133,56,0.5)',
      },
      gray: {
        light: '#d7d8db',
        main: '#bcbfc2',
        dark: '#797e86',
      },
      error: {
        main: '#ce4525',
      },
      info: {
        light: '#239cf3',
        main: '#187bef',
        dark: '#383f49',
      },
      success: {
        main: '#51b943',
      },
    },
    mixins: {
      toolbar: {
        minHeight: '80px',
      },
    },
    typography: {
      fontFamily: 'Poppins',
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      h1: {
        fontSize: '3.5rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: primaryMainColor,
      },
      h2: {
        fontSize: '2.5rem',
        fontWeight: 500,
        lineHeight: 1.17,
        color: primaryMainColor,
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.23,
        color: primaryMainColor,
      },
      h4: {
        fontSize: '2rem',
        fontWeight: 400,
        lineHeight: 1.23,
        color: primaryMainColor,
      },
      h5: {
        fontSize: '1.7rem',
        fontWeight: 500,
        lineHeight: 1.29,
        color: primaryMainColor,
      },
      h6: {
        fontSize: '1.7rem',
        fontWeight: 400,
        lineHeight: 1.29,
        color: primaryMainColor,
      },
      subtitle1: {
        fontSize: '1.6rem',
        fontWeight: 400,
        lineHeight: 1.18,
        color: primaryMainColor,
      },
      subtitle2: {
        fontSize: '2.5rem',
        fontWeight: 400,
        lineHeight: 1,
        color: primaryMainColor,
      },
      body1: {
        fontSize: '0.9rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: primaryMainColor,
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.4,
        color: primaryMainColor,
      },
      body3: {
        fontSize: '0.9rem',
        fontWeight: 500,
        lineHeight: 1.5,
        color: primaryMainColor,
      },
      button: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.4,
        color: primaryMainColor,
        textTransform: 'initial',
      },
      caption: {
        fontSize: '0.6rem',
        fontWeight: 500,
        lineHeight: 1.77,
        color: primaryMainColor,
      },
      overline: {
        fontSize: '0.6rem',
        fontWeight: 400,
        lineHeight: 1.77,
        color: primaryMainColor,
        textTransform: 'none',
      },
      faint: {
        fontSize: '0.5rem',
        fontWeight: 500,
        lineHeight: 1.3,
        color: primaryMainColor,
      },
      microtitle: {
        fontSize: '1.3rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: primaryMainColor,
      },
      subtitle: {
        fontSize: '1.3rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: primaryMainColor,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
  });

  theme.typography.h1[theme.breakpoints.down('md')] = { fontSize: '2.4rem' };
  theme.typography.h3[theme.breakpoints.down('md')] = { fontSize: '1.5625rem' };
  theme.typography.h5[theme.breakpoints.down('md')] = { fontSize: '1.5rem' };
  theme.typography.h6[theme.breakpoints.down('md')] = { fontSize: '1.5rem' };
  theme.typography.button[theme.breakpoints.down('md')] = { fontSize: '1rem' };

  return theme;
};
