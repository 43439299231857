import { Route, Routes } from 'react-router-dom';
import { SuppliersListPage } from './pages';
import EditSupplierPage from './pages/edit-supplier-page';
import NewSupplierPage from './pages/new-supplier-page';

export function SuppliersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SuppliersListPage />}>
        <Route path="new" element={<NewSupplierPage />} />
        <Route path="edit/:supplier_uuid" element={<EditSupplierPage />} />
      </Route>
    </Routes>
  );
}
