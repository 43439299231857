import { usePlainFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { AssetDto } from '@docpack/shared/dtos';
import { useCallback } from 'react';

export function useUploadFile() {
  const fetcher = usePlainFetcher();
  const { error, handle } = useHandleRespError();

  const uploadFile = useCallback(
    async (data: {
      file: File;
      brand_uuid?: string;
      project_uuid?: string;
    }) => {
      const body = new FormData();
      body.append('file', data.file);

      if (data.brand_uuid) {
        body.append('brand_uuid', data.brand_uuid);
      }

      if (data.project_uuid) {
        body.append('project_uuid', data.project_uuid);
      }

      const result = await fetcher(`/api/assets`, {
        method: 'POST',
        body: body,
      });

      return handle<AssetDto>(await result.json());
    },
    [fetcher, handle]
  );

  return {
    error,
    uploadFile,
  };
}
