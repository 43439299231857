import UserForm from '../components/user-form';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useNewUser } from '../data-access';
import {
  AlertController,
  useMessagePopup,
  useAccessControl,
  WhiteBoxModalContainer,
  useHandleClose,
  useFormMethods,
} from '@docpack/frontend/core-components';
import { NewUserDto, UserPermission, UserRole } from '@docpack/shared/dtos';
import { UserListContextInterface } from './users-list-page';
import { useStripe } from '@docpack/frontend/react-hook-utils';

export const NewUserPage = () => {
  const navigate = useNavigate();
  const routerContext = useOutletContext<UserListContextInterface>();
  const currentUserIsAdmin = useAccessControl({
    roles: [UserRole.Amministratore],
  });
  const formMethods = useFormMethods(NewUserDto, true, {
    user_role: currentUserIsAdmin ? UserRole.Amministratore : UserRole.Master,
    user_permission: currentUserIsAdmin
      ? [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.Fatturazione,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ]
      : [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ],
  });
  const handleClose = useHandleClose(formMethods);

  const { newWorkspaceUser, error } = useNewUser();

  //console.log(error);
  const showMessage = useMessagePopup();

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
        cy="alert-new-user"
      />
      <Modal open={true} onClose={() => handleClose()}>
        <WhiteBoxModalContainer>
          <Typography
            variant="h3"
            id="parent-modal-title"
            mb={3}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            Crea nuovo utente
          </Typography>
          <UserForm
            handleClose={() => handleClose()}
            onSubmit={(values) =>
              newWorkspaceUser(values as NewUserDto)
                .then(() => navigate('..'))
                .then(() =>
                  showMessage('Utente creato con successo!', 'success', () =>
                    handleClose(true)
                  )
                )
                .then(() => routerContext?.reloadUsers())
                .then(() => routerContext?.addUserCallback())
                .catch((error) => {
                  //console.log();
                  showMessage(error.toString(), 'error', () =>
                    handleClose(true)
                  );
                })
            }
            isNew
            formMethods={formMethods}
          />
        </WhiteBoxModalContainer>
      </Modal>
    </>
  );
};

export default NewUserPage;
