import { ShareMultipleProjectsDto } from '@docpack/shared/dtos';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useSendEmailProjects() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError(false);

  async function sendEmail(data: ShareMultipleProjectsDto) {
    const result = await fetcher(`/api/project/download/share`, {
      method: 'POST',
      body: data,
    });

    return handle(result);
  }

  return {
    error,
    sendEmail,
  };
}
