import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { UserDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetUserInfo() {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<UserDto>(`/api/user/me`, fetcher);

  const data = handle(result?.data);

  return {
    ...result,
    error,
    data: data,
  };
}
