import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { CategoryDto, UpdateCategoryDto } from '@docpack/shared/dtos';

export function useUpdateCategory() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function updateCategory(data: UpdateCategoryDto) {
    const result = await fetcher(`/api/project/categories`, {
      method: 'PUT',
      body: data,
    });

    return handle<CategoryDto>(result);
  }

  async function deleteCategory(category_uuid: string) {
    const result = await fetcher(`/api/project/categories/${category_uuid}`, {
      method: 'DELETE',
      body: {},
    });

    return handle<CategoryDto>(result);
  }

  return {
    error,
    updateCategory,
    deleteCategory,
  };
}
