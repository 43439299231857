import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  NewProjectBox,
  ProjectBox,
  ProjectBoxSkeleton,
  ProjectsCount,
} from '../components';
import Grid from '@mui/material/Grid';
import { useGetProjects, useGetTotalProjectsCount } from '../data-access';
import {
  AlertController,
  DotsMenu,
  SearchBar,
  Toolbar,
  useEffectOnScrollEnd,
  SelectOrderContainer,
  UserAccessControl,
  ToolbarLeftContainer,
  AddButton,
  useShowElementOnScroll,
  useMessagePopup,
  ShowDetailsContainer,
} from '@docpack/frontend/core-components';
import { useGetItemsSelected } from '@docpack/frontend/core-components';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FolderIcon from '@mui/icons-material/Folder';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useNewProject } from '../data-access';
import IconButton from '@mui/material/IconButton';
import { unstable_batchedUpdates } from 'react-dom';
import {
  ProjectDto,
  SearchProjectsDto,
  UserPermission,
  UserRole,
} from '@docpack/shared/dtos';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ProjectsListPageOutletContext {
  share_token?: string;
  reloadProjects: () => void;
  selectedProjects: Array<ProjectDto>;
  searchedFields?: {
    search_text?: string;
    search_category?: string;
    search_sub_category?: string;
    search_supplier?: string;
    brand?: string;
    date_start?: Date;
    date_end?: Date;
  };
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#000',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export function ProjectsListPage() {
  const navigate = useNavigate();
  const formMethods = useForm({ mode: 'onChange' });
  const [order, setOrder] = useState<SearchProjectsDto['orderBy']>('date_desc');
  const watchAllFields = formMethods.watch();
  const searchForBrand = watchAllFields['brand'] || '';
  const { totalProjectsCount } = useGetTotalProjectsCount();

  const { data, error, mutate, nextPage, isLoadingMore, totalCount } =
    useGetProjects({
      search: watchAllFields['search_text'],
      search_brand: watchAllFields['brand'],
      search_date_from: watchAllFields['date_start'],
      search_date_to: watchAllFields['date_end'],
      search_category: watchAllFields['search_category'],
      search_sub_category: watchAllFields['search_sub_category'],
      search_supplier: watchAllFields['search_supplier'],
      orderBy: order,
    });

  useEffectOnScrollEnd(nextPage);

  const {
    itemsSelected,
    toggleItemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  } = useGetItemsSelected();

  const { deleteProject, error: errorDelete } = useNewProject();
  const theme = useTheme();
  const mediaQueryMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const selectedProjects = useMemo(
    () =>
      data?.filter((project) => itemsSelected.includes(project.project_uuid)) ||
      [],
    [data, itemsSelected]
  );

  const allProjectsIds = useMemo(() => {
    return data?.map((project) => project.project_uuid);
  }, [data]);

  //Reset selection when filters changes
  useEffect(() => {
    deselectAll();
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['search_text'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['brand'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['date_start'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['date_end'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['search_category'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['search_sub_category'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchAllFields['search_supplier'],
    deselectAll,
  ]);

  const showElement = useShowElementOnScroll(300);

  const showMessage = useMessagePopup();

  const [showDetails, setShowDetails] = useState(
    localStorage.getItem('showDetails') === 'true'
  );
  useEffect(() => {
    localStorage.setItem('showDetails', showDetails.toString());
  }, [showDetails]);

  return (
    <>
      <SearchBar
        selectOptions="brands"
        formMethods={formMethods}
        projectsCount={totalCount}
        categoriesSelect
        suppliersSelect
      />
      <AlertController
        message={
          error || errorDelete
            ? { type: 'error', text: (error || errorDelete) as string }
            : undefined
        }
      />
      <Toolbar
        itemsSelectedCount={itemsSelected.length}
        customTopStickyOnMobile={80}
      >
        <ToolbarLeftContainer>
          {showElement ? (
            <UserAccessControl
              options={{
                roles: [UserRole.Amministratore, UserRole.Master],
                permissions: [UserPermission.Caricare],
              }}
            >
              <AddButton
                linkTo={'new'}
                label={'Inserisci un nuovo progetto'}
                data-testid=""
              />
            </UserAccessControl>
          ) : null}
        </ToolbarLeftContainer>
        <ShowDetailsContainer>
          <Typography variant="body3" style={{ marginRight: '1rem' }}>
            Visualizza più dettagli
          </Typography>
          <IOSSwitch
            checked={showDetails}
            onChange={() => {
              setShowDetails(!showDetails);
            }}
          />
        </ShowDetailsContainer>
        <SelectOrderContainer>
          <Select
            value={order}
            onChange={(e) =>
              setOrder(e.target.value as SearchProjectsDto['orderBy'])
            }
            label="Data"
          >
            <MenuItem value={'date_asc'}>
              Data inserimento (meno recente)
            </MenuItem>
            <MenuItem value={'date_desc'}>
              Data inserimento (più recente)
            </MenuItem>
            <MenuItem value={'date_edit_asc'}>
              Data modifica (meno recente)
            </MenuItem>
            <MenuItem value={'date_edit_desc'}>
              Data modifica (più recente)
            </MenuItem>
            <MenuItem value={'job_asc'}>Job (meno recente)</MenuItem>
            <MenuItem value={'job_desc'}>Job (più recente)</MenuItem>
          </Select>
        </SelectOrderContainer>
        <Stack direction="row" spacing={3}>
          <UserAccessControl
            options={{ roles: [UserRole.Amministratore, UserRole.Master] }}
          >
            <IconButton
              color="primary"
              component={Link}
              to={'report'}
              data-testid="report-projects-button"
            >
              <EventNoteIcon fontSize={'medium'} />
            </IconButton>
          </UserAccessControl>
          {itemsSelected.length > 1 && (
            <>
              <UserAccessControl
                options={{
                  roles: [UserRole.Amministratore, UserRole.Master],
                  permissions: [UserPermission.Scaricare],
                }}
              >
                <IconButton color="primary" component={Link} to={'download'}>
                  <DownloadIcon fontSize={'medium'} />
                </IconButton>
              </UserAccessControl>

              {searchForBrand !== '' && (
                <UserAccessControl
                  options={{
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [],
                  }}
                >
                  <IconButton color="primary" component={Link} to={'move'}>
                    <FolderIcon fontSize={'medium'} />
                  </IconButton>
                </UserAccessControl>
              )}
              <IconButton color="primary" component={Link} to={'send-email'}>
                <MailOutlineIcon fontSize={'medium'} />
              </IconButton>
              <UserAccessControl
                options={{
                  roles: [UserRole.Amministratore, UserRole.Master],
                  permissions: [],
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Sei sicuro di voler eliminare ${itemsSelected.length} progetti?`
                      )
                    ) {
                      Promise.all(
                        itemsSelected.map((project_id) =>
                          deleteProject(project_id).then(() => project_id)
                        )
                      )
                        .then((ids) => {
                          unstable_batchedUpdates(() => {
                            ids.forEach((id) => removeItemsSelected(id));
                          });
                          mutate();
                        })
                        .then(() => {
                          showMessage(
                            'Progetti eliminati con successo!',
                            'success'
                          );
                        })
                        .catch(console.error);
                    }
                  }}
                >
                  <DeleteIcon fontSize={'medium'} />
                </IconButton>
              </UserAccessControl>
            </>
          )}
          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(allProjectsIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Grid
        container
        alignItems="stretch"
        spacing={3}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.target === e.currentTarget) deselectAll();
        }}
        sx={{
          margin: { xs: 0, sm: '-24px 0 0 -24px' },
          width: { xs: '100%', sm: 'calc(100% + 24px)' },
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill,minmax(220px, 1fr))',
        }}
      >
        {!mediaQueryMdDown && (
          <UserAccessControl
            options={{
              roles: [UserRole.Amministratore, UserRole.Master],
              permissions: [UserPermission.Caricare],
            }}
          >
            <Grid
              item
              sx={{
                paddingLeft: { xs: '0 !important', sm: '24px !important' },
              }}
            >
              <NewProjectBox />
            </Grid>
          </UserAccessControl>
        )}
        {data?.map((project) => (
          <Grid
            key={project.project_uuid}
            item
            sx={{ paddingLeft: { xs: '0 !important', sm: '24px !important' } }}
          >
            <ProjectBox
              logo={project.picture?.link}
              project_code={project.project_code}
              name={`${project.name}`}
              data={project}
              selected={isSelected((item) => item === project.project_uuid)}
              onClick={(e) => {
                e.stopPropagation();
                toggleItemsSelected(project.project_uuid);
              }}
              itemsSelectedCount={itemsSelected.length}
              showDetails={showDetails}
            >
              <Stack direction="row" justifyContent="center" marginTop="1rem">
                <UserAccessControl
                  options={{
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [UserPermission.Scaricare],
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('download');
                    }}
                    data-testid="project-item-download-button"
                  >
                    <DownloadIcon fontSize={'small'} />
                  </IconButton>
                </UserAccessControl>
                <UserAccessControl
                  options={{
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [],
                  }}
                >
                  <IconButton
                    color="primary"
                    component={Link}
                    to={`edit/${project.project_uuid}`}
                    data-testid="project-item-edit-button"
                  >
                    <EditIcon fontSize={'small'} />
                  </IconButton>
                </UserAccessControl>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('send-email');
                  }}
                  data-testid="project-item-send-email-button"
                >
                  <MailOutlineIcon fontSize={'small'} />
                </IconButton>
                <UserAccessControl
                  options={{
                    roles: [UserRole.Amministratore, UserRole.Master],
                    permissions: [],
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        window.confirm(
                          `Sei sicuro di voler eliminare il progetto ${project.name}?`
                        )
                      ) {
                        deleteProject(project.project_uuid)
                          .then(() => mutate())
                          .then(() => removeItemsSelected(project.project_uuid))
                          .then(() =>
                            showMessage(
                              'Progetto eliminato con successo!',
                              'success'
                            )
                          )
                          .catch(console.error);
                      }
                    }}
                    data-testid="project-item-delete-button"
                  >
                    <DeleteIcon fontSize={'small'} />
                  </IconButton>
                </UserAccessControl>
              </Stack>
            </ProjectBox>
          </Grid>
        ))}
        {isLoadingMore && (
          <Grid item xs={2.4}>
            <ProjectBoxSkeleton />
          </Grid>
        )}
      </Grid>
      <ProjectsCount
        filterProjectsCount={totalCount}
        totalProjectsCount={totalProjectsCount || 0}
      />
      <Outlet
        context={
          {
            reloadProjects: () => {
              deselectAll();
              mutate();
            },
            selectedProjects: selectedProjects,
            searchedFields: watchAllFields ? watchAllFields : undefined,
          } as ProjectsListPageOutletContext
        }
      />
    </>
  );
}
