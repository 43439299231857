import { IsNumberString, IsOptional, IsString } from 'class-validator';

export class SearchCategoryAndSubCategoryDto {
  @IsString()
  @IsOptional()
  fullTextSearch?: string;

  @IsNumberString()
  @IsOptional()
  page?: string;

  @IsNumberString()
  @IsOptional()
  offset?: string;
}
