import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { NewContactDto } from '@docpack/shared/dtos';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ContactForm from '../components/contact-form';
import { useNewContact } from '../data-access/new-contact.hook';
import { ContactListContextInterface } from './contacts-page';

export const NewContactPage = () => {
  const navigate = useNavigate();
  const routerContext = useOutletContext<ContactListContextInterface>();
  const { newContact, error } = useNewContact();
  const formMethods = useFormMethods(NewContactDto);
  const handleClose = useHandleClose(formMethods);

  const showMessage = useMessagePopup();

  return (
    <>
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <Modal open={true} onClose={() => handleClose()}>
        <WhiteBoxModalContainer>
          <Typography
            variant="h3"
            id="parent-modal-title"
            mb={3}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            Nuovo contatto
          </Typography>
          <ContactForm
            isNew
            handleClose={() => handleClose()}
            onSubmit={(values: NewContactDto) =>
              newContact(values)
                .then(() => {
                  navigate('..');
                })
                .then(() =>
                  showMessage('Contatto creato con successo!', 'success')
                )
                .then(() => routerContext?.reloadContacts())
                .catch((error) => console.error(error))
            }
            formMethods={formMethods}
          />
        </WhiteBoxModalContainer>
      </Modal>
    </>
  );
};

export default NewContactPage;
