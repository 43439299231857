import styled from 'styled-components';
import {
  AlertController,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { MoveProjectForm } from '../components';
import { ProjectsListPageOutletContext } from './projects-list-page';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useMoveProjects } from '../data-access';
import { useSWRConfig } from 'swr';

const FormWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(3)};
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MoveProjectPageProps {}

export function MoveProjectPage(props: MoveProjectPageProps) {
  const navigate = useNavigate();
  const outletContext = useOutletContext<ProjectsListPageOutletContext>();
  const { moveProjects, error } = useMoveProjects();

  const searchedBrand = outletContext.searchedFields?.brand
    ? outletContext.searchedFields?.brand
    : undefined;

  // Access to the current cache context to invalidate the single and list of project
  const { mutate } = useSWRConfig();

  const handleClose = () => {
    navigate('..');
  };

  const showMessage = useMessagePopup();

  return (
    <>
      <AlertController
        message={
          !searchedBrand
            ? {
                type: 'error',
                text: 'Impossibile spostare questi progetti, selezionare un marchio di partenza tra i filtri',
              }
            : undefined
        }
      />
      <AlertController
        message={error ? { type: 'error', text: error } : undefined}
      />
      <Modal
        open={true}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white.main',
            maxWidth: '100%',
            width: 800,
            maxHeight: '100vh',
            overflow: 'auto',
            padding: { xs: '24px', md: '40px' },
            borderRadius: '24px',
          }}
        >
          <Typography variant="h3" mb={2} sx={{ display: 'block' }}>
            Sposta progetti
          </Typography>
          <Typography variant="body1">
            Hai selezionato {outletContext?.selectedProjects?.length} progetti
          </Typography>
          <FormWrapper>
            <MoveProjectForm
              handleClose={() => handleClose()}
              onSubmit={(migrate) =>
                moveProjects(migrate)
                  .then(mutate)
                  .then(outletContext.reloadProjects)
                  .then(handleClose)
                  .then(() =>
                    showMessage('Progetti spostati con successo', 'success')
                  )
                  .catch((error) => console.error(error))
              }
              defaultValues={{
                brands: searchedBrand ? [searchedBrand] : [],
                commonBrand: searchedBrand,
                projects:
                  outletContext?.selectedProjects?.map(
                    (project) => project.project_uuid
                  ) || [],
              }}
            />
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
}
