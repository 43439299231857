import {
  AddButton,
  AlertController,
  DotsMenu,
  PlaceholderText,
  PlanErrorModal,
  SearchBar,
  SelectOrderContainer,
  Toolbar,
  ToolbarLeftContainer,
  useCheckUser,
  useGetItemsSelected,
  useMessagePopup,
} from '@docpack/frontend/core-components';
import { UserDto } from '@docpack/shared/dtos';
import { unstable_batchedUpdates } from 'react-dom';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { KeyedMutator } from 'swr';
import {
  OrderByAndHeaderListing,
  OrderBySelect,
  useOrderbySelect,
} from '../components';
import UserItem from '../components/user-item';
import { useDeleteUser, useGetUsers } from '../data-access';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useStripe } from '@docpack/frontend/react-hook-utils';

export interface UserListContextInterface {
  reloadUsers: KeyedMutator<UserDto[]>;
  addUserCallback: Function;
  requiredPlan?: string;
}

export const UsersListPage = () => {
  const orderBy = useOrderbySelect();
  const navigate = useNavigate();
  const [canCreate, setCanCreate] = useState<boolean | undefined>();
  const formMethods = useForm({ mode: 'onChange' });
  const [reqPlan, setRequiredPlan] = useState<string | undefined>();
  const checkUser = useCheckUser();
  const [isOpen, changeState] = useState<boolean>(false);

  useEffect(() => {
    if (checkUser.data) {
      setRequiredPlan(checkUser.data.requiredPlan);
      setCanCreate(checkUser.data.canCreate);
    }
  }, [checkUser]);

  const { data, mutate, error } = useGetUsers({
    orderBy: orderBy.order,
    search: formMethods.watch('search_text'),
    search_date_from: formMethods.watch('date_start'),
    search_date_to: formMethods.watch('date_end'),
  });

  const {
    itemsSelected,
    toggleItemsSelected,
    isSelected,
    removeItemsSelected,
    selectAll,
    deselectAll,
  } = useGetItemsSelected();

  const { deleteUser, error: deleteUserError } = useDeleteUser();
  const showMessage = useMessagePopup();

  const allUsersIds = useMemo(() => {
    return data?.map((user) => user.user_uuid);
  }, [data]);

  const closeModal = () => {
    changeState(false);
  };

  return (
    <>
      <AlertController
        message={
          error || deleteUserError
            ? { text: error || deleteUserError || '', type: 'error' }
            : undefined
        }
      />
      {!canCreate && reqPlan && (
        <PlanErrorModal
          requiredPlan={reqPlan}
          isOpen={isOpen}
          onClose={closeModal}
        />
      )}
      <SearchBar removeSelect formMethods={formMethods} />
      <Toolbar itemsSelectedCount={itemsSelected.length}>
        <ToolbarLeftContainer>
          <AddButton
            disabled={checkUser.isValidating}
            linkTo={canCreate ? 'new' : ''}
            label={'Inserisci un nuovo utente'}
            onClick={() => changeState(true)}
          />
        </ToolbarLeftContainer>
        <SelectOrderContainer>
          <OrderBySelect {...orderBy} />
        </SelectOrderContainer>
        <Stack direction="row" spacing={3}>
          {itemsSelected.length === 1 && (
            <IconButton
              color="primary"
              component={Link}
              to={`edit/${itemsSelected[0]}`}
            >
              <EditIcon fontSize={'medium'} data-testid="user-edit-icon" />
            </IconButton>
          )}
          {itemsSelected.length > 0 && (
            <IconButton
              color="primary"
              onClick={() => {
                if (
                  window.confirm(
                    `Sei sicuro di voler eliminare ${itemsSelected.length} utenti?`
                  )
                ) {
                  Promise.all(
                    itemsSelected.map((user_id) =>
                      deleteUser(user_id).then(() => user_id)
                    )
                  )
                    .then((ids) => {
                      unstable_batchedUpdates(() => {
                        ids.forEach((id) => removeItemsSelected(id));
                      });
                      showMessage('Utenti eliminati con successo!', 'success');
                      mutate();
                    })
                    .then(() => checkUser.mutate());
                }
              }}
            >
              <DeleteIcon fontSize={'medium'} data-testid="user-delete-icon" />
            </IconButton>
          )}

          <DotsMenu hideOnMobile>
            {({ close }) => (
              <>
                <Button
                  onClick={() => {
                    selectAll(allUsersIds || []);
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Seleziona tutti
                </Button>
                <Button
                  onClick={() => {
                    deselectAll();
                    close();
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  Deseleziona tutti
                </Button>
              </>
            )}
          </DotsMenu>
        </Stack>
      </Toolbar>
      <Stack>
        {!!data?.length && <OrderByAndHeaderListing />}
        {data?.length ? (
          data?.map((user) => (
            <UserItem
              key={user.user_uuid}
              user={user}
              selected={isSelected((item) => item === user.user_uuid)}
              onClick={() => toggleItemsSelected(user.user_uuid)}
            />
          ))
        ) : (
          <PlaceholderText>
            Clicca sul bottone in alto a sinistra
            <br />
            per aggiungere il tuo primo utente
          </PlaceholderText>
        )}
      </Stack>
      <Outlet
        context={
          {
            addUserCallback: checkUser.mutate,
            reloadUsers: mutate,
            requiredPlan: reqPlan,
          } as UserListContextInterface
        }
      />
    </>
  );
};

export default UsersListPage;
