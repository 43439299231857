import { useState } from 'react';

export function usePagination() {
  const [page, setPage] = useState(0);

  const nextPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  return {
    page,
    nextPage,
  };
}
