import { useFetcher } from '@docpack/frontend/client-authorization';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';
import { ProjectMigrationDto } from '@docpack/shared/dtos';

export function useMoveProjects() {
  const fetcher = useFetcher();
  const { error, handle } = useHandleRespError();

  async function moveProjects(data: ProjectMigrationDto) {
    const result = await fetcher('/api/project/migrations', {
      method: 'PUT',
      body: data,
    });

    return handle(result);
  }

  return {
    error,
    moveProjects,
  };
}
