import useSwr from 'swr';
import { useFetcher } from '@docpack/frontend/client-authorization';
import { ContactDto } from '@docpack/shared/dtos';
import { useHandleRespError } from '@docpack/frontend/react-hook-utils';

export function useGetSingleContact(contact_uuid: string) {
  const fetcher = useFetcher();
  const { handle, error } = useHandleRespError(false);

  const result = useSwr<ContactDto>(`/api/contact/${contact_uuid}`, fetcher);

  return {
    ...result,
    data: handle(result.data),
    error,
  };
}
