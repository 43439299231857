import Stack from '@mui/material/Stack';
import {
  CheckboxGroupCustom,
  InputText,
  RadioGroupCustom,
  SelectBrandsInput,
  UploadFile,
  useAccessControl,
} from '@docpack/frontend/core-components';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import {
  NewUserDto,
  UpdateUserDto,
  UserPermission,
  UserRole,
  UserType,
} from '@docpack/shared/dtos';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

export interface UserFormPropsNew {
  handleClose: () => void;
  onSubmit: (val: NewUserDto) => Promise<unknown>;
  defaultValues?: Partial<NewUserDto>;
  isNew: true;
  isEdit?: false;
  formMethods: UseFormReturn<NewUserDto, object>;
}

export interface UserFormPropsEdit {
  handleClose: () => void;
  onSubmit: (val: UpdateUserDto) => Promise<unknown>;
  defaultValues?: Partial<UpdateUserDto>;
  isNew?: false;
  isEdit: true;
  formMethods: UseFormReturn<UpdateUserDto, object>;
}

const user_types = [
  { value: UserType.Interno, label: 'Interno' },
  { value: UserType.Esterno, label: 'Esterno' },
];

const user_permissions = [
  { value: UserPermission.Caricare, label: 'Caricare' },
  { value: UserPermission.Scaricare, label: 'Scaricare' },
  { value: UserPermission.Fatturazione, label: 'Fatturazione' },
  { value: UserPermission.NuovoProgetto, label: 'Nuovo Progetto' },
  { value: UserPermission.Report, label: 'Report' },
];

function getAviableRoles(currentUserIsAdmin: boolean) {
  if (currentUserIsAdmin) {
    return [
      { value: UserRole.Amministratore, label: 'Amministratore' },
      { value: UserRole.Master, label: 'Utente Master' },
      { value: UserRole.Basic, label: 'Utente Basic' },
    ];
  }
  return [
    { value: UserRole.Amministratore, label: 'Amministratore', disabled: true },
    { value: UserRole.Master, label: 'Utente Master' },
    { value: UserRole.Basic, label: 'Utente Basic' },
  ];
}

function getUserPermission(isAdmin: boolean, isMaster: boolean) {
  if (isAdmin) {
    return user_permissions;
  }
  if (isMaster) {
    return user_permissions.filter(
      (p) => p.value !== UserPermission.Fatturazione
    );
  }
  return [
    { value: UserPermission.Caricare, label: 'Caricare' },
    { value: UserPermission.Scaricare, label: 'Scaricare' },
  ];
}

export default function UserForm(props: UserFormPropsNew | UserFormPropsEdit) {
  const currentUserIsAdmin = useAccessControl({
    roles: [UserRole.Amministratore],
  });

  const isAdmin = props.isNew
    ? props.formMethods.watch('user_role') === UserRole.Amministratore
    : props.formMethods.watch('user_role') === UserRole.Amministratore;
  const isMaster = props.isNew
    ? props.formMethods.watch('user_role') === UserRole.Master
    : props.formMethods.watch('user_role') === UserRole.Master;

  useEffect(() => {
    if (isAdmin) {
      if (props.isNew) {
        props.formMethods.setValue('user_permission', [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.Fatturazione,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ]);
      } else {
        props.formMethods.setValue('user_permission', [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.Fatturazione,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ]);
      }
      return;
    }
    if (isMaster) {
      if (props.isNew) {
        props.formMethods.setValue('user_permission', [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ]);
      } else {
        props.formMethods.setValue('user_permission', [
          UserPermission.Caricare,
          UserPermission.Scaricare,
          UserPermission.NuovoProgetto,
          UserPermission.Report,
        ]);
      }
      return;
    }
    if (!isAdmin && !isMaster && props.isNew) {
      props.formMethods.setValue('user_permission', [
        UserPermission.Caricare,
        UserPermission.Scaricare,
      ]);
      return;
    } else {
      if (props.isEdit) {
        props.formMethods.setValue(
          'user_permission',
          props.defaultValues?.user_permission || []
        );
      }
      if (props.isNew) {
        props.formMethods.setValue(
          'user_permission',
          props.defaultValues?.user_permission || []
        );
      }
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formMethods, isAdmin, isMaster, props.isNew]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FormProvider {...(props.formMethods as any)}>
      <div className="usernew-form">
        <form
          onSubmit={props.formMethods.handleSubmit((values) => {
            if (props.isNew) {
              return props.onSubmit(values as NewUserDto);
            }
            return props.onSubmit(values as UpdateUserDto);
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={3}>
                <InputText
                  name="first_name"
                  label="Nome "
                  cy="users-new-firstname"
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <InputText
                  name="last_name"
                  label="Cognome "
                  cy="users-new-lastname"
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <InputText
                  name="email"
                  label="Email "
                  type={'email'}
                  cy="users-new-email"
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <InputText
                  name="phone"
                  label="Cell. "
                  type={'number'}
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <InputText
                  name="company_name"
                  label="Nome azienda "
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <SelectBrandsInput
                  name="brands"
                  label="Marchi"
                  hasSelectAll
                  required
                  tooltipText="Puoi decidere a quali marchi ha accesso l’utente."
                  disabled={!currentUserIsAdmin && isAdmin}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputText
                  name="description"
                  label="Descrizione o note "
                  textarea
                  disabled={!currentUserIsAdmin && isAdmin}
                />
                <RadioGroupCustom
                  name="user_role"
                  label="Ruolo"
                  options={getAviableRoles(currentUserIsAdmin)}
                  required
                  disabled={!currentUserIsAdmin && isAdmin}
                  tooltipText="Definisci il livello dell’utente. Amministratore ha accesso a tutte le funzioni ed ha accesso e può effettuare modifiche in ogni sezione; Utente Master è come Amministratore ma non può accedere ai dati di pagamento e fatturazione; Utente Base ha la possibilità solo di caricare o scaricare i progetti."
                />
                <RadioGroupCustom
                  name="user_type"
                  label="Tipologia"
                  options={user_types}
                  required
                  disabled={!currentUserIsAdmin && isAdmin}
                  cy="users-new-type"
                  tooltipText="Definisci se l’utente è esterno all’azienda oppure interno ad essa."
                />
                <CheckboxGroupCustom
                  name="user_permission"
                  label="Permessi"
                  disabled={isAdmin || isMaster}
                  options={getUserPermission(isAdmin, isMaster)}
                  required
                  tooltipText="Definisci le azioni che può effettuare l’utente all’interno del programma. Progetti: permette di creare nuovi progetti e di poterli eliminare; Report: permette di scaricare una lista dei progetti esistenti; Fatturazione: permette di modificare i dati di pagamento; Scarica: permette di scaricare i file presenti all’interno del progetto; Carica: permette di caricare i file all’interno del progetto."
                />
                <UploadFile
                  name="image_profile_asset_uuid"
                  label="Immagine di profilo"
                  accept="image/*"
                  tooltipText="Immagine di profilo"
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: '48px !important' }}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" onClick={props.handleClose}>
                Chiudi
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                variant="contained"
                data-testid="users-savenew"
                disabled={
                  props.formMethods.formState.isSubmitting ||
                  (!currentUserIsAdmin && isAdmin)
                }
              >
                Salva modifiche
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </FormProvider>
  );
}
